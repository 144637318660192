import React, { useContext, useState, useEffect } from 'react';
import AppContext from '../global/AppContext';
import ItemProfileEditable from '../elements/itemTextEditable';
import ProfileImage from '../elements/profileImage';
import { SendRequest } from '../../webRequests';
import './myProfile.css';

const MyProfile = () => {
    const { language, userPrefs, userState, updateUserPrefs, updateUserState } = useContext(AppContext);
    const [isVisible, setIsVisible] = useState(true);
    const [shouldRender, setShouldRender] = useState(false);
    const pageID = "My-Profile";
    
    
    useEffect(() => {

        let isVisibleTimeout;
        let shouldRenderTimeout;
        let renderConditions = userPrefs.currentMenuPage === pageID;

        if (userPrefs && userState) {
            if (renderConditions) {
                setShouldRender(true);
                 isVisibleTimeout = setTimeout(() => {
                    setIsVisible(true);
                }, 50);
            } else {
                setIsVisible(false);
                shouldRenderTimeout = setTimeout(() => {
                    setShouldRender(false);
                }, 500); 
            }
        }
        return () => {
            clearTimeout(isVisibleTimeout);
            clearTimeout(shouldRenderTimeout);
        };
    }, [userPrefs, userState, updateUserPrefs]);

    const handleFinishEditing = (variableName) => async (newValue) => {
        try {
            const responseData = await SendRequest('updateuserproperty', { property: variableName, value: newValue });
            console.log("Edit result:", responseData);
            if (responseData.result === "success"){
                console.log("Updating state:", variableName , " to " , newValue);
                updateUserState( variableName, newValue);
            }
        } catch (error) {
            console.error('Error updating user property:', error);
        }
    };

    return ( shouldRender ?
        <div className={`${pageID} Page-Content`}>
            <div className={`CLR-TXT-1 Fade FromBottom ${isVisible ? 'Visible' : ''}`} style={{ height: "100%", overflowY : "auto" }}>
                <h1 style={{ paddingTop: "60px"}}>{language.myProfile}</h1>
                
                <ProfileImage/>

                <div className='Group-Label'>{language.name}</div>
                
                <ItemProfileEditable 
                    label={language.firstName} 
                    value={userState.firstname} 
                    onFinishEditing={handleFinishEditing("firstname")} 
                />

                <ItemProfileEditable 
                    label={language.lastName} 
                    value={userState.lastname} 
                    onFinishEditing={handleFinishEditing("lastname")} 
                />

                <div className='Group-Label' style={{paddingTop:'20px'}}>{language.address}</div>

                <ItemProfileEditable 
                    label='Stát' 
                    value={userState.state} 
                    onFinishEditing={handleFinishEditing("state")} 
                />

                <ItemProfileEditable 
                    label='Město' 
                    value={userState.city} 
                    onFinishEditing={handleFinishEditing("city")} 
                />

                <ItemProfileEditable 
                    label='Ulice' 
                    value={userState.street} 
                    onFinishEditing={handleFinishEditing("street")} 
                />

                <ItemProfileEditable 
                    label='Číšlo popisné' 
                    value={userState.streetnumber} 
                    onFinishEditing={handleFinishEditing("streetnumber")} 
                />

                <div className='Group-Label' style={{paddingTop:'20px'}}>{language.contacts}</div>

                <ItemProfileEditable 
                    label='Telefon' 
                    value={userState.phonenumber}
                    onFinishEditing={handleFinishEditing("phonenumber")} 
                />

                <ItemProfileEditable 
                    label='E-mail' 
                    value={userState.emailcontact}
                    onFinishEditing={handleFinishEditing("emailcontact")} 
                />

                <div style={{paddingBottom:'50px'}}></div>

            </div>
        </div>
        :
        null
    );
};

export default MyProfile;