import React, { useContext, useRef } from 'react';
import AnimatedPage from './animatedPage';
import AppContext from '../global/AppContext';
import { SendRequest } from '../../webRequests';
import ItemTextEditable from '../elements/itemTextEditable';
import UserAvatar from '../elements/userAvatar';
import ButtonWithIcon from '../elements/buttonWithIcon';

const pageID = "NewChildStep1";

const PageContent = () => {
    const { language, userPrefs, updateUserPrefs, userState, navBack } = useContext(AppContext);
    const orgId = userPrefs.currentOrg.orgid;
    const groupId = userPrefs?.groupDetail?.groupid;
    const inviteFirstNameRef = useRef();
    const inviteLastNameRef = useRef();
    const inviteEmailRef = useRef();

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (userPrefs.inviteUserTo === "OrganizationChild"){

            const requestData = {
                orgid: orgId,
                invitedFirstName: inviteFirstNameRef.current.value,
                invitedLastName: inviteLastNameRef.current.value,
                invitedEmail: inviteEmailRef.current.value,
            };

            const responseData = await SendRequest('getuserchildren', { requestdata: requestData });
            console.log("responseData", responseData);

            const inviteChildParentData = {
                parentFirstName: inviteFirstNameRef.current.value,
                parentLastName: inviteLastNameRef.current.value,
                parentEmail: inviteEmailRef.current.value,
            };

            updateUserPrefs("inviteChildParentData", inviteChildParentData);

            updateUserPrefs("newChildOptions", responseData.childusers);
            updateUserPrefs("currentPage", "NewChildStep2");
        }

        // goBack();
    };

    const goBack = (event) => {
        if (event) {
            event.preventDefault(); // Prevent the default action
        }

        navBack('HomeScreen');
    };
  
    const currentOrg = userState.userorganizations.find(org => org.orgid === orgId);
    const currentGroup = userState.usergroups.find(group => group.groupid === groupId);
  
    return (
            <form onSubmit={handleSubmit}>
                <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>

                    <h3 style={{marginBottom:"20px"}}>{language.enterParentInfo}</h3>
                    {/* <h2 style={{marginTop:"0px", marginBottom:"0px"}}>{currentOrg?.orgname || "Organization Not Found"}</h2>
                    <h2 style={{marginTop:"0px"}}>{currentGroup?.groupname || "Organization Not Found"}</h2> */}
                    <div style={{width:"100%"}}>
                        <ItemTextEditable 
                            label={language.firstName} 
                            ref={inviteFirstNameRef}
                            required={true}
                            />

                        <ItemTextEditable 
                            label={language.lastName} 
                            ref={inviteLastNameRef}
                            required={true}
                            />

                        <ItemTextEditable 
                            label='E-mail'
                            ref={inviteEmailRef}
                            required={true}
                            type={'email'}
                            />
                    </div>

                    <ButtonWithIcon text={language.continue} leftIcon={'person_add'} width={"90%"} style={{marginTop:"10px"}}/>
                    <ButtonWithIcon text={language.cancel} leftIcon={'cancel'} rightIcon={'arrow_back_ios'} width={"90%"} style={{marginTop:"10px"}}
                        onClick={goBack}/>
                </div>
            </form>
    );
};

const EnhancedPage = AnimatedPage(PageContent, pageID);
const components = {
    [pageID]: () => <EnhancedPage />
};
export default components[pageID];
