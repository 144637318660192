import React, { useContext, useState, useEffect } from 'react';
import AnimatedPage from './animatedPage';
import AppContext from '../global/AppContext';
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import  './calendar.css';
import { cs } from 'date-fns/locale/cs';
import { MaterialSymbol } from 'react-material-symbols';
import CalendarEventItem from '../elements/calendarEventItem';

const pageID = "Calendar";

const PageContent = () => {
  const { language, userState, updateUserPrefs } = useContext(AppContext);
  const [selected, setSelected] = useState(null);
  const [month, setMonth] = useState(new Date());  // State to control the displayed month
  const [selectedEvents, setSelectedEvents] = useState([]);

  const CustomDayCell = ({ day, modifiers, ...props }) => {
  
    // Convert day to ISO string and then extract the date part
    const dateStr = `${day.date.getFullYear()}-${String(day.date.getMonth() + 1).padStart(2, '0')}-${String(day.date.getDate()).padStart(2, '0')}`;

    const myevent = userState.calendarevents.myevents.find(e => {
      const eventDate = new Date(e.eventdate); // Assuming eventdate is in ISO format
      const eventDateStr = `${eventDate.getFullYear()}-${String(eventDate.getMonth() + 1).padStart(2, '0')}-${String(eventDate.getDate()).padStart(2, '0')}`;
      return eventDateStr === dateStr;
    });

    const postevent = userState.calendarevents.postevents.find(e => {
      const eventDate = new Date(e.eventdate); // Assuming eventdate is in ISO format
      const eventDateStr = `${eventDate.getFullYear()}-${String(eventDate.getMonth() + 1).padStart(2, '0')}-${String(eventDate.getDate()).padStart(2, '0')}`;
      return eventDateStr === dateStr;
    });
  
    return (
      <td {...props}>
        <button className='custom-day-cell' onClick={() => setSelectedCustom(day.date)}>

        <div>{day.date.getDate()}</div>
        {myevent && (
          <div className="event-indicator">
            já {/* Display the first letter of the event name */}
          </div>
        )}

        {postevent && (
          <div className="post-event-indicator">
            MŠ {/* Display the first letter of the event name */}
          </div>
        )}


        </button>
      </td>
    );
  };

  useEffect(() => {
    if (userState.calendarevents && userState.calendarevents.myevents) {
      console.log("Updated ", selected);
      updateEvents(selected);
    }
  }, [userState.calendarevents.myevents]);

  const setSelectedCustom = (date) => {
    if (date?.toISOString() === selected?.toISOString()) {
      date = undefined;
    }

    updateEvents(date);
  }

  const updateEvents = (date) => {
    setSelected(date);
  
    if (date) {
      updateUserPrefs("selectedDate", date);
      setMonth(date);
  
      // Filter events for the selected date
      const dateStr = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
      const eventsForDay = [...userState.calendarevents.myevents, ...userState.calendarevents.postevents].filter(e => {
        const eventDate = new Date(e.eventdate);
        const eventDateStr = `${eventDate.getFullYear()}-${String(eventDate.getMonth() + 1).padStart(2, '0')}-${String(eventDate.getDate()).padStart(2, '0')}`;
        return eventDateStr === dateStr;
    });
  
      setSelectedEvents(eventsForDay); // Update state with events for the selected day
    } else {
      updateUserPrefs("selectedDate", null);
      setSelectedEvents([]); // Clear events when no date is selected
    }
  }


  const editCalendarEvent = (calendarEvent)  => () => {
    if (calendarEvent.postid)
      return;

    updateUserPrefs('isPostEvent', 0);
    updateUserPrefs('editCalendarEvent', calendarEvent);
    updateUserPrefs('displayCalendarNewEvent', 1);
    console.log("Edit event ", calendarEvent);
  }

  const sortedEvents = selectedEvents.sort((a, b) => {
    const dateA = new Date(a.eventdate);
    const dateB = new Date(b.eventdate);
    return dateA - dateB;
  });

  const formattedDate = selected 
  ? new Intl.DateTimeFormat('cs-CZ', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    }).format(selected)
  : "Vyberte den";

  return (
    <div style={{ padding: "15px" }}>
      <div style={{display: "flex", alignItems: "center", position: "relative"}}>
        <h3 style={{ marginBlock: "0px", position: "relative", left: '50%', transform: "translate(-50%)" }}>{language.calendar}</h3>
        <button
          type="button"
          className="Icon-Circle"
          onClick={() => {

            updateUserPrefs('isPostEvent', 0);
            updateUserPrefs('displayCalendarNewEvent', 1);
          }}
          style={{ marginLeft: "auto", color:"white", backgroundColor:"#fdd046", boxShadow:"2px 2px 3px #00000036"}}
        >
          <MaterialSymbol icon="add" grade={25} weight={600} />
        </button>
      </div>
      <DayPicker
        fixedWeeks={true}
        mode="single"
        captionLayout="dropdown"
        selected={selected}
        onSelect={(date) => {
          setSelected(date);
          // Keep the selected month in view after choosing a day
          if (date) {
            updateUserPrefs("selectedDate", date);
            setMonth(date);
          }
          else{
            updateUserPrefs("selectedDate", null);
          }
        }}
        month={month}  // Control the displayed month with state
        onMonthChange={setMonth}  // Update state when the user manually changes the month
        startMonth={new Date(2020, 0)}
        endMonth={new Date(2030, 11)}
        locale={cs}
        components={{
          Day: (props) => <CustomDayCell {...props} />, // Inject custom day cell
        }}
        // components={{
        //   DayButton: (props) => {
        //     const { day, modifiers, ...buttonProps } = props;
        //     return (
        //       <>
        //       <div>
        //         <button
        //           {...buttonProps}
        //         />
        //       X</div>
        //       </>
        //     );
        //   }
        // }}
        // footer={
        //   selected ? `Vybráno: ${selected.toLocaleDateString()}` : "Vyberte den"
        // }
        styles={{
          root: { width: '100%' },
          month: { width: '100%' },
          months: { width: '100%', maxWidth: '100%' },
          month_grid: { width: '100%' },
          selected: { backgroundColor: 'red' },
        }}
      />

      {/* List of Events for the Selected Day */}
      <div style={{ marginTop: '20px', padding: '10px', borderRadius: '10px', backgroundColor:'white', boxShadow: '0px 1px 5px 1px #cccccc' }}>
        <h4 style={{textAlign:"center", marginBlock:"0px"}}>{selected ? `${formattedDate}` : "Vyberte den"}</h4>
        {sortedEvents.length > 0 ? (
          sortedEvents.map((event, index) => (
            <CalendarEventItem key={index} index={index} date={event.eventdate} name={event.name} text={event.text} postid={event.postid} onClick={editCalendarEvent(event)}/>
          ))
        ) : (
          selected && <p style={{textAlign:"center"}}>{language.noEventsForThisDay}</p>
        )}
      </div>
    </div>
  );
};

const EnhancedPage = AnimatedPage(PageContent, pageID);
const components = {
  [pageID]: () => <EnhancedPage />
};
export default components[pageID];