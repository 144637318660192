import React from 'react';

const CalendarEventItem = ({ index, date, name, text, postid = undefined, onClick }) => {
  return (
    <button onClick={onClick} style={{padding:"0px", width:"100%", borderStyle:"none", backgroundColor:"white"}}>
        <div style={{ marginTop: index === 0 ? '20px' : '0', marginBottom:"20px", fontSize:"13px"}}>
            <div style={{ display: 'flex', paddingInline: '10px', padding: '0px', backgroundColor: '#fff', borderRadius: '5px', paddingLeft:"5px"}}>
                <div style={{fontSize:"15px", fontWeight:"500", width:"20%", textAlign:"left"}}>{new Date(date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit',hourCycle: 'h23' })}</div>
                <div className='dot' style={{width:"10px", height:"10px", borderRadius:"100%", backgroundColor: postid == undefined ? "#fdd046" : "red", marginRight:"11%", alignSelf:"center"}}></div>
                <div style={{fontWeight:"700", textAlign:"left", width:"65%", margin:"auto", textAlign:"center"}}>{name}</div>
            </div>
            <div style={{ display: 'flex', paddingInline: '10px', padding:'0px', backgroundColor: '#fff', borderRadius: '5px', paddingLeft:"5px" }}>
                <div style={{width:"20%"}}></div>
                <div style={{width:"1px", marginLeft:'5px', backgroundColor: postid == undefined ? "#fdd046" : "red"}}></div>
                <div style={{textAlign:"left", color:"grey", width:"65%", marginLeft:"auto", paddingBlock:"15px",wordBreak:"break-word"}}>{text}</div>
            </div>
        </div>
    </button>
  );
};

export default CalendarEventItem;