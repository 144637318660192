import React, { useState, useImperativeHandle, forwardRef } from 'react';
import './groupBookEntry.css'; // You can define your CSS in a separate file
import ButtonUniversal from './buttonUniversal';
import { MaterialSymbol } from 'react-material-symbols';

// Forwarding the ref to the component so that it can be accessed by the parent
const GroupBookEntry = forwardRef(({ entryName, entryContent, buttonText = "", onClick, buttonIcon = 'edit', contentStyle = null }, ref) => {
    const [content, setContent] = useState(entryContent);

    // Expose methods to parent using the ref
    useImperativeHandle(ref, () => ({
        entryName,        // Exposing entryName to parent
        entryContent,     // Exposing entryContent to parent
        updateContent(newContent) {
            setContent(newContent);
        },
        getContent() {
            return content; // Return current content
        },
    }));

    return (
        <div className="GroupBookEntry">
            <div className="GroupBookContainer">
                <div className="GroupBookEntryName">
                    {entryName}
                </div>
                <div className="GroupBookEntryContent" style={contentStyle}>
                    {content}
                    {buttonText && (
                        <ButtonUniversal 
                            text={buttonText}
                            style={{color:"black", fontSize:"clamp(0px,3vw,15px)", height:"clamp(0px,4vw,25px)", marginLeft:"auto", marginBlock:"auto"}}
                            rightIconStyle={{display:"flex", paddingLeft:"5px"}}
                            rightIcon={<MaterialSymbol icon={buttonIcon} color='white'/>}
                            backgroundColor="#8fd2ef"
                            onClick={() => (onClick ? onClick() : console.log("onClick not defined"))}
                        />
                    )}
                </div>
            </div>
        </div>
    );
});

export default GroupBookEntry;