import React, { useContext, useState, useEffect, useRef } from 'react';
import AppContext from '../global/AppContext';
import ButtonLayered from '../elements/buttonLayered';
import { MaterialSymbol } from 'react-material-symbols';
import UserAvatar from '../elements/userAvatar';
import './postAddModal.css';

const InfoLoading = () => {
    const { language, userState, userPrefs, updateUserPrefs } = useContext(AppContext);

    const [isVisible, setIsVisible] = useState(false);
    const [shouldRender, setShouldRender] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false); 
    const textareaRef = useRef(null);

    useEffect(() => {
        let isVisibleTimeout;
        let shouldRenderTimeout;
        let conditionsMet = (userPrefs?.displayInfoLoading || 0);

        if (userPrefs && userState) {
            if (conditionsMet) {
                setShouldRender(true);
                 isVisibleTimeout = setTimeout(() => {
                    setIsVisible(true);
                }, 50);
            } else {
                setIsVisible(false);
                shouldRenderTimeout = setTimeout(() => {
                    setShouldRender(false);
                }, 500); 
            }
        }
        return () => {
            clearTimeout(isVisibleTimeout);
            clearTimeout(shouldRenderTimeout);
        };
    }, [userPrefs, userState]);
    

    const handleContinue = async (event) => {
        event.preventDefault();
        updateUserPrefs('displayInfoLoading', 0);
    };
    
    return ( shouldRender ?
        <div className='Page-Container' style={{transition: '0.5s', zIndex: '3', backgroundColor: isVisible ? '#00000078' : '', position:'fixed', maxHeight: '100%', overflowY:'hidden'}}>
            <form onSubmit={handleContinue} className={`Box CLR-BG-1 Shadow-1 Fade FromTop ${isVisible ? 'Visible' : ''}`} style={{width:'60%', paddingBlock:'30px'}} >
                {/* <div>
                  <MaterialSymbol icon="info" grade={25} weight={600} style={{fontSize:'40px', marginBottom:'20px', color:'#bebebe', marginBottom:'30px'}}/>
                </div> */}
                <div className='CLR-TXT-1' style={{ marginBottom: '30px'}}>{language.loading}</div>
                <div className='loader' style={{marginInline: 'auto', marginBlock: '10px', transition:'0.5s', width:'50px', background:'#07a8ca', marginBottom:'30px', animationDuration:'1s'}}></div>
                <div>
                  <ButtonLayered type="submit" text={language.cancel} disabled={isSubmitting}/>
                </div>
            </form>
        </div>
        :
        null
    );
};

export default InfoLoading;