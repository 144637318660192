import React, { useContext, useEffect, useState } from 'react';
import AnimatedPage from './animatedPage';
import AppContext from '../global/AppContext';
import { SendRequest } from '../../webRequests';
import UserAvatar from '../elements/userAvatar';
import GroupsList from './groupsList';
import ButtonWithIcon from '../elements/buttonWithIcon';
import ButtonWithIconUser from '../elements/buttonWithIconUser';
import UsersList from '../elements/usersList';

const pageID = "OrganizationDetail";

const PageContent = () => {
    const { language, userPrefs, getUserState, updateUserPrefs, userState } = useContext(AppContext);
    const orgId = userPrefs.currentOrg.orgid;
    const [ userPending, setUsersPending ] = useState(false);
    const [ groupsPending, setGroupsPending ] = useState(false);
    const [ orgUsers, setOrgUsers ] = useState('');
    const { showConfirmation } = useContext(AppContext);

    const inviteUser = () => {
        updateUserPrefs("inviteUserTo", "Organization");
        updateUserPrefs("currentPage", "InviteUser");
        console.log("Invite user for org", currentOrg?.orgname);
    };

    const removeUser = () => {
        updateUserPrefs("removeUserFrom", "Organization");
        updateUserPrefs("currentPage", "RemoveUser");
        console.log("Remove user from organization", currentOrg?.orgname);
    };

    const addChildUser = () => {
        updateUserPrefs("inviteUserTo", "OrganizationChild");
        updateUserPrefs("currentPage", "NewChildStep1");
        console.log("Invite user to group", currentOrg?.groupname);
    };

    const removeChildUser = () => {
        updateUserPrefs("removeUserFrom", "OrganizationChild");
        updateUserPrefs("currentPage", "RemoveUser");
        console.log("Remove child user from group", currentOrg?.groupname);
    };
    
    const createGroup = async () => {
        updateUserPrefs("currentPage", "NewGroup");
        // var groupdata = { orgid: currentOrg.orgid, groupname: "Whatever"}
        // console.log("Create group for org", currentOrg?.orgname , " data:" , groupdata);
        // const responseData = await SendRequest('creategroup', { newgroupdata:groupdata });
        // console.log("createGroup:", responseData);
    };

    const deleteOrg = async () => {
        console.log("Delete ", userPrefs.currentOrg , userPrefs.currentOrg.orgname);

        showConfirmation(
            <>
            {language.confirmOrgDelete} <br /> <strong>{userPrefs.currentOrg.orgname}</strong> ?
            </>,
            async () =>  {

                updateUserPrefs('displayInfoLoading', 1);

                const requestData = {
                    orgid: userPrefs.currentOrg.orgid,
                };
        
                var responseData = await SendRequest('deleteorganization', { requestdata: requestData });
                console.log("deleteorganization:", responseData);

                if (responseData['success']){
                    updateUserPrefs('displayInfoLoading', 0);
                    updateUserPrefs('infoMessage', language.done);
                    updateUserPrefs('displayInfoMessage', 1);

                    updateUserPrefs("currentPage", 'OrganizationsManagement');
                }
                else{
                    updateUserPrefs('displayInfoLoading', 0);
                    updateUserPrefs('infoMessage', language.failed);
                    updateUserPrefs('displayInfoMessage', 1);

                    updateUserPrefs("currentPage", 'OrganizationsManagement');
                }
            },
            () => {
            console.log('Delete action canceled');
            }
        );
    };
        
    const handleGroupButtonClick = (group) => {
        console.log(`Group button clicked for group: ${group.groupname}`);
        updateUserPrefs("groupDetail", group);
        updateUserPrefs("currentPage", "GroupDetail");
        updateUserPrefs("currentOrg", currentOrg);
        updateUserPrefs("currentGroup", group);
        // Perform actions specific to the group button click
    };

    const getOrgUsers = async () => {

        const requestData = { orgid: orgId };
        const responseData = await SendRequest('getorganizationusers', { requestdata: requestData });
        console.log("getorganizationusers:", responseData);

        const usersData = {
            orgid: orgId,
            orgusers: responseData.orgusers.sort((a, b) => b.role - a.role),
        };

        await setOrgUsers(usersData);
        updateUserPrefs('orgUsers', usersData);
        // console.log("orgUsers: ", orgUsers);
        setUsersPending(false);
    };

    const getOrgGroups = async () => {

        const requestData = { orgid: orgId };
        const responseData = await SendRequest('getorganizationgroups', { requestdata: requestData });
        console.log("getorganizationgroups", responseData);
        if ( responseData && responseData.orggroups){
            setFilteredGroups(responseData.orggroups || []);
        }

        // console.log("orgUsers: ", orgUsers);
        setGroupsPending(false);
    };


    // const currentOrg = userState.userorganizations.find(org => org.orgid === orgId);
    const currentOrg = userPrefs.currentOrg;

    useEffect(() => {
        if (!currentOrg) {
            console.log("Current org not set for OrganizationDetail");
            updateUserPrefs("currentPage", "Wall");
        }
        else if (!userPending && orgId != orgUsers.orgid) {
            setUsersPending(true);
            getOrgUsers();
        }
    
        if (userPrefs.devOrgDetail === 1) {
            if (!groupsPending && userState.loginstatus === 1){
                setGroupsPending(true);
                getOrgGroups();
            }
        } else {
            const defaultFilteredGroups = userState.usergroups.filter(group => group.orgid === currentOrg.orgid);
            setFilteredGroups(defaultFilteredGroups);
        }
    }, [currentOrg, updateUserPrefs, userPending, orgId, orgUsers.orgid, userPrefs.devOrgDetail]);
    
    const [filteredGroups, setFilteredGroups] = useState([]);
    const hasGroups = filteredGroups.length > 0;
    const filteredUsers = orgUsers?.orgusers?.filter(user => user.role > 0);

    const twoItemsMenuContainerStyle = {display:"flex", flexWrap:"wrap", width:"90%", gap:"2.5%", marginTop:"2.5%"};
    const menuItemStyle = {flex: "1", height:"clamp(0px,18vw,100px)", flexDirection:"column", justifyContent:"center", alignItems:"start", padding:"3%" };
    const menuItemLayout = "menuItem";

    if (!currentOrg) {
        return null;
    }
  
    return (
          <>
          <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                <div className='CLR-BG-NavBar Shadow-2' style={{ width: "100%", textAlign: "center", paddingBlock:"10px"}}>
                        <h4 style={{ marginTop: "0px", marginBottom: "0px" }}>{language[`type_${currentOrg.orgtype}_organizationDetailTitle`]}</h4>
                        <h2 style={{ marginTop: "0px", marginBottom: "0px" }}>{currentOrg?.orgname || "Organization Not Found"}</h2>
                </div>
                {/* <GroupsList orgData={currentOrg}/> */}

                <h4 style={{ marginTop: "20px", marginBottom: "0px" }}>{language.administration}</h4>

                <ButtonWithIconUser user={currentOrg?.owner} username={`${currentOrg?.ownerfirstname} ${currentOrg?.ownerlastname}`} avatarSubTitle={language[`type_${currentOrg.orgtype}_orgOwner`]} userTitle={language[`type_${currentOrg.orgtype}_director`]} width={"90%"} style={{marginTop:"10px"}}/>

                { (currentOrg.orgtype == 0 || currentOrg.orgtype == 99) && (
                    <ButtonWithIcon text={language[`type_${currentOrg.orgtype}_userManagement`]} leftIcon={'group'} width={"90%"} style={{marginTop:"10px"}}
                        onClick={() => updateUserPrefs("currentPage", "OrganizationUsers")}/>
                )}

                { currentOrg.orgtype == 1 && (
                    <div style={twoItemsMenuContainerStyle}>
                        <ButtonWithIcon text={language[`type_${currentOrg.orgtype}_userManagement`]} leftIcon={'group'} layout={menuItemLayout} style={menuItemStyle}
                            onClick={() => updateUserPrefs("currentPage", "OrganizationUsers")}/>

                        <ButtonWithIcon text={language[`type_${currentOrg.orgtype}_childsManagement`]} leftIcon={'group'} layout={menuItemLayout} style={menuItemStyle}
                            onClick={() => updateUserPrefs("currentPage", "OrganizationChildren")}/>
                    </div>
                )}

                <ButtonWithIcon text={language[`type_${currentOrg.orgtype}_groupsManagement`]} leftIcon={'group'} width={"90%"} style={{marginTop:"10px"}}
                    onClick={() => updateUserPrefs("currentPage", "OrganizationGroups")}/>

                {/* Dev only */}
                {userState.user !== undefined && userState.user.usertype === 99 && userPrefs.devOrgDetail === 1 && (
                <>
                    <h3 style={{ marginTop: "20px", marginBottom: "0px" }}>Dev</h3>

                    <ButtonWithIcon text={language.deleteOrg} leftIcon={'group'} width={"90%"} style={{marginTop:"10px"}}
                        onClick={() => deleteOrg()}/>
                </>
                )}

                {orgUsers.orgusers && orgUsers.orgusers.length > 0 && (
                    <UsersList 
                        title={language[`type_${currentOrg.orgtype}_${`usersInOrg`}`]}
                        users={filteredUsers}
                    />
                )}
          </div>
          </>
          );
  };

const EnhancedPage = AnimatedPage(PageContent, pageID);
const components = {
    [pageID]: () => <EnhancedPage />
  };
export default components[pageID];
