import React, { useContext } from 'react';
import AnimatedPage from './animatedPage';
import AppContext from '../global/AppContext';
import ButtonWithIcon from '../elements/buttonWithIcon';
import UserAvatar from '../elements/userAvatar';
import AbsentNote from '../elements/absentNote';
import { SendRequest } from '../../webRequests';

const pageID = "AbsentNotes";

const PageContent = () => {
  const { userState, language, userPrefs, updateUserPrefs, showOptions, hideOptions } = useContext(AppContext);

  const selectChild = async (child) => {
    updateUserPrefs("currentPage", "NewAbsentNote");
    updateUserPrefs("selectedChild", child);
    hideOptions();
  }

  const childSelection = (user) => {
    console.log(user);

    showOptions({
      message: (
        <>
          {userState.childusers && userState.childusers.length > 0 && (
            <>
              <h4 style={{ marginTop: "20px", marginBottom: "10px" }}>{language.newAbsentNoteWho}</h4>

              <div className={`MyKidsList`} style={{ display: "flex", justifyContent: "center" }}>
                {userState.childusers && userState.childusers.map((child, index) => (
                  <div key={child.userid} style={{ paddingInline: '10px', marginBottom: "clamp(0px,5vw,20px)" }}>
                    <UserAvatar user={child} onClick={() => selectChild(child)} displayName={true} />
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      ),
      showIcon: false,
      buttons: []
    });
  };

  const absentNotesData = userPrefs.absentNotesDisplayData === "parent" 
  ? userState.childusers
      .flatMap((child) => (child.absentnotes || []).map((note) => ({ ...note, child })))
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
  : userPrefs.absentNotesDisplayData;

  console.log("absentNotesData ", absentNotesData);

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <h3 style={{ marginBottom: "15px", paddingInline: "15px" }}>{language.absentNotes}</h3>

      {userState.childusers && userState.childusers.length > 0 && (
      <ButtonWithIcon text={language.newAbsentNote} leftIcon={'note_add'} width={"90%"}
        onClick={() => childSelection()} />
      )}

      {/* Merge, sort, and render absent notes */}
      {absentNotesData.map(({ child, ...note }) => ( // Destructure child and note
              <AbsentNote
                  key={note.id}
                  note={note}
                  user={child} // Pass the child reference
                  style={{ width: "90%", margin: "auto", marginTop: "clamp(0px,5vw,20px)" }}
              />
          ))
      }

      {/* Display absent notes for each child user */}
      {/* {userState.childusers.map((child) => (
          <div key={child.userid} style={{ width: "100%" }}>
            {child.absentnotes && child.absentnotes.length > 0 && (
            <div>
              {child.absentnotes.map((note) => (
                <AbsentNote key={note.id} note={note} user={child} style={{width:"90%", margin:"auto", marginTop:"clamp(0px,5vw,20px)"}}/>
              ))}
            </div>
            )}
          </div>
        ))} */}
    </div>
  );
};

const EnhancedPage = AnimatedPage(PageContent, pageID);
const components = {
  [pageID]: () => <EnhancedPage />
};
export default components[pageID];