import React, { useContext, useState, useEffect } from 'react';
import AppContext from '../global/AppContext';
import MainMenu from './mainMenu';
import MyProfile from './myProfile';
import EditProfile from './editProfile';
import NewOrganization from './newOrganizationMenu';

const MenuContent = () => {
    const { userPrefs, userState, updateUserPrefs} = useContext(AppContext);
    const [isVisible, setIsVisible] = useState(false);
    const [shouldRender, setShouldRender] = useState(false);
    const pageID = "Menu-Content";


    useEffect(() => {
        let isVisibleTimeout;
        let shouldRenderTimeout;
        let renderConditions = userPrefs.menuOpen === 1 && userState.loginstatus === 1;

        if (userPrefs && userState) {
            if (renderConditions) {
                setShouldRender(true);
                 isVisibleTimeout = setTimeout(() => {
                    setIsVisible(true);
                }, 50);
            } else {
                setIsVisible(false);
                shouldRenderTimeout = setTimeout(() => {

                    if (userPrefs.currentMenuPage !== 'Main-Menu'){
                        updateUserPrefs('currentMenuPage', 'Main-Menu');
                    }

                    setShouldRender(false);
                }, 500); 
            }
        }
        return () => {
            clearTimeout(isVisibleTimeout);
            clearTimeout(shouldRenderTimeout);
        };
    }, [userPrefs, userState, updateUserPrefs]);

    const close = () => {
        updateUserPrefs("menuOpen", 0);
    };

    return ( shouldRender ?
        <div className={`${pageID} Page-Container`}  onClick={() => updateUserPrefs("menuOpen", 0)}>
            <div className={`Fade FromRight Shadow-Menu CLR-BG-4 ${isVisible ? 'Visible' : ''}`} style={{ minHeight: "100%" , width: "80%", position:"absolute", right:"0"}} onClick={(e) => {e.stopPropagation();}}>
            <MainMenu/>
            <MyProfile/>
            {/* <EditProfile/> */}
            <NewOrganization/>
            </div>
        </div>
        :
        null
    );
};

export default MenuContent;