import React, { useContext, useState, useEffect } from 'react';
import AppContext from '../global/AppContext';

const OrganizationsList = () => {
    const { language, userPrefs, userState, updateUserPrefs  } = useContext(AppContext);
    const [isVisible, setIsVisible] = useState(false);
    const [shouldRender, setShouldRender] = useState(false);
    const pageID = "Organizations-List";

    useEffect(() => {
        let isVisibleTimeout;
        let shouldRenderTimeout;
        let renderConditions = userPrefs.currentPage === pageID && userState.loginstatus === 1;

        if (userPrefs && userState) {
            if (renderConditions) {
                setShouldRender(true);
                 isVisibleTimeout = setTimeout(() => {
                    setIsVisible(true);
                }, 50);
            } else {
                setIsVisible(false);
                shouldRenderTimeout = setTimeout(() => {
                    setShouldRender(false);
                }, 500); 
            }
        }
        return () => {
            clearTimeout(isVisibleTimeout);
            clearTimeout(shouldRenderTimeout);
        };
    }, [userPrefs, userState]);

    const handleOrganizationClick = (org) => {
            updateUserPrefs("currentPage", "Organization-Detail");
            updateUserPrefs("currentOrg", org);
    };

    return ( shouldRender ?
        <div className={`${pageID} Page-Container`}>
            <div className={`Box CLR-BG-3 CLR-TXT-1 Shadow-1 Fade FromBottom ${isVisible ? 'Visible' : ''}`} style={{ padding: "50px" }}>
                <h1>{language.organizationsTitle}</h1>
                <div className={`ItemsList`}>
                {userState.userorgdata && userState.userorgdata.length > 0 ? (
                    userState.userorgdata.map((org) => (
                        <button key={org.orgname} 
                            className="ListItem CLR-BTN-1"
                            onClick={() => handleOrganizationClick(org)}
                        >
                            {org.orgname}
                        </button>
                    ))
                ) : (
                    <p>{language.noOrganizations}</p>
                )}
                </div>
            </div>
        </div>
        :
        null
    );
};

export default OrganizationsList;