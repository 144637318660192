import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';

const AppendedToDocument = ({ children, className, containerStyle = {} }) => {
    useEffect(() => {
        // Create a container div and apply styles if any
        const container = document.createElement('div');
        container.className = className || '';
        Object.assign(container.style, containerStyle);
        
        document.body.appendChild(container); // Append to document body
        const root = createRoot(container);

        // Render the children into the container
        root.render(children);

        // Cleanup function to unmount and remove the container
        return () => {
            setTimeout(() => {
                root.unmount();
                document.body.removeChild(container);
            }, 0); // Delay unmounting until after the render cycle
        };
    }, [children, className, containerStyle]);

    return null; // This component doesn't render anything in the main React tree
};

export default AppendedToDocument;