import React, { useContext, useEffect, useState } from 'react';
import AnimatedPage from './animatedPage';
import AppContext from '../global/AppContext';
import { SendRequest } from '../../webRequests';
import Posts from './posts';
import { MaterialSymbol } from 'react-material-symbols';
import ButtonWithIcon from '../elements/buttonWithIcon';

const pageID = "Wall";

const PageContent = () => {
  const { language, updateUserPrefs, getUserState, showOptions, hideOptions, userPrefs } = useContext(AppContext);
  const {userState} = useContext(AppContext);
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const getWallData = async () => {
      try {
        const response = await SendRequest('getuserwalldata', { userid: userState.userid });
        console.log('getuserwalldata ', response);

        // Assuming response contains an array of posts
        if (response && response.userwalldata) {
          setPosts(response.userwalldata.posts);
        }
      } catch (error) {
        console.error('Error fetching wall data:', error);
      }
    };

    getWallData();

    updateUserPrefs('refreshWallData', false);
  }, [userState, userPrefs.refreshWallData]);

  const openPostModal = (org, group) => {
    hideOptions();
    updateUserPrefs("currentOrg", org);
    updateUserPrefs('currentGroup', group);
    // updateUserPrefs('postModalOpen', 1);
    updateUserPrefs("currentPage", "NewPost");
    updateUserPrefs("newPost", {})
  };

  const addPost = () => {
    // Create a map of organizations by their orgid for quick lookup
    const organizationsMap = userState.userorganizations.reduce((acc, org) => {
      acc[org.orgid] = org;
      return acc;
    }, {});
  
    // Group usergroups by organization and filter groups with role > 0
    const groupedByOrganization = userState.usergroups.reduce((acc, group) => {
      const org = organizationsMap[group.orgid]; // Find the corresponding organization based on orgid
      if (!org || group.role <= 0) {
        return acc; // Skip if no organization is found for the group or if role <= 0
      }
  
      // If the organization doesn't exist in the accumulator, initialize it
      if (!acc[org.orgname]) {
        acc[org.orgname] = { org, groups: [] }; // Store the full org object and an array of groups
      }
  
      // Push group details into the corresponding organization
      acc[org.orgname].groups.push(group);
  
      return acc;
    }, {});
  
    // Display the options modal with the grouped organizations and groups
    showOptions({
      message: (
        <>
          <div style={{paddingTop:"clamp(0px, 6vw, 30px)"}}>
            {language.selectPostGroup}<br/>
          </div>
          <div style={{ width: '100%', paddingBottom:"20px"}}>
            {Object.keys(groupedByOrganization).map(orgName => (
              <div key={orgName} style={{ marginBottom: '0px', textAlign: 'center' }}>
                <h4 style={{marginBottom:"0px"}}>{orgName}</h4>
                <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                  {groupedByOrganization[orgName].groups.map(group => (
                    <ButtonWithIcon
                      key={group.groupid}
                      text={group.groupname}
                      leftIcon="group"
                      rightIcon="arrow_forward_ios"
                      width={"90%"}
                      onClick={() => openPostModal(groupedByOrganization[orgName].org, group)} // Pass the full org object and group to the modal
                      style={{ margin: '5px' }} // Add margin for spacing between buttons
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </>
      ),
      showIcon: false,
      buttons: []
    });
  };

  return (
    <>
      <div style={{ padding: "clamp(0px, 5vw, 15px)" }}>
        <div style={{display: "flex", alignItems: "center", position: "relative"}}>
          <h3 style={{ marginBlock: "0px", position: "relative", left: '50%', transform: "translate(-50%)" }}>{language.wall}</h3>

          {userState?.usergroups?.length > 0 && (
            <button
              type="button"
              className="Icon-Circle"
              onClick={() => addPost()}
              style={{ marginLeft: "auto", color:"white", backgroundColor:"#fdd046", boxShadow:"2px 2px 3px #00000036"}}
              >
              <MaterialSymbol icon="add" grade={25} weight={600} />
            </button>
          )}

        </div>
      </div>
      <div>
        <Posts posts={posts}/>
      </div>
    </>
  );
};

const EnhancedPage = AnimatedPage(PageContent, pageID);
const components = {
  [pageID]: () => <EnhancedPage className={'CLR-BG-5'}/>
};
export default components[pageID];