import { useState, useEffect } from 'react';
import { SendRequest } from '../../webRequests';

export const useUserState = () => {
  const [userState, setUserState] = useState('');

  const logIn = async (username, password, result) => {
    const responseData = await SendRequest('loginwithpassword', { username, password });
    setUserState(responseData);
    console.log("logIn:", responseData);

    const resultData = {
        code: responseData.resultcode,
        groupscount: responseData?.usergroups?.length // Fixed typo here
    };

    result(resultData);
  };

  const verifyLogIn = async (username, password, verifycode, result) => {
    const responseData = await SendRequest('loginverify', { username, password, verifycode });
    setUserState(responseData);

    const resultData = {
      code: responseData.resultcode,
      groupscount: responseData?.usergroups?.length // Fixed typo here
    };

    result(resultData);
  };
  
  const logOut = async () => {
    const responseData = await SendRequest('logout');
    setUserState(responseData);
    console.log("logOut:", responseData);
  };

  const getUserState = async () => {
    const responseData = await SendRequest('loginstatus');
    setUserState(responseData);
    console.log("getUserState:", responseData);

    // Emergency logout
    // if (responseData == null){
    //   logOut();
    // }
  };

  const updateUserState = (key, value) => {
    setUserState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  useEffect(() => {
      console.log("userState updated", userState);

      if (userState?.calendarevents) {
        // Combine myevents and postevents into one array
        const allEvents = [
            ...(userState.calendarevents.myevents || []), 
            ...(userState.calendarevents.postevents || [])
        ];

        // Schedule notifications for all events
        scheduleNotifications(allEvents);
    }

  }, [userState]);

  const scheduleNotifications = (events) => {
    console.log("Scheduling notifications");
    // Function to debug service worker status
    const debugServiceWorkerStatus = () => {
      navigator.serviceWorker.getRegistrations()
        .then(registrations => {
          console.log('Service Worker Registrations:', registrations);
          if (registrations.length > 0) {
            registrations.forEach((registration, index) => {
              console.log(`Registration ${index}:`, registration);
            });
          } else {
            console.log('No Service Worker registrations found.');
          }
        })
        .catch(error => {
          console.error('Error fetching service worker registrations:', error);
        });
    };
  
    // Clear all existing notifications
    navigator.serviceWorker.getRegistrations()
      .then(function (registrations) {
        registrations.forEach(registration => {
          registration.getNotifications()
            .then(notifications => {
              notifications.forEach(notification => notification.close());
            })
            .catch(error => {
              console.error('Error fetching notifications:', error);
            });
        });
      })
      .catch(error => {
        console.error('Error getting service worker registrations:', error);
      });
  
    // Schedule notifications for future events
    events.forEach(event => {
      const eventDate = new Date(event.eventdate.replace(' ', 'T'));
      const now = new Date();
      const timeUntilEvent = eventDate - now;
  
      if (timeUntilEvent > 0) {
        console.log("Event scheduled:", event, "in:", timeUntilEvent);
  
        setTimeout(() => {
          console.log("Event going on:", event);
          //debugServiceWorkerStatus(); // Debug service worker status
  
          // Use the current active service worker to show notifications
          navigator.serviceWorker.getRegistrations()
            .then(registrations => {
              const activeRegistration = registrations.find(reg => reg.active);
              if (activeRegistration) {
                console.log("Displaying notification using active registration:", activeRegistration);
  
                activeRegistration.showNotification("Máte aktuální událost v kalendáři aplikace Nerligo.cz", {
                  body: (event.name + ", " + event.text),
                  icon: 'https://www.nerliano.cz/app/logo192.png',
                  badge: 'https://www.nerliano.cz/app/badge72.png',
                  tag: event.id, // Use event.id to ensure uniqueness
                  data: {
                    url: event.url || '/'
                  },
                  requireInteraction: true,
                  silent: false,
                  renotify: true,
                  priority: 'high'
                })
                .then(() => console.log("Notification displayed."))
                .catch(error => console.error('Error displaying notification:', error));
              } else {
                console.log('No active service worker registration found.');
              }
            })
            .catch(error => console.error('Error getting service worker registrations:', error));
        }, timeUntilEvent);
      } else {
        // console.log("Event NOT scheduled:", event.name, "in:", timeUntilEvent);
      }
    });
  };

  const debugServiceWorkerStatus = () => {
    if ('serviceWorker' in navigator) {
      console.log('Service Worker is supported.');
  
      // Check the number of service workers registered
      navigator.serviceWorker.getRegistrations()
        .then(registrations => {
          console.log('Service Worker Registrations:', registrations);
          registrations.forEach(registration => {
            console.log('Registration:', registration);
            console.log('Service Worker:', registration.installing, registration.waiting, registration.active);
          });
        })
        .catch(error => {
          console.error('Error fetching service worker registrations:', error);
        });
  
      // Check if the service worker is ready
      navigator.serviceWorker.ready
        .then(registration => {
          console.log('Service Worker is ready:', registration);
        })
        .catch(error => {
          console.error('Service Worker ready promise rejected:', error);
        });
    } else {
      console.log('Service Worker is not supported.');
    }
  };
  
  useEffect(() => {
      getUserState();
  }, []);

  return { userState, verifyLogIn, logIn, logOut, getUserState, updateUserState };
};