// AbsentNote.jsx
import React, { useContext } from "react";
import { formatPostDate } from '../global/utils';
import AppContext from '../global/AppContext';
import UserAvatar from "./userAvatar";

const AbsentNote = ({ note, user, style }) => {
    const { language } = useContext(AppContext);

  return (
    <div className={`AbsentNote Shadow-1`} style={{padding: "clamp(0px, 3vw, 15px)", display:"flex", borderRadius:"5px", alignItems:"center", ...style}}>
        <div style={{paddingRight:"clamp(0px, 3vw, 15px)"}} ><UserAvatar user={user} displayName={false}/></div>
        <div style={{width:"55vw"}}>
            <div style={{fontWeight:"600"}}>{user.firstname} {user.lastname}</div>

            <div style={{display:"flex", justifyContent:"space-between"}}>
              <div><strong>{language.from}:</strong> {formatPostDate(note.from, 1)}</div>
              <div>{formatPostDate(note.from, 2)}</div>
            </div>

            <div style={{display:"flex", justifyContent:"space-between"}}>
              <div><strong>{language.to}:</strong> {formatPostDate(note.to, 1)}</div>
              <div>{formatPostDate(note.to, 2)}</div>
            </div>

            {note.reasonid > -1 && (
              <div><strong>{language.absenceReason}:</strong> {language[`absenceReason${note.reasonid}`]}</div>
            )}

            {(note.notes != "" && note.notes != undefined) && (
              <div><strong>{language.note}:</strong> {note.notes}</div>
            )}
        </div>
    </div>
  );
};

export default AbsentNote;