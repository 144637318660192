import React, { useContext } from 'react';
import AnimatedPage from './animatedPage';
import AppContext from '../global/AppContext';
import ButtonWithIcon from '../elements/buttonWithIcon';
import UserAvatar from '../elements/userAvatar';
import AbsentNote from '../elements/absentNote';
import { SendRequest } from '../../webRequests';
import { ReactComponent as ModryPulkruh } from '../../assets/icons/svg/Modry_Pulkruh.svg';
import { ReactComponent as Tabletky } from '../../assets/icons/svg/Tabletky.svg';
import { ReactComponent as Zarovka } from '../../assets/icons/svg/Zarovka.svg';
import { MaterialSymbol } from 'react-material-symbols';

const pageID = "AbsentNotesParentPanel";

const PageContent = () => {
    const { userState, language, userPrefs, updateUserPrefs, showOptions, hideOptions } = useContext(AppContext);

    const selectChild = async (child) => {
        updateUserPrefs("currentPage", "NewAbsentNote");
        updateUserPrefs("selectedChild", child);
        hideOptions();
    }

    const childSelection = (user) => {
        console.log(user);

        showOptions({
            message: (
                <>
                    {userState.childusers && userState.childusers.length > 0 && (
                        <>
                            <h4 style={{ marginTop: "20px", marginBottom: "10px" }}>{language.newAbsentNoteWho}</h4>

                            <div className={`MyKidsList`} style={{ display: "flex", justifyContent: "center" }}>
                                {userState.childusers && userState.childusers.map((child, index) => (
                                    <div key={child.userid} style={{ paddingInline: '10px', marginBottom: "clamp(0px,5vw,20px)" }}>
                                        <UserAvatar user={child} onClick={() => selectChild(child)} displayName={true} />
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </>
            ),
            showIcon: false,
            buttons: []
        });
    };

    const absentNotesData = userPrefs.absentNotesDisplayData === "parent"
        ? userState.childusers
            .flatMap((child) => (child.absentnotes || []).map((note) => ({ ...note, child })))
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        : userPrefs.absentNotesDisplayData;

    console.log("absentNotesData ", absentNotesData);

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>

            <ModryPulkruh className='Signature-Graphic-1'/>
            <Tabletky style={{zIndex:"-1", width:"70vw", position:"absolute", marginTop:"clamp(0px,15vw,100px)"}}/>

            <div className='Spacer-Line-1' style={{marginTop:"clamp(0px,40vw,200px)"}}></div>

            <div type="button" onClick={() => childSelection()} style={{display:"flex", alignItems:"center", cursor:"pointer", marginBlock:"clamp(0px,15vw,30px)", marginRight:"10vw", marginLeft:"auto"}}>
                <div style={{fontSize:"clamp(0px,10vw,20px)", fontWeight:"600", marginRight:"clamp(0px,2vw,10px)"}}>{language.newAbsentNote}</div>
                <div
                className="Icon-Circle-Div"
                style={{color:"white", backgroundColor:"#69b42a", alignItems:"center", justifyContent:"center"}}
                >
                <MaterialSymbol icon="add" grade={25} weight={600}/>
                </div>
            </div>

            <div style={{display:"flex", alignItems:"center", marginBottom:"clamp(0px,30vw,150px)", textAlign:"center"}}>
                <div style={{marginInline:"5vw"}}><Zarovka className='Svg-Icon-Default'/></div>
                <div style={{marginRight:"10vw"}}>
                    {language.absentNoteInstructions}
                </div>
            </div>

            {/* Merge, sort, and render absent notes */}
            <div className='Spacer-Line-1'></div>
            <h3 style={{ marginBottom: "0px", marginTop: "10px", paddingInline: "15px" }}>{language.history}</h3>
            {absentNotesData.map(({ child, ...note }) => ( // Destructure child and note
                <AbsentNote
                    key={note.id}
                    note={note}
                    user={child} // Pass the child reference
                    style={{ width: "90%", margin: "auto", marginBottom: "clamp(0px,5vw,20px)" }}
                />
            ))
            }
        </div>
    );
};

const EnhancedPage = AnimatedPage(PageContent, pageID, 'currentPage', false );
const components = {
    [pageID]: () => <EnhancedPage />
};
export default components[pageID];