import React, { useContext, useRef, useState, useEffect } from 'react';
import AnimatedPage from './animatedPage';
import AppContext from '../global/AppContext';
import './newPost.css';
import { MaterialSymbol } from 'react-material-symbols';
import { ReactComponent as ButtonGallery } from '../../assets/icons/svg/button_galery.svg';
import { SendRequest } from '../../webRequests';
import { UploadRequest } from '../../webRequests';
import FileItem from '../elements/fileItem';
import ButtonUniversal from '../elements/buttonUniversal';
import { createRoot } from 'react-dom/client';
import NewPostPoll from './newPostPoll';
import ImageItem from '../elements/imageItem';
import AppendedToDocument from '../elements/appendedToDocument';

const pageID = "NewPost";

const PageContent = () => {
    const { language, userPrefs, updateUserPrefs, getUserState, updateUserState, userState, showConfirmation, showOptions } = useContext(AppContext);
    const titleRef = useRef(null);
    const textRef = useRef(null);

    // Reference for the file input
    const imagesInputRef = useRef(null);
    const cameraInputRef = useRef(null);
    const documentsInputRef = useRef(null);
    const [filesToUpload, setFilesToUpload] = useState([]);

    // States for character counts
    const [titleCharCount, setTitleCharCount] = useState(0);
    const [textCharCount, setTextCharCount] = useState(0);

    // Checkbox states
    const [importantIconChecked, setImportantIconChecked] = useState(userPrefs?.newPost?.importantIconChecked || false);
    const [requiresConfirmationChecked, setRequiresConfirmationChecked] = useState(userPrefs?.newPost?.requiresConfirmation || false);
    const [lowerIconChecked, setLowerIconChecked] = useState(userPrefs?.newPost?.lowerIconChecked || false);

    // Parent confirmation
    const [selectedParentConfirmIndex, setParentConfirmSelectedIndex] = useState(1);

    const parentConfirmButtons = [
        { id: 0, content: <><strong>{language.yes}</strong> {language.needs}</> },
        { id: 1, content: <><strong>{language.no}</strong> {language.needsNot}</> },
    ];

    // Handler functions for character count updates
    const handleTitleChange = (e) => {
        setTitleCharCount(e.target.value.length);
        updateUserPrefs('newPost', { ...userPrefs.newPost, title: e.target.value });
    };

    const handleTextChange = (e) => {
        setTextCharCount(e.target.value.length);
        updateUserPrefs('newPost', { ...userPrefs.newPost, text: e.target.value });
    };

    // Function to handle files button click
    const handleGalleryClick = () => {
        if (imagesInputRef.current) {
            imagesInputRef.current.click();
        }
    };

    const handleCameraClick = () => {
        if (cameraInputRef.current) {
            cameraInputRef.current.click();
        }
    };

    const handleDocumentsClick = () => {
        if (documentsInputRef.current) {
            documentsInputRef.current.click();
        }
    };

    // Function to handle file input change (if needed)
    const addFilesToUpload = (event, filetype) => {
        const allowedExtensions = {
            image: ['.jpg', '.jpeg', '.png'], // Allowed image extensions
            document: ['.pdf', '.doc', '.docx', '.xls', '.xlsx'] // Allowed document extensions
        };

        const invalidFiles = []; // Array to collect invalid file names

        const newFiles = Array.from(event.target.files).filter(file => {
            const fileExtension = file.name.split('.').pop().toLowerCase();
            const isValidExtension = allowedExtensions[filetype].some(ext => file.name.toLowerCase().endsWith(ext));
            
            if (!isValidExtension) {
                invalidFiles.push(file.name); // Collect invalid file names
                return false; // Filter out invalid files
            }
            return true; // Keep valid files
        }).map(file => ({ file, filetype })); // Attach the group info

        // Check if any files were invalid
        if (invalidFiles.length > 0) {
            const invalidGroup = allowedExtensions[filetype].join(', ');
            console.warn(`The following files were not valid for ${filetype}: ${invalidFiles.join(', ')}. Allowed extensions are: ${invalidGroup}`);

            const groupName = filetype === "image"  ? "obrázky" : "dokumenty";
            updateUserPrefs('infoMessage', `Byly ignorováný soubory s nepodporovaným formátem. Podporované formáty pro ${groupName} jsou:\n ${invalidGroup}`);
            updateUserPrefs('displayInfoMessage', 1);
        }

        
        setFilesToUpload((prevFiles) => {
            // Use only userPrefs.newPost.files as the base to avoid duplication
            const currentFiles = userPrefs.newPost.files || [];
            const updatedFiles = [...currentFiles, ...newFiles]; // Merge current files with new files

            // Update userPrefs.newPost.files
            updateUserPrefs('newPost', { ...userPrefs.newPost, files: updatedFiles });
        });
    };

    const removeFilesToUpload = (fileToRemove) => {
        // Use userPrefs.newPost.files as the base for the update
        const currentFiles = userPrefs.newPost.files || [];
        const updatedFiles = currentFiles.filter(file => file !== fileToRemove); // Remove the selected file
    
        // Update userPrefs.newPost.files
        updateUserPrefs('newPost', { ...userPrefs.newPost, files: updatedFiles });
    
        // No need to update setFilesToUpload since userPrefs is the source of truth
    };

    const removeOriginalFile = (fileToRemove) => {
        console.log("Removing file:", fileToRemove);
    
        const currentFiles = userPrefs.newPost.originalAttachments || [];
        const removedFiles = userPrefs.newPost.removedFiles || []; // Initialize removedFiles if not already present
    
        // Filter the files based on postattachmentid, removing the one that matches fileToRemove's attachmentid
        const updatedFiles = currentFiles.filter(file => file.postattachmentid !== fileToRemove.postattachmentid);
    
        // Add the removed file to the removedFiles array
        const updatedRemovedFiles = [...removedFiles, fileToRemove];
    
        // Update userPrefs.newPost with both updated originalAttachments and removedFiles
        updateUserPrefs('newPost', { 
            ...userPrefs.newPost, 
            originalAttachments: updatedFiles,  // Update originalAttachments with filtered files
            removedFiles: updatedRemovedFiles   // Track the removed files
        });
    
        console.log("Updated attachments:", updatedFiles);
        console.log("Files marked as removed:", updatedRemovedFiles);
    };

    const groupFilesByTypeOrig = (files) => {
        if (!files) return {};

        // Initialize the grouped files with two categories: 'images' and 'others'
        const groupedFiles = {
            image: [],
            document: []
        };
    
        // Iterate through each file and group them based on filetype
        files.forEach(file => {
            if (file.filetype.includes('image')) {
                groupedFiles.image.push(file);
            } else {
                groupedFiles.document.push(file);
            }
        });
    
        return groupedFiles;
    };

    const groupFilesByType = (files) => {
        if (!files) return {};

        // Define the desired fixed order of file types
        const fileTypesOrder = ['image', 'document'];
    
        // Initialize the accumulator with all file types, even if empty
        const groupedFiles = fileTypesOrder.reduce((acc, type) => {
            acc[type] = [];
            return acc;
        }, {});
    
        // Populate the grouped files based on the current files
        files?.forEach(file => {
            if (groupedFiles[file.filetype]) {
                groupedFiles[file.filetype].push(file);
            }
        });
    
        return groupedFiles;
    };

    // Print files to upload

    useEffect(() => {
        console.log("importantIconChecked:", importantIconChecked);
        console.log("requiresConfirmationChecked:", requiresConfirmationChecked);
        console.log("Files to upload:", filesToUpload);
    }, [filesToUpload, importantIconChecked, requiresConfirmationChecked]);
    
    // Button functions

    const confirmSubmit = async (event) => {

        console.log('confirmSubmit title:' , userPrefs.newPost.title , ' text:', userPrefs.newPost.text );

        if ((!userPrefs.newPost.title || userPrefs.newPost.title.trim() === "") || (!userPrefs.newPost.text || userPrefs.newPost.text.trim() === "")){
            showConfirmation(
                <>
                {language.postTitleOrTextMissing}
                </>,
                async () =>  {
                    submitPost();
                },
                () => {
                    console.log('Delete action canceled');
                }
            );
        }
        else{
            submitPost();
        }
    }

    const submitPost = async (event) => {
        if (event) event.preventDefault();

        console.log(
            'Post title:' , titleRef.current.value,
            '\nPost text:' , textRef.current.value,
            '\nRequires confirmation:', requiresConfirmationChecked,
            '\nAdds important icon:', importantIconChecked,
            '\nAdds lower icon:', lowerIconChecked
        );
        
        try {
            const requestData = {
                postgroupid: userPrefs.currentGroup.groupid,
                postorgid: userPrefs.currentOrg.orgid,
                posttitle: userPrefs.newPost.title,
                posttext: userPrefs.newPost.text,
                requiresconfirmation: requiresConfirmationChecked,
                important: importantIconChecked,
                lowericon: lowerIconChecked,
                poll: userPrefs?.newPost?.poll?.pollOptions?.length > 0 ? userPrefs.newPost.poll : undefined,
                calendarevent: userPrefs.newPost.calendarEvent,
                removeattachments: userPrefs.newPost.removedFiles,
            };

            const files = userPrefs?.newPost?.files?.map(fileWrapper => fileWrapper.file);

            updateUserPrefs('displayInfoLoading', 1);

            var responseData = null;

            if (userPrefs.newPost.postid == undefined){
                responseData = await UploadRequest('addpost', files, requestData);
            }
            else{
                requestData.postid = userPrefs.newPost.postid;
                responseData = await UploadRequest('editpost', files, requestData);
            }

            console.log(responseData);
            
            const response = await SendRequest('getgroupdata', { groupid: userPrefs.currentGroup.groupid });
            updateUserPrefs("groupdata_" + userPrefs.currentGroup.groupid, response.groupdata);
            getUserState();

            updateUserPrefs('displayInfoLoading', 0);

            if (responseData.result === 'success'){
                console.log("postid:", userPrefs.newPost.postid , " undefined:" , userPrefs.newPost.postid === undefined);
                updateUserPrefs('infoMessage', userPrefs.newPost.postid === undefined ? language.postAddSuccess : language.postEditSuccess );
                updateUserPrefs('displayInfoMessage', 1);
                updateUserPrefs("currentPage", 'Wall');

                // Update local calendar events state
                if (userPrefs?.newPost?.calendarEvent != undefined) {
                    const updatedCalendarEvent = {
                        ...userPrefs.newPost.calendarEvent,
                        eventdate: userPrefs.newPost.calendarEvent.eventdate.replace('T', ' ').split('.')[0],
                        name: userPrefs.newPost.calendarEvent.eventname,
                        postid: responseData.insertedpostid // Update the postid with X
                    };
                    // Add the new event to the postevents array using updateUserState
                    updateUserState('calendarevents', {
                        ...userState.calendarevents,
                        postevents: [
                            ...userState.calendarevents.postevents, 
                            updatedCalendarEvent // Add the new event
                        ]
                    });
                }
            }
            else{
                updateUserPrefs('infoMessage', language.failed);
                updateUserPrefs('displayInfoMessage', 1);
            }
        } catch (error) {
            console.error('Error:', error);

        }
    };

    const formRef = useRef(null);
    const sendButton =            
        <ButtonUniversal 
            text={language.send}
            backgroundColor={"orange"}
            style={{ zIndex: 1, color: "white", borderRadius: "clamp(0px,5vw,20px)", paddingLeft: "clamp(0px,5vw,15px)", paddingRight:"0px",  position: "fixed", top: "calc(var(--viewport-height) - (50px + clamp(0px,4vw,20px))", right: "clamp(0px,4vw,20px)", transform: "translate(0px,-100%)", opacity: userPrefs.currentPage == pageID ? 1 : 0  }}
            rightIcon={<MaterialSymbol icon={'send'} color='white' />}
            onClick={() => {
                if (formRef.current && formRef.current.reportValidity()) {
                    formRef.current.dispatchEvent(new Event('submit', { bubbles: true }));
                }
            }}
        />
    ;

    return (
        <>
            <AppendedToDocument className="New-Post-Send-Floating-Button">{sendButton}</AppendedToDocument>

            <div className='Page-Header'>
                
                <h3>{userPrefs.newPost.postid ? language.editPostHeader : language.newPostHeader}</h3>
                {!userPrefs.newPost.postid && (
                    <>
                        { userPrefs.currentOrg.orgname} {userPrefs.currentGroup.groupname}   
                    </>
                )}
            </div>

            <form ref={formRef} onSubmit={confirmSubmit} className='New-Post-Input-Top-Container'>
                <div className='New-Post-Input-Container' style={{marginBottom:"clamp(0px,4vw,20px)"}}>
                    <textarea 
                        type="text"
                        value={userPrefs?.newPost?.title || ''}
                        placeholder={language.enterPostTitle}
                        className="New-Post-Input Title"
                        ref={titleRef}
                        onChange={handleTitleChange}  // Handle character count change
                        maxLength="40" // Set the max length
                    />
                    <div className='New-Post-Title-UnderLine Red'></div>
                    <div className='Char-Count'>{titleCharCount}/40</div> {/* Display the character count */}
                </div>
                <div className='New-Post-Input-Container'>
                    <textarea 
                        value={userPrefs?.newPost?.text || ''}
                        type="text"
                        placeholder={language.enterPostText}
                        className="New-Post-Input Text"
                        ref={textRef}
                        onChange={handleTextChange}  // Handle character count change
                        maxLength="250" // Set the max length
                    />
                    <div className='New-Post-Title-UnderLine Blue'></div>
                    <div className='Char-Count'>{textCharCount}/250</div> {/* Display the character count */}
                    
                    {/* Original send button */}
                    {/* <button className='New-Post-Send-Icon' type="submit">
                        <MaterialSymbol 
                            icon={'send'}
                            size={'clamp(0px,6vw,25px)'}
                            grade={25}  
                            weight={300} 
                            style={{color:"white"}} 
                        />
                    </button> */}
                </div>

            </form>

            {/* Menu */}
            <div style={{backgroundColor:"white", paddingBottom:"clamp(0px,2vw,10px)", borderRadius:"clamp(0px,2vw,10px)"}}>
                <ButtonUniversal 
                    text={language.important} 
                    type='underline'
                    style={{width:"100%"}}
                    leftIcon={<span role="img">⚠️</span>} 
                    isToggle={true}
                    defaultToggled={importantIconChecked}
                    rightIconOnEnd={true}
                    onToggle={(newState) => {
                        setImportantIconChecked(newState);
                        updateUserPrefs('newPost', { ...userPrefs.newPost, importantIconChecked: newState });
                    }}
                />

                <ButtonUniversal 
                    text={language.requiresConfirmation} 
                    type='underline'
                    style={{width:"100%"}}
                    leftIcon={<span role="img">🤝</span>} 
                    isToggle={true}
                    defaultToggled={requiresConfirmationChecked}
                    rightIconOnEnd={true}
                    onToggle={(newState) => {
                        setRequiresConfirmationChecked(newState);
                        updateUserPrefs('newPost', { ...userPrefs.newPost, requiresConfirmation: newState });
                    }}
                />

                <ButtonUniversal 
                    text={language.image} 
                    type='underline'
                    style={{width:"100%"}}
                    leftIcon={<span role="img">🖼️</span>} 
                    onClick={handleGalleryClick}
                />
                <input 
                    type="file" 
                    ref={imagesInputRef} 
                    style={{ display: 'none' }} 
                    onChange={(event) => addFilesToUpload(event, "image")} 
                    accept="image/*"
                    multiple
                />

                <ButtonUniversal 
                    text={language.document} 
                    type='underline'
                    style={{width:"100%"}}
                    leftIcon={<span role="img">📄</span>}
                    onClick={handleDocumentsClick}
                />
                <input 
                    type="file" 
                    ref={documentsInputRef} 
                    style={{ display: 'none' }} 
                    onChange={(event) => addFilesToUpload(event, "document")} 
                    accept=".pdf,.doc,.docx,.xls,.xlsx,.txt,.ppt,.pptx"
                    multiple
                />

                <ButtonUniversal 
                    text={language.poll} 
                    type='underline'
                    style={{width:"100%"}}
                    leftIcon={<span role="img">📊</span>}
                    rightIcon={userPrefs?.newPost?.poll?.pollOptions?.length > 0 && (<span role="img" aria-label="checkmark" style={{ color: 'green' }}>✔</span>)}
                    rightIconOnEnd={true}
                    onClick={() => updateUserPrefs("currentPage","NewPostPoll")}
                />

                <ButtonUniversal 
                    text={language.addEvent} 
                    type='underline'
                    style={{width:"100%"}}
                    leftIcon={<span role="img">📆</span>} 
                    rightIcon={userPrefs?.newPost?.calendarEvent != undefined && (<span role="img" aria-label="checkmark" style={{ color: 'green' }}>✔</span>)}
                    rightIconOnEnd={true}
                    onClick={() =>  {
                        updateUserPrefs('isPostEvent', 1);
                        updateUserPrefs('displayCalendarNewEvent', 1);
                        if (userPrefs?.newPost?.calendarEvent != undefined){
                            updateUserPrefs('editCalendarEvent', userPrefs.newPost.calendarEvent);
                        }
                    }}
                />

            </div> 

            {/* Original post files (while editing) */}
            <div className="selected-images-list" style={{ width: "90%", margin: "auto", marginTop: "clamp(0px,4vw,15px)" }}>
            {Object.entries(groupFilesByTypeOrig(userPrefs?.newPost?.originalAttachments))
                .filter(([, files]) => files.length > 0) // Only include groups with files
                .map(([filetype, files], outerIndex) => (
                <div key={`${filetype}-${outerIndex}`} style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ fontWeight: "500" }}>{language[`originalUploaded${filetype}`]}</div>
                    <div style={{ display: "flex", flexWrap: "wrap", flexDirection: filetype === 'image' ? "row" : "column" }}>
                    {files.map((file, innerIndex) => (
                        filetype === 'image' ? (
                        <div key={file.filename || innerIndex} style={{ width: "25%", minWidth: "25%" }}>
                            <ImageItem
                            name={file.filename}
                            postId={userPrefs.newPost.postid}
                            index={innerIndex} 
                            fileType={file.filetype}
                            aspect={"1:1"}
                            onClose={() => removeOriginalFile(file)}
                            />
                        </div>
                        ) : (
                        <FileItem
                            key={file.filename || innerIndex} // Ensure unique key
                            name={file.filename}
                            fileType={file.filetype}
                            index={innerIndex}
                            onRemove={() => removeOriginalFile(file)}
                        />
                        )
                    ))}
                    </div>
                </div>
                ))}
            </div>

            {/* Picked files */}
            <div className="selected-images-list" style={{ width: "90%", margin: "auto", marginTop:"clamp(0px,4vw,15px)"}}>
                {Object.entries(groupFilesByType(userPrefs?.newPost?.files))
                    .filter(([, files]) => files.length > 0) // Only include groups with files
                    .map(([filetype, files]) => (
                        <div key={filetype} style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ fontWeight: "500" }}>{language[`uploaded${filetype}`]}</div>
                            {/* Display the group name */}
                            {files.map((file, index) => (
                                <FileItem 
                                    key={index} 
                                    name={file.file.name} 
                                    fileType={file.file.type}
                                    index={index} 
                                    onRemove={() => removeFilesToUpload(file)}
                                />
                            ))}
                        </div>
                    ))}
            </div>

            <div style={{height:"clamp(0px, 16vw, 100px)"}}></div>
        </>
    );
};

const EnhancedPage = AnimatedPage(PageContent, pageID);
const components = {
    [pageID]: () => <EnhancedPage />
};
export default components[pageID];