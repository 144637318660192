import React, { useContext, useEffect, useState } from 'react';

import AppContext from '../global/AppContext';
import TopBar from '../parts/topBar';
import NavBar from '../parts/navBar';
import LoginForm from '../parts/loginForm';
import Introduction from '../parts/introduction';
import MenuContent from '../parts/menuContent';
import AppContent from '../parts/appContent';
import PostAddModal from '../parts/postAddModal';
import { useSearchParams } from 'react-router-dom';
import { appPath } from '../global/utils';
import { SendRequest } from '../../webRequests';
import InfoMessage from '../parts/infoMessage';
import InfoLoading from '../parts/infoLoading';
import InfoConfirm from '../parts/infoConfirm';
import InfoOptions from '../parts/infoOptions';
import CalendarNewEvent from '../parts/calendarNewEvent';
import ImageGallery from '../parts/imageGallery';
import DatePickerModal from '../parts/datePickerModal';

const Home = () => {
    const { userPrefs, updateUserPrefs } = useContext(AppContext);
    const [searchParams] = useSearchParams();
    const verifyCode = searchParams.get('verifyacc');
    const [verifyCodeProcessing, setVerifyCodeProcessing] = useState(false);

    useEffect(() => {
        const processVerifyCode = async () => {
            console.log('checking verifycode:', verifyCode, ' wantLogIn=' , userPrefs.wantLogIn);
            try {
                const response = await SendRequest('checkverificationcode', { verifycode: verifyCode });
                console.log('validating verifycode:', verifyCode, ' checkstatus:', response , ' wantLogIn=' , userPrefs.wantLogIn);
                if (response.codevalid){
                    updateUserPrefs('verifyCode', verifyCode);
                    updateUserPrefs('verifyUserName', response.account);
                    updateUserPrefs('wantLogIn', 1);
                } else {
                    updateUserPrefs('verifyCode', '');
                }
            } catch (error) {
                console.error('Error checking verification code:', error);
                updateUserPrefs('verifyCode', '');
            }
        };

        if (verifyCode && userPrefs.verifyCode !== verifyCode){
            window.history.pushState({}, '', appPath());
            if (!verifyCodeProcessing){
                setVerifyCodeProcessing(true);
                processVerifyCode();
            }
        }
        else if (verifyCode === null && userPrefs.verifyCode !== ""){
            updateUserPrefs('verifyCode', '');
        }
    }, [verifyCode, updateUserPrefs, userPrefs.verifyCode, verifyCodeProcessing, userPrefs.wantLogIn]);

    return (
        <>
            <TopBar/>
            <NavBar/>
            <Introduction/>
            <AppContent/>
            <LoginForm/>
            <MenuContent/>
            <PostAddModal/>
            <InfoMessage/>
            <InfoLoading/>
            <InfoConfirm/>
            <InfoOptions/>
            <CalendarNewEvent/>
            <DatePickerModal/>
            <ImageGallery/>
        </>
    );
};

export default Home;