import { useState, useEffect, useMemo } from 'react';
import { saveDataToIDB, loadDataFromIDB } from '../../utils/IDB';
import { appPath } from './utils';

export const useUserPrefs = () => {
    const [userPrefs, setUserPrefs] = useState({});

    const databaseName = 'Nerliano';
    const version = 2;
    const objectStoreName = 'UserPrefs';

    const themes = useMemo(() => ({
        default: { path: appPath() + '/CSS/defaultTheme.css' },
        oldDefault: { path: appPath() + '/CSS/oldDefaultTheme.css' },
        modern: { path: appPath() + '/CSS/modernTheme.css' }
    }), []);

    const updateScrollY = async (value) => {
        const updatedPrefs = {
            ...userPrefs,
            scrollY: value,
        };
        setUserPrefs(updatedPrefs); // Update local state immediately
        saveDataToIDB(databaseName, version, objectStoreName, 'scrollY', value); // Persist to IndexedDB
    };

    const resetUserPrefs = async () => {
        console.log("Resetting user prefs");
        setUserPrefs({}); // Clear state first
        updateUserPrefs("currentPage", "HomeScreen");
        window.location.reload();
    
        // Clear the IndexedDB store
        try {
            const dbRequest = indexedDB.open(databaseName, version);
    
            dbRequest.onsuccess = function(event) {
                const db = event.target.result;
                const transaction = db.transaction(objectStoreName, 'readwrite');
                const objectStore = transaction.objectStore(objectStoreName);
    
                // Clear all records in the object store
                const clearRequest = objectStore.clear();
    
                clearRequest.onsuccess = function() {
                    console.log("IndexedDB user preferences cleared successfully");
                };
    
                clearRequest.onerror = function(event) {
                    console.error("Failed to clear IndexedDB user preferences", event.target.error);
                };
            };
    
            dbRequest.onerror = function(event) {
                console.error("Failed to open IndexedDB for clearing", event.target.error);
            };
        } catch (error) {
            console.error("An error occurred while resetting user prefs", error);
        }
    };

    const getUserPrefs = async () => {
        try {
            const prefs = await loadDataFromIDB(databaseName, version, objectStoreName);
            if (prefs) {
            setUserPrefs(prefs);
            console.log("getUserPrefs:" , prefs);
            }
        } catch (error) {
            console.warn(error);
        }
    };

    const updateUserPrefs = async (key, value) => {
        if (userPrefs.key !== value) {
            console.log("Updating user prefs:" + key + " " + value);
    
            setUserPrefs((prevState) => {
                const updatedPrefs = {
                    ...prevState,
                    [key]: value,
                };
    
                // Track currentPage changes for browsing history
                if (key === "currentPage") {
                    //console.log("Updating history");
    
                    // Initialize browseHistory if it doesn't exist
                    let updatedHistory = prevState.browseHistory ? [...prevState.browseHistory] : [];
    
                    // Add the new page to the history
                    updatedHistory.push(value);
    
                    // Limit the history to 20 entries
                    if (updatedHistory.length > 20) {
                        updatedHistory = updatedHistory.slice(-20); // Keep only the last 20 entries
                    }
    
                    // Update the preferences with the new history
                    updatedPrefs.browseHistory = updatedHistory;
    
                    // Save browseHistory separately
                    saveDataToIDB(databaseName, version, objectStoreName, 'browseHistory', updatedHistory);
    
                    //console.log(updatedHistory); // Updated line to check the new history after limiting
                }
    
                // Save the updated preferences object except browseHistory
                saveDataToIDB(databaseName, version, objectStoreName, key, value);
    
                return updatedPrefs;
            });
        } else {
            console.log("user prefs not updated ... key", key, "already has value", value);
        }
    };

    const enterApp = async (groupsCount) => {
        updateUserPrefs('menuOpen', 0);
        updateUserPrefs("currentPage", groupsCount ? 'HomeScreen' : "HomeScreen");
        console.log("on app enter page " , userPrefs.currentPage);
    }

    const navBack = async (mainPage) => {

        setUserPrefs((prevState) => {
            const updatedPrefs = { ...prevState };
    
            // Check if browseHistory exists and has items
            if (updatedPrefs.browseHistory && updatedPrefs.browseHistory.length > 0) {
                // Get the last page from the history
                const lastPage = updatedPrefs.browseHistory[updatedPrefs.browseHistory.length - 1];
    
                // Check if currentPage is the same as the last page
                if (updatedPrefs.currentPage === lastPage) {
                    // Pop the last page from the history
                    updatedPrefs.browseHistory.pop();
    
                    // Set currentPage to the new last page if available
                    if (updatedPrefs.browseHistory.length > 0) {
                        updatedPrefs.currentPage = updatedPrefs.browseHistory[updatedPrefs.browseHistory.length - 1];
                    } else {
                        // If no pages left in history, reset currentPage to a default or mainPage
                        updatedPrefs.currentPage = mainPage;
                    }
                } else {
                    // Otherwise, set the currentPage to the last page
                    updatedPrefs.currentPage = lastPage;
                }
    
                // If the last page is the mainPage, clear the history
                if (updatedPrefs.currentPage === mainPage) {
                    updatedPrefs.browseHistory = [];
                }
    
                // Save the updated prefs to IndexedDB
                saveDataToIDB(databaseName, version, objectStoreName, 'currentPage', updatedPrefs.currentPage);
                saveDataToIDB(databaseName, version, objectStoreName, 'browseHistory', updatedPrefs.browseHistory);
            }
    
            return updatedPrefs;
        });
    };

    useEffect(() => {
        const getPwaMode = () => {
            const isPWA = window.matchMedia('(display-mode: standalone)').matches ||
                          window.matchMedia('(display-mode: fullscreen)').matches ||
                          window.matchMedia('(display-mode: minimal-ui)').matches ||
                          window.navigator.standalone === true;
            updateUserPrefs('pwaMode', isPWA);
        };
    
        getUserPrefs();
        getPwaMode();
        //updateUserPrefs('pwaMode', true);
    }, []);

    useEffect(() => {
        // console.log("userPrefs updated", userPrefs);
    }, [userPrefs]);

    return { userPrefs, themes, updateScrollY, resetUserPrefs, getUserPrefs, updateUserPrefs, enterApp, navBack };
};