import React, { useEffect, useState } from 'react';
import { appPath } from '../global/utils';
import './userAvatar.css';
import { getRandomGradient } from '../global/utils';
import { SendRequest } from '../../webRequests';

const UserAvatar = ({ user, isButton = true, onClick, size, small = false, style = {}, outlineStyle = {}, displayName = false , displayFullName = false}) => {
    const [imageExists, setImageExists] = useState(true);
    const [imageUrl, setImageUrl] = useState(appPath() + `/profileimages/${user?.profileimagename}`);
    const [isCheckingImage, setIsCheckingImage] = useState(false);

    useEffect(() => {
        if (user?.profileimagename) {
            setImageUrl(appPath() + `/profileimages/${user.profileimagename}`);
        } else {
            setImageUrl(null); // Reset if no image available
        }
    }, [user]);

    useEffect(() => {
        const fetchData = async () => {
            if (!isCheckingImage) {
                setIsCheckingImage(true);

                try {
                    const checkImageExists = async () => {
                        try {
                            const response = await fetch(imageUrl, { method: 'HEAD' });
                            const contentType = response.headers.get('content-type');
                            //console.log("UserAvatar Image fetch:", response, "Contentype:", contentType);
                            if (!response.ok || !contentType.includes('image')) {
                                //console.log("Response not OK:", response, contentType);
                                setImageExists(false);
                                //console.log("Image for user ", user.firstname , " not found:", imageUrl);
                            }
                            else{
                                setImageExists(true);
                                //console.log("Image for user ", user.firstname , " OK:", imageUrl);
                            }
                        } catch (error) {
                            //console.log("Image not found:", error);
                            setImageExists(false);
                            setIsCheckingImage(false);
                        } finally {
                            setIsCheckingImage(false);
                        }
                    };
        
                    checkImageExists();
                } catch (error) {
                    console.error("Failed to fetch user data:", error);
                }
            }
        };
    
        fetchData();  // Call the async function inside useEffect
    }, [user, imageUrl]); 

    const avatarClassName = small ? 'User-Avatar-Small' : 'User-Avatar';
    const backgroundStyle = imageExists
        ? { backgroundImage: `url(${imageUrl})` }
        : { background: getRandomGradient(parseInt(user?.userid)) };

    const fullName = `${user?.firstname} ${user?.lastname}`;
    const appliedSize = small || !size ? {} : { width: size, height: size };

    return (
        <div className='UserAvatar' style={{ display: "flex", flexDirection: "column", textAlign: "center", alignItems: "center", justifyContent: "center", ...style }}>
            {isButton ? (
                <button
                    onClick={onClick}
                    className={`${avatarClassName} Shadow-3`}
                    style={{ 
                        ...appliedSize, // Apply size only if not small and size is provided
                        ...backgroundStyle, 
                        ...outlineStyle 
                    }}
                    title={fullName}
                >
                    {imageExists ? (
                        ""
                    ) : (
                        <div className="Initials" style={{fontSize: small ? 'clamp(0px,3vw,12px)' : size ? `${size / 2}px` : 'clamp(0px,4vw,16px)'}}>
                            {(user?.firstname?.charAt(0) || '') + (user?.lastname?.charAt(0) || '')}
                        </div>
                    )}
                </button>
            ) : (
                <div
                    onClick={onClick}
                    className={`${avatarClassName} Shadow-3`}
                    style={{ 
                        ...appliedSize, // Apply size only if not small and size is provided
                        ...backgroundStyle, 
                        ...outlineStyle 
                    }}
                    title={fullName}
                >
                    {imageExists ? (
                        ""
                    ) : (
                        <div className="Initials">
                            {(user?.firstname?.charAt(0) || '') + (user?.lastname?.charAt(0) || '')}
                        </div>
                    )}
                </div>
            )}
            {displayName ? (
                <div className="UserName" style={{ fontSize: "clamp(0px,3vw,12px)", marginTop:"clamp(0px, 2vw, 5px)"}}>
                    {displayFullName
                        ? `${user?.firstname} ${user?.lastname}` 
                        : `${user?.firstname} ${user?.lastname?.charAt(0)}.` 
                    }
                </div>
            ) : null}
        </div>
    );
};

export default UserAvatar;