import React, { useContext, useState, useRef, useEffect } from 'react';
import AnimatedPage from './animatedPage';
import AppContext from '../global/AppContext';
import ButtonWithIcon from '../elements/buttonWithIcon';
import ButtonUniversal from '../elements/buttonUniversal';
import UserAvatar from '../elements/userAvatar';
import AppendedToDocument from '../elements/appendedToDocument';
import { formatPostDate } from '../global/utils';
import { MaterialSymbol } from 'react-material-symbols';
import { DayPicker } from 'react-day-picker';
import Picker from 'react-mobile-picker';
import { cs } from 'date-fns/locale/cs';
import { SendRequest } from '../../webRequests';
import { ReactComponent as ModryPulkruh } from '../../assets/icons/svg/Modry_Pulkruh.svg';
import { ReactComponent as SvgCalendar } from '../../assets/icons/svg/Kalendar.svg';
import { ReactComponent as Tabletky } from '../../assets/icons/svg/Tabletky.svg';
import AbsentNote from '../elements/absentNote';


const pageID = "NewAbsentNote";

const PageContent = () => {

    const { language, userState, userPrefs, updateUserState, updateUserPrefs } = useContext(AppContext);
    const textAreaRef = useRef(null);
    const [selectedReason, setSelectedReason] = useState(null);
    const [selectedType, setSelectedType] = useState(null);

    const now = new Date();
    const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);

    const formatDate = (date) => {
        return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().slice(0, 19).replace("T", " ");
    };

    const initialFromDate = formatDate(new Date(tomorrow.setHours(0, 0, 0, 0)));
    const initialToDate = formatDate(new Date(tomorrow.setHours(23, 59, 59, 999)));

    const [previewNote, setPreviewNote] = useState({
        from: "",
        to: "",
        reasonid: -1,
        notes: ""
    });


    const send = async () => {

        if (needsReason){
            updateUserPrefs('infoMessage',language.absentNoteAskForReason);
            updateUserPrefs('displayInfoMessage', 1);
            return;
        }

        const dateFrom = userPrefs?.datePickerStorage?.newAbsentNoteFrom;
        const dateTo = userPrefs?.datePickerStorage?.newAbsentNoteTo;

        console.log("SEND");
        console.log("From:", dateFrom);
        console.log("To:", dateTo);
        // console.log("Note:", textAreaRef.current.value);

        console.log("From:", dateFrom.getTime(), "To", dateTo.getTime());

        const requestData = {
            userid: userState.user.userid,
            childid: userPrefs.selectedChild.userid,
            groupid: userPrefs.selectedChild.groups[0].groupid,
            datefrom: new Date(dateFrom.getTime() - dateFrom.getTimezoneOffset() * 60000).toISOString().slice(0, 19).replace("T", " "),
            dateto: new Date(dateTo.getTime() - dateTo.getTimezoneOffset() * 60000).toISOString().slice(0, 19).replace("T", " "),
            reasonid: previewNote.reasonid,
            //note: textAreaRef.current.value
        };

        try {
            const responseData = await SendRequest('sendabsentnote', { requestdata: requestData });
            console.log("sendabsentnote:", responseData);

            if (responseData.success) {
                cacheNewNote(requestData, responseData.noteid);
                updateUserPrefs('infoMessage', language.absentNoteSuccesfullySend);
            }
            else {
                updateUserPrefs('infoMessage', language.failed);
            }

            updateUserPrefs('displayInfoMessage', 1);
            updateUserPrefs("currentPage", "AbsentNotesParentPanel");
        } catch (error) {
            console.error("Error fetching group book data:", error);
        }
    };

    const cacheNewNote = async (data, noteid) => {
        const { childid, note, datefrom, dateto } = data;

        // Find the child user with the given childid
        const updatedChildUsers = userState.childusers.map(child => {
            if (child.userid === childid) {
                // Add the new note to the absentNotes array of the matched child
                const newAbsentNote = { // Unique id for the absent note (can be modified)
                    id: noteid,
                    userid: childid,
                    byuserid: userState.user.userid, // Assuming the note is sent by the logged-in user
                    orgid: null,
                    groupid: userPrefs.selectedChild.groups[0].groupid, // Set this to null or any groupid if needed
                    reasonid: data.reasonid,
                    notes: note,
                    from: datefrom,
                    to: dateto,
                    created_at: new Date().toISOString() // Timestamp for when the note was created
                };

                // Add the new absent note to the child's absentNotes array
                return {
                    ...child,
                    absentnotes: [...child.absentnotes, newAbsentNote]
                };
            }

            // If the child doesn't match, return the child as is
            return child;
        });

        // Update the userState with the modified childUsers list
        updateUserState('childusers', updatedChildUsers);

        console.log("Updated child users:", updatedChildUsers, "UserState:", userState);
    };

    const handleTimeSelection = (type) => {

        setSelectedType(type);

        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        let startTime, endTime;

        if (type === "morning") {
            startTime = new Date(tomorrow.setHours(0, 0, 0, 0)); // Morning start: 6 AM
            endTime = new Date(tomorrow.setHours(11, 59, 59, 999)); // Morning end: 12 PM
        } else if (type === "allday") {
            startTime = new Date(tomorrow.setHours(0, 0, 0, 0)); // All day start: midnight
            endTime = new Date(tomorrow.setHours(23, 59, 59, 999)); // All day end: 11:59 PM
        } else if (type === "evening") {
            startTime = new Date(tomorrow.setHours(12, 0, 0, 0)); // Evening start: 6 PM
            endTime = new Date(tomorrow.setHours(23, 59, 59, 999)); // Evening end: 11:59 PM
        }

        setPreviewNote(prevState => ({
            ...prevState,
            from: formatDate(startTime),
            to: formatDate(endTime),
        }));

        // Update userPrefs with the selected dates
        updateUserPrefs("datePickerStorage", {
            ...userPrefs?.datePickerStorage,
            newAbsentNoteFrom: startTime,
            newAbsentNoteTo: endTime,
        });
    };

    const handleReasonSelection = (reasonid) => {
        setSelectedReason(reasonid);
        setPreviewNote(prevState => ({
            ...prevState,
            reasonid: reasonid,
        }));
    };

    const handleTextareaChange = () => {
        // Adjust the textarea height dynamically
        if (textAreaRef.current) {
            textAreaRef.current.style.height = 'auto';
            if (textAreaRef.current.scrollHeight > textAreaRef.current.clientHeight) {
                const scrollHeight = textAreaRef.current.scrollHeight;
                const maxHeight = window.innerHeight * 0.3;
                textAreaRef.current.style.height = `${Math.min(scrollHeight, maxHeight)}px`;
            }
        }
    };

    useEffect(() => {
        if (userPrefs.currentPage == pageID) {
            updateUserPrefs("datePickerStorage", null);
            updateUserPrefs("displayDatePickerModal", 0);

            setPreviewNote(prevState => ({
                ...prevState,
                from: "",
                to: "",
            }));
        }
    }, [userPrefs.currentPage]);

    useEffect(() => {
        const fetchData = async () => {
            if (userPrefs?.datePickerStorage?.newAbsentNoteFrom != undefined) {

                setPreviewNote(prevState => ({
                    ...prevState,
                    from: formatDate(userPrefs?.datePickerStorage?.newAbsentNoteFrom),
                }));

                console.log("newAbsentNoteFrom updated:", userPrefs?.datePickerStorage?.newAbsentNoteFrom);
                
                if (userPrefs?.datePickerStorage?.newAbsentNoteTo == undefined){

                    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
                    await delay(300);
                    
                    const dateStoreKey = "newAbsentNoteTo";
                    const tomorrow = new Date();
                    tomorrow.setDate(tomorrow.getDate() + 1);
                    console.log("Today:", new Date(), " Tomorrow:", tomorrow);
                    const datePickerData = { fromDate: userPrefs?.datePickerStorage?.newAbsentNoteFrom, initDate: userPrefs?.datePickerStorage?.[`${dateStoreKey}`] == null ? tomorrow : userPrefs?.datePickerStorage?.[`${dateStoreKey}`], dateStoreKey: dateStoreKey, ignoreTime: true, presetHour: "23", presetMinute: "59", title: language.absentNotePickTo }
                    updateUserPrefs("displayDatePickerModal", datePickerData);
                }             
            }
        };

        fetchData();
    }, [userPrefs?.datePickerStorage?.newAbsentNoteFrom]);

    useEffect(() => {
        if (userPrefs?.datePickerStorage?.newAbsentNoteTo != undefined) {
            console.log("newAbsentNoteFrom updated:", userPrefs?.datePickerStorage?.newAbsentNoteFrom);
            setPreviewNote(prevState => ({
                ...prevState,
                to: formatDate(userPrefs?.datePickerStorage?.newAbsentNoteTo),
            }));
        }
        else{
            setPreviewNote(prevState => ({
                ...prevState,
                to: "",
            }));
        }
    }, [userPrefs?.datePickerStorage?.newAbsentNoteTo]);

    const pickDateFrom = async (dateStoreKey) => {

        setSelectedType(null);

        setPreviewNote(prevState => ({
            ...prevState,
            from: "",
            to: "",
        }));

        updateUserPrefs("datePickerStorage", null);

        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        console.log("Today:", new Date(), " Tomorrow:", tomorrow);
        const datePickerData = { initDate: userPrefs?.datePickerStorage?.[`${dateStoreKey}`] == null ? tomorrow : userPrefs?.datePickerStorage?.[`${dateStoreKey}`], dateStoreKey: dateStoreKey, ignoreTime: true, presetHour: "00", presetMinute: "00", title: language.absentNotePickFrom }
        updateUserPrefs("displayDatePickerModal", datePickerData);
    }

    const canSend = previewNote.from !== "" && previewNote.to !== "";
    const needsReason = previewNote.reasonid == -1;

    const sendButton =
        <ButtonUniversal
            text={language.send}
            backgroundColor={canSend ? "orange" : "#ffa5005e"}
            onClick={canSend ? () => send() : null}
            style={{ zIndex: 1, color: "white", borderRadius: "clamp(0px,5vw,20px)", paddingLeft: "clamp(0px,5vw,15px)", paddingRight: "0px", position: "fixed", top: "calc(var(--viewport-height) - (50px + clamp(0px,4vw,20px))", right: "clamp(0px,4vw,20px)", transform: "translate(0px,-100%)", opacity: userPrefs.currentPage == pageID ? 1 : 0 }}
            rightIcon={<MaterialSymbol icon={'send'} color='white' />}
        />;

    return (
        <>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <AppendedToDocument className="Save-Group-Book-Floating-Button">{sendButton}</AppendedToDocument>

                <ModryPulkruh className='Signature-Graphic-1' />
                <Tabletky style={{ zIndex: "-1", width: "70vw", position: "absolute", marginTop: "clamp(0px,15vw,100px)" }} />
            </div>
            <div>

                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "clamp(0px,20vw,200px)" }}>
                    {/* <h3 style={{ marginBottom: "15px", paddingInline: "15px" }}>{language.newAbsentNote}</h3> */}

                    <div style={{ marginTop: "clamp(0px,5vw,20px)" }}>
                        <UserAvatar user={userPrefs.selectedChild} displayName={false} displayFullName={true} />
                    </div>

                    <div className='Spacer-Line-1' style={{ marginTop: "clamp(0px,5vw,200px)" }}></div>

                    <div style={{ marginTop: "clamp(0px,3vw,20px)" }}>{userPrefs.selectedChild.groups[0].orgname}</div>
                    <div style={{ marginBottom: "clamp(0px,3vw,20px)" }}>{userPrefs.selectedChild.groups[0].name}</div>

                    <ButtonUniversal
                        type={'flat'}
                        text={language.absentNoteForTomorrow}
                        style={{ borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px", borderTopLeftRadius: "clamp(0px,3vw,15px)", borderTopRightRadius: "clamp(0px,3vw,15px)", fontSize: "clamp(0px,5vw,20px)", fontWeight: "400", backgroundColor: "#3aaa34", color: "white", justifyContent: "center", height: "clamp(0px,16vw,50px)", width: "80%", padding: "clamp(0px,2vw,10px)" }}
                        rightIconOnEnd={true}
                    />
                    <div style={{ display: "flex", width: "80%", paddingBottom: "clamp(0px,5vw,25px)" }}>
                        <ButtonUniversal
                            type={'flat'}
                            text={language.morning}
                            style={{ background: selectedType == "morning" ? "linear-gradient(90deg, #5ac3e3, #3375b7)" : "white", cursor: "pointer", textTransform: "uppercase", borderWidth: "0px 1px 2px 2px", width: "33.33%", borderColor: "#3aaa34", borderStyle: "solid", borderBottomLeftRadius: "clamp(0px,3vw,15px)", borderBottomRightRadius: "0px", borderTopLeftRadius: "0px", borderTopRightRadius: "0px", fontSize: "clamp(0px,2.9vw,12px)", fontWeight: "700", color: selectedType == "morning" ? "white" : "#3aaa34", justifyContent: "center", height: "clamp(0px,6vw,20px)", padding: "clamp(0px,2vw,10px)" }}
                            rightIconOnEnd={true}
                            onClick={() => handleTimeSelection("morning")}
                        />
                        <ButtonUniversal
                            type={'flat'}
                            text={language.allDay}
                            style={{ background: selectedType == "allday" ? "linear-gradient(90deg, #5ac3e3, #3375b7)" : "white", cursor: "pointer", textTransform: "uppercase", borderWidth: "0px 1px 2px 1px", width: "33.33%", borderColor: "#3aaa34", borderStyle: "solid", borderRadius: "0px", fontSize: "clamp(0px,2.9vw,12px)", fontWeight: "700", color: selectedType == "allday" ? "white" : "#3aaa34", justifyContent: "center", height: "clamp(0px,6vw,20px)", padding: "clamp(0px,2vw,10px)" }}
                            rightIconOnEnd={true}
                            onClick={() => handleTimeSelection("allday")}
                        />
                        <ButtonUniversal
                            type={'flat'}
                            text={language.evening}
                            style={{ background: selectedType == "evening" ? "linear-gradient(90deg, #5ac3e3, #3375b7)" : "white", cursor: "pointer", textTransform: "uppercase", borderWidth: "0px 2px 2px 1px", width: "33.33%", borderColor: "#3aaa34", borderStyle: "solid", borderBottomLeftRadius: "0px", borderBottomRightRadius: "clamp(0px,3vw,15px)", borderTopLeftRadius: "0px", borderTopRightRadius: "0px", fontSize: "clamp(0px,2.9vw,12px)", fontWeight: "700", color: selectedType == "evening" ? "white" : "#3aaa34", justifyContent: "center", height: "clamp(0px,6vw,20px)", padding: "clamp(0px,2vw,10px)" }}
                            rightIconOnEnd={true}
                            onClick={() => handleTimeSelection("evening")}
                        />

                    </div>

                    <ButtonUniversal
                        type={'flat'}
                        text={language.absentNoteFromTo}
                        rightIcon={<SvgCalendar style={{ transform: "translate(-3vw, 0px)" }} />}
                        style={{ cursor: "pointer", borderRadius: "clamp(0px,3vw,15px)", fontSize: "clamp(0px,5vw,20px)", fontWeight: "400", backgroundColor: "#6e98d0", color: "white", justifyContent: "center", height: "clamp(0px,25vw,65px)", width: "80%", padding: "clamp(0px,2vw,10px)" }}
                        rightIconOnEnd={true}
                        onClick={() => pickDateFrom("newAbsentNoteFrom")}
                    />

                    <div className='Spacer-Line-1' style={{ marginBlock: "clamp(0px,6vw,200px)" }}></div>

                    <div style={{ width: "90%", display: "flex", justifyContent: "space-evenly" }}>
                        <ButtonUniversal
                            type={'flat'}
                            text={language.absentReasonIllness}
                            style={{ color: selectedReason == 1 ? "#3375b7" : "white", borderColor: selectedReason == 1 ? "#3375b7" : "white", background: selectedReason == 1 ? "white" : "linear-gradient(90deg, #5ac3e3, #3375b7)", cursor: "pointer", textWrap: "nowrap", borderRadius: "clamp(0px,2vw,10px)", fontSize: "clamp(0px,4vw,20px)", fontWeight: "400", backgroundColor: "#6e98d0", justifyContent: "center", height: "clamp(0px,9vw,50px)", width: "23%", padding: "clamp(0px,2vw,10px)", borderStyle: "solid" }}
                            onClick={() => handleReasonSelection(1)}
                        />
                        <ButtonUniversal
                            type={'flat'}
                            text={language.absentReasonDoctor}
                            style={{ color: selectedReason == 2 ? "#3fab35" : "white", borderColor: selectedReason == 2 ? "#3fab35" : "white", background: selectedReason == 2 ? "white" : "linear-gradient(90deg, #74bc79, #3fab35)", cursor: "pointer", textWrap: "nowrap", borderRadius: "clamp(0px,2vw,10px)", fontSize: "clamp(0px,4vw,20px)", fontWeight: "400", backgroundColor: "#6e98d0", justifyContent: "center", height: "clamp(0px,9vw,50px)", width: "23%", padding: "clamp(0px,2vw,10px)", borderStyle: "solid" }}
                            onClick={() => handleReasonSelection(2)}
                        />
                        <ButtonUniversal
                            type={'flat'}
                            text={language.absentReasonHolidays}
                            style={{ color: selectedReason == 3 ? "#c24519" : "white", borderColor: selectedReason == 3 ? "#c24519" : "white", background: selectedReason == 3 ? "white" : "linear-gradient(90deg, #e96418, #c24519)", cursor: "pointer", textWrap: "nowrap", borderRadius: "clamp(0px,2vw,10px)", fontSize: "clamp(0px,4vw,20px)", fontWeight: "400", backgroundColor: "#6e98d0", justifyContent: "center", height: "clamp(0px,9vw,50px)", width: "23%", padding: "clamp(0px,2vw,10px)", borderStyle: "solid" }}
                            onClick={() => handleReasonSelection(3)}
                        />
                        <ButtonUniversal
                            type={'flat'}
                            text={language.absentReasonOther}
                            style={{ color: selectedReason == 0 ? "#fbcc18" : "white", borderColor: selectedReason == 0 ? "#fbcc18" : "white", background: selectedReason == 0 ? "white" : "linear-gradient(90deg, #f8e821, #fbcc18)", cursor: "pointer", textWrap: "nowrap", borderRadius: "clamp(0px,2vw,10px)", fontSize: "clamp(0px,4vw,20px)", fontWeight: "400", backgroundColor: "#6e98d0", justifyContent: "center", height: "clamp(0px,9vw,50px)", width: "23%", padding: "clamp(0px,2vw,10px)", borderStyle: "solid" }}
                            onClick={() => handleReasonSelection(0)}
                        />
                    </div>

                    <div className='Spacer-Line-1' style={{ marginBlock: "clamp(0px,6vw,200px)" }}></div>

                    <div style={{ fontSize: "clamp(0px,5vw,20px)", fontWeight: "400", marginBottom: "0px", paddingInline: "15px" }}>{language.absentNotePreview}</div>

                    <AbsentNote
                        note={previewNote}
                        user={userPrefs.selectedChild} // Pass the child reference
                        style={{ width: "90%", margin: "auto", marginBottom: "clamp(0px,5vw,20px)" }}
                    />
                    {/* 
                    <h4 style={{ marginBottom: "0px", paddingInline: "15px" }}>{language.from}</h4>
                    <ButtonUniversal
                        text={userPrefs?.datePickerStorage?.newAbsentNoteFrom == null ? language.pickDateTime : `${formatPostDate(userPrefs.datePickerStorage.newAbsentNoteFrom)}`}
                        style={{ boxShadow: "1px 1px 5px 0px #00000063", width: "80%", padding: "clamp(0px,2vw,10px)", marginBlock: "clamp(0px,1vw,5px)" }}
                        rightIcon={<SvgCalendar style={svgIconStyle} />}
                        rightIconOnEnd={true}
                        onClick={() => pickDate("newAbsentNoteFrom")} // Toggle checkbox on button click
                    />

                    <h4 style={{ marginBottom: "0px", paddingInline: "15px" }}>{language.to}</h4>
                    <ButtonUniversal
                        text={userPrefs?.datePickerStorage?.newAbsentNoteTo == null ? language.pickDateTime : `${formatPostDate(userPrefs.datePickerStorage.newAbsentNoteTo)}`}
                        style={{ boxShadow: "1px 1px 5px 0px #00000063", width: "80%", padding: "clamp(0px,2vw,10px)", marginBlock: "clamp(0px,1vw,5px)" }}
                        rightIcon={<SvgCalendar style={svgIconStyle} />}
                        rightIconOnEnd={true}
                        onClick={() => pickDate("newAbsentNoteTo")} // Toggle checkbox on button click
                    />

                    <h4 style={{ marginBottom: "0px", paddingInline: "15px" }}>{language.note}</h4>
                    <div className='New-Post-Input-Container' style={{ width: "80%" }}>
                        <textarea
                            ref={textAreaRef}
                            defaultValue={''}
                            type="text"
                            placeholder={language.absentNoteNotes}
                            className="New-Post-Input"
                            onChange={handleTextareaChange}
                        />
                        <div className='New-Post-Title-UnderLine Blue'></div>
                    </div> */}
                </div>
            </div>
        </>
    );
};

const EnhancedPage = AnimatedPage(PageContent, pageID, 'currentPage', false);
const components = {
    [pageID]: () => <EnhancedPage />
};
export default components[pageID];