import React, {useContext} from 'react';
import AppContext from '../global/AppContext';
import Post from '../elements/post';

const Posts = ({ posts }) => {
    const { language, userState } = useContext(AppContext);

    return (
        <div className="Posts">
            
            {posts?.length > 0 ? (
                posts.map((post) => (
                    <Post key={post.postid} postData={post} showChildIndicators={userState?.childusers?.length > 1} />
                ))
            ) : (
                <div style={{textAlign:"center"}}>{language.noPosts}</div>
            )}
        </div>
    );
};

export default Posts;