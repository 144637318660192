import React, { useContext, useState } from 'react';
import AppContext from '../global/AppContext';
import './topBar.css';
import { MaterialSymbol } from 'react-material-symbols';
import { ReactComponent as NerligoLogo } from '../../assets/icons/svg/nerligo_logo.svg';

const TopBar = () => {
    const { language, userPrefs, userState, updateUserPrefs, navBack } = useContext(AppContext);
    const [barOffset] = useState(0);
    // const [lastScrollPosition, setLastScrollPosition] = useState(0);

    // useEffect(() => {

    //     if (userState.loginstatus !== 1){
    //         setBarOffset(0);
    //     }

    //     if (userPrefs.scrollY > lastScrollPosition){
    //         setBarOffset(Math.min(barOffset - (lastScrollPosition - userPrefs.scrollY), 50));
    //         setLastScrollPosition(userPrefs.scrollY);
    //     }
    //     else if (userPrefs.scrollY < lastScrollPosition){
    //         setBarOffset(Math.max(barOffset - (lastScrollPosition - userPrefs.scrollY), 0));
    //         setLastScrollPosition(userPrefs.scrollY);
    //     }
    // }, [userPrefs.scrollY, barOffset, lastScrollPosition, userState.loginstatus, logOut]);

    const wantLogIn = () => {
        updateUserPrefs('wantLogIn', 1);
    };

    // const wantLogOut = () => {
    //     updateUserPrefs('wantLogIn', 0);
    //     logOut();
    // };

    const openCloseMenu = () => {
        updateUserPrefs('menuOpen', userPrefs.menuOpen === 0 ? 1 : 0);
    };

    const loginstatus = userState?.loginstatus || 0;
    const mainPage = userState?.usergroups?.length > 0 ? "HomeScreen" : "Wall";

    return (
        <div className='TopBar CLR-BG-TopBar' style={{ transform: `translateY(${-barOffset}px)`}}>
            
            {userState?.loginstatus === 1 && (
                <button type="button" className={`CLR-TXT-NavBar Icon Left NavBack`} onClick={() => navBack(mainPage)} style={{width: userPrefs.currentPage === mainPage ? "0px" : "", padding:"0px"}}>
                    <MaterialSymbol 
                        icon='arrow_back'
                        //fill={1}
                        grade={25}  
                        weight={300} 
                        style={{color:"gray"}} 
                    />
                </button>
            )}

            <div className='Logo' style={{width:"clamp(0px, 30vw, 100px)"}}><NerligoLogo/></div>
            {loginstatus === 0 && (
                <div className='WrappedTopBarButton Right Last'>
                    <button type="button" className="Icon-Circle CLR-BTN-1" onClick={() => wantLogIn()} style={{ float: 'right', paddingInline:"0px" }}>
                        {/* {language.loginFormTitle} */}
                        <MaterialSymbol icon="login" grade={-25} />
                    </button>
                </div>
            )}

            {loginstatus === 1 && (
                <button type="button" className={`CLR-TXT-NavBar Icon Right`} onClick={() => openCloseMenu()}>
                    <MaterialSymbol 
                        icon='settings'
                        //fill={1}
                        grade={25}  
                        weight={300} 
                        style={{color:"gray"}} 
                        />
                </button>
            )}
        </div>
    );
};

export default TopBar;