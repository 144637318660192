import { useState, useEffect, useMemo } from 'react';
import { saveDataToIDB, loadDataFromIDB } from '../../utils/IDB';
import { appPath } from './utils';

export const useOptions = () => {

    const [options, setOptions] = useState({
        isOpen: false,
        canClose: true,
        onClose: undefined,
        showIcon: true,
        message: '',
        onConfirm: null,
        onCancel: null,
    });

    const showOptions = (options) => {
        setOptions({
          isOpen: true,
          canClose: options.canClose,
          onClose: options.onClose,
          showIcon: options.showIcon,
          message: options.message,
          buttons: options.buttons
        });
    };

    const showOptionsOnly = () => {
      setOptions({
        isOpen: true
      });
    };
      
    const hideOptions = () => {
        setOptions(prevOptions => ({
          ...prevOptions, // Retain the existing values
          isOpen: false, // Only change isOpen to false
        }));
    };

    return { options, showOptions, hideOptions, showOptionsOnly };
};