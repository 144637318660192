import React, { useContext, useEffect, useState } from 'react';
import AnimatedPage from './animatedPage';
import AppContext from '../global/AppContext';
import ButtonWithIcon from '../elements/buttonWithIcon';
import { MaterialSymbol } from 'react-material-symbols';
import { SendRequest } from '../../webRequests';

const pageID = "OrganizationGroups";

const PageContent = () => {
  const { language, updateUserPrefs, userState, userPrefs } = useContext(AppContext);
  const [groups, setGroups] = useState([]);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [ groupsPending, setGroupsPending ] = useState(false);
  const hasGroups = filteredGroups.length > 0;
  const orgId = userPrefs.currentOrg.orgid;

  useEffect(() => {
      setGroups(userState.usergroups);
      getOrgGroups();
      //console.log("GROUPS:", userState.usergroups);
  }, [userState.usergroups]);

  const getOrgGroups = async () => {

    const requestData = { orgid: orgId };
    const responseData = await SendRequest('getorganizationgroups', { requestdata: requestData });
    console.log("getorganizationgroups", responseData);
    if ( responseData && responseData.orggroups){
        setFilteredGroups(responseData.orggroups || []);
    }

    // console.log("orgUsers: ", orgUsers);
    setGroupsPending(false);
  };

  const handleGroupButtonClick = (group) => {
    console.log(`Group button clicked for group: ${group.groupname}`);
    updateUserPrefs("groupDetail", group);
    updateUserPrefs("currentPage", "GroupDetail");
    updateUserPrefs("currentGroup", group);
    // Perform actions specific to the group button click
  };

  const gotoGroup = (group) => {
    updateUserPrefs("currentGroup", group);
    updateUserPrefs("currentOrg", userState.userorganizations.find(org => org?.orgid === group.orgid));
    updateUserPrefs("groupDetail", group);
    updateUserPrefs("currentPage", "GroupDetail");
  };

  const addGroup = (orgid) => {
    updateUserPrefs("currentOrg", userState.userorganizations.find(org => org?.orgid === orgid));
    updateUserPrefs("currentPage", "NewGroup");
  };

  const createGroup = async () => {
    updateUserPrefs("currentPage", "NewGroup");
    // var groupdata = { orgid: currentOrg.orgid, groupname: "Whatever"}
    // console.log("Create group for org", currentOrg?.orgname , " data:" , groupdata);
    // const responseData = await SendRequest('creategroup', { newgroupdata:groupdata });
    // console.log("createGroup:", responseData);
};

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>

      <div className='CLR-BG-NavBar Shadow-2' style={{ width: "100%", textAlign: "center", paddingBlock:"10px"}}>
        <h4 style={{ marginTop: "0px", marginBottom: "0px" }}>{language.organizationDetailTitle}</h4>
        <h2 style={{ marginTop: "0px", marginBottom: "0px" }}>{userPrefs.currentOrg?.orgname || "Organization Not Found"}</h2>
      </div>

      <ButtonWithIcon text={language[`type_${userPrefs.currentOrg.orgtype}_createGroup`]} leftIcon={'group'} width={"90%"} style={{marginTop:"10px"}}
        onClick={() => createGroup()}/>

      <h4 style={{ marginTop: "20px", marginBottom: "0px" }}>{language[`type_${userPrefs.currentOrg.orgtype}_groups`]}</h4>
      {filteredGroups.map(group => (
        <ButtonWithIcon key={group.groupid} text={group.groupname} leftIcon={'group'} width={"90%"} style={{marginTop:"10px"}}
        onClick={() => handleGroupButtonClick(group)}/>
      ))}

    </div>
  );
};

const EnhancedPage = AnimatedPage(PageContent, pageID);
const components = {
  [pageID]: () => <EnhancedPage />
};
export default components[pageID];