import React, { useContext, useEffect } from 'react';
import AppContext from '../global/AppContext';
import OrganizationDetail from '../parts/organizationDetail';
import GroupDetail from '../parts/groupDetail';
import GroupPosts from './groupPosts';
import GroupUsers from './groupUsers';
import GroupChildren from './groupChildren';
import GroupBook from './groupBook';
import OrganizationsList from '../parts/organizationsList';
import Wall from './wall';
import GamesAndApps from './gamesAndApps';
import AbsentNotes from './absentNotes';
import NewAbsentNote from './newAbsentNote';
import Calendar from './calendar';
import NewGroup from './newGroup';
import InviteUser from './inviteUser';
import NewChildStep1 from './newChildStep1';
import NewChildStep2 from './newChildStep2';
import RemoveUser from './removeUser';
import NewOrganization from './newOrganization';
import OrganizationsManagement from './organizationsManagement';
import OrganizationUsers from './organizationUsers';
import OrganizationChildren from './organizationChildren';
import OrganizationGroups from './organizationGroups';
import HomeScreen from './homeScreen';
import EditProfile from './editProfile';
import PublicProfile from './publicProfile';
import MyOrganizations from './myOrganizations';
import MyGroups from './myGroups';
import NewPost from './newPost';
import newPostPoll from './newPostPoll';
import NewPostPoll from './newPostPoll';
import Presence from './presence';
import AbsentNotesParentPanel from './absentNotesParentPanel';

const AppContent = () => {
    const { userState, userPrefs, updateUserPrefs } = useContext(AppContext);

    // useEffect(() => {
    //     if (userPrefs.currentPage === undefined) {
    //         updateUserPrefs("currentPage", "Wall");
    //     }
    // }, [userPrefs, updateUserPrefs]);
            
            
    if (userState.loginstatus !== 1) {
        return null;
    }

    return (
            // <div className={`App-Content Fade FromLeft ${userPrefs.menuOpen === 0 || userPrefs.menuOpen === undefined ? 'Visible' : ''}`}>
            <div className={`App-Content Fade FromLeft Visible`}>
                <NewOrganization/>
                <OrganizationsList/>
                <OrganizationDetail/>
                <OrganizationGroups/>
                <OrganizationUsers/>
                <OrganizationChildren/>
                <InviteUser/>
                <NewChildStep1/>
                <NewChildStep2/>
                <RemoveUser/>
                <NewGroup/>
                <MyOrganizations/>
                <MyGroups/>
                <GroupDetail/>
                <GroupPosts/>
                <GroupUsers/>
                <GroupChildren/>
                <GroupBook/>
                <HomeScreen/>
                <EditProfile/>
                <PublicProfile/>
                <Wall/>
                <OrganizationsManagement/>
                <GamesAndApps/>
                <AbsentNotes/>
                <AbsentNotesParentPanel/>
                <NewAbsentNote/>
                <Calendar/>
                <NewPost/>
                <NewPostPoll/>
                <Presence/>
            </div>
    );
};

export default AppContent;