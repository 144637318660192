import { MaterialSymbol } from "react-material-symbols";
import './buttonWithIcon.css';

const ButtonWithIcon = ({ onClick, text, leftIcon, rightIcon, className, disabled, width, style, layout = 'normal' }) => {

    const combinedStyle = {
        width, 
        ...style // Spread the style prop to apply additional styles
    };

    return (
        <>
            {layout === 'normal' && (
                
                <button
                onClick={onClick}
                className={`ButtonWithIcon ${className ? className : ""}`}
                disabled={disabled}
                style={combinedStyle} // Apply the width prop here
                >
                <MaterialSymbol 
                    icon={leftIcon ? leftIcon : 'arrow_back_ios'}
                    //fill={1}
                    grade={25}  
                    weight={300} 
                    className="LeftIcon"
                    />
                {text}
                <MaterialSymbol 
                    icon={rightIcon ? rightIcon : 'arrow_forward_ios'}
                    //fill={1}
                    grade={25}  
                    weight={300} 
                    className="RightIcon"
                    />

                </button>
            )}

            {layout === 'menuItem' && (
                
                <button
                onClick={onClick}
                className={`ButtonWithIcon ${className ? className : ""}`}
                disabled={disabled}
                style={combinedStyle} // Apply the width prop here
                >
                <MaterialSymbol 
                    icon={leftIcon ? leftIcon : 'arrow_back_ios'}
                    //fill={1}
                    grade={25}  
                    weight={300} 
                    className="LeftIcon MenuItem"
                    />
                    <div>
                        {text}
                    </div>

                </button>
            )}
        </>
    );
};

export default ButtonWithIcon;