import React, { useContext, useState, useRef, useEffect } from 'react';
import AnimatedPage from './animatedPage';
import AppContext from '../global/AppContext';
import ButtonUniversal from '../elements/buttonUniversal';
import { MaterialSymbol } from 'react-material-symbols';
import './newPostPoll.css';

const pageID = "NewPostPoll";

const PageContent = () => {
    const { language, userPrefs, showOptions, hideOptions, updateUserPrefs } = useContext(AppContext);

    // Initialize local state for poll title and options from userPrefs
    const [pollTitle, setPollTitle] = useState(userPrefs.newPost?.poll?.pollTitle || '');
    const [pollOptions, setPollOptions] = useState(userPrefs.newPost?.poll?.pollOptions || []); // Default to empty array if no options

    const textareaRef = useRef(null);
    const newOptionFormRef = useRef(null);
    const titleFormRef = useRef(null);

    const addOption = () => {
        console.log("addOption");

        if (newOptionFormRef.current && newOptionFormRef.current.reportValidity()) {
            const newOption = textareaRef.current.value; // Access the value from the textarea
            hideOptions();
            console.log("Adding option: ", newOption);
            
            // Update local state for poll options
            setPollOptions(prevOptions => [...prevOptions, { text: newOption }]);
            
            // Clear the textarea after adding the option
            if (textareaRef.current) {
                textareaRef.current.value = ''; // Clear the textarea value
                textareaRef.current.style.height = 'auto'; // Reset height after clearing
            }
        }

    };

    const removeOption = (index) => {
        // Remove the option at the specified index from the local state
        setPollOptions(prevOptions => prevOptions.filter((_, i) => i !== index));
    };

    const save = () => {

        if (titleFormRef.current && titleFormRef.current.reportValidity()){

            // Create newPoll object and save to userPrefs
            const newPoll = {
                pollTitle, // Save the current poll title
                pollOptions // Save the current poll options
            };
            updateUserPrefs("currentPage", "NewPost");
            updateUserPrefs("newPost", { ...userPrefs.newPost, poll: newPoll }); // Save the poll object
            console.log("Saved poll:", newPoll); // Log the new poll for debugging
        }
    };

    const setNewOptionName = () => {
        showOptions({
            message: (
               <>
                    <form ref={newOptionFormRef}>
                        <textarea
                            className='RoundedInput'
                            required={true}
                            ref={textareaRef}
                            style={{width:"100%", marginTop:"clamp(0px,6vw,25px)", marginBottom:"clamp(0px,2vw,10px)", backgroundColor:"white", overflow:"hidden"}}
                            placeholder='Zadejte text nové možnosti ...'
                            onChange={handleTextareaChange}
                            />
                    </form>
                    <ButtonUniversal
                        textColor={"white"}
                        backgroundColor={"#40bbe1"}
                        text={language.add}
                        onClick={addOption} // Pass the add button functionality
                        style={{ marginLeft:"auto", width:"30%", justifyContent:"center"}}
                        />
               </> 
            ),
            showIcon: false
          });
    };

    const handleTextareaChange = (event) => {
        // Adjust the textarea height dynamically
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            if (textareaRef.current.scrollHeight > textareaRef.current.clientHeight) {
                textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
            }
        }
    };
    
    const changeTitle = (event) => {
        setPollTitle(event.target.value); // Update local state for the poll title
    };

    return (
        <>
            <div className='Page-Header'>
                <h3>{language.postPollSetupHeader}</h3>
            </div>

            <div className='PostPollTitle'>
                <form ref={titleFormRef}>
                    <input
                        required={true}
                        value={pollTitle}
                        placeholder={language.pollTitle} // Set the input value
                        onChange={changeTitle} // Update the input value
                    />
                </form>
            </div>

            <div style={{fontWeight: "500" }}>
                {pollOptions.length > 0 && ( // Use local state for rendering options
                    <div style={{ marginBlock: "10px" }}>
                        {pollOptions.map((option, index) => (
                            <div key={index} style={{display:"flex", alignItems:"center", color:"#00d300"}}>
                                <div style={{width:"clamp(0px, 10vw, 40px)", textAlign:"center"}}>
                                    {index}
                                </div>
                                <ButtonUniversal
                                    type='underline'
                                    text={option.text}
                                    style={{ marginBlock: "5px", width:"80%", flexGrow:"1", textAlign:"left" }} // Add margin for spacing
                                />
                                <div style={{width:"clamp(0px, 10vw, 40px)", textAlign:"center"}}>
                                    <button
                                        onClick={() => removeOption(index)} // Remove option functionality
                                        style={{
                                            cursor: "pointer",
                                            backgroundColor: "red",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: "clamp(0px,6vw,25px)",
                                            height: "clamp(0px,6vw,25px)",
                                            borderRadius: "100%",
                                        }}
                                    >
                                        <MaterialSymbol
                                            icon={'close'}
                                            size={'clamp(0px,5vw,20px)'}
                                            grade={25}
                                            weight={300}
                                            style={{ color: "white" }}
                                        />
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                <ButtonUniversal
                    leftIcon={<MaterialSymbol icon={'add'} color='#00d300' />}
                    text={language.addPollOption}
                    style={{ width: "100%", marginBottom: "clamp(0px, 5vw, 20px)", }}
                    onClick={setNewOptionName} // Pass the add button functionality
                />
                <ButtonUniversal
                    textColor={"white"}
                    backgroundColor={"orange"}
                    text={language.save}
                    onClick={save} // Save functionality
                    style={{ margin:"auto", width:"50%", justifyContent:"center"}}
                />
            </div>
        </>
    );
};

const EnhancedPage = AnimatedPage(PageContent, pageID);
const components = {
    [pageID]: () => <EnhancedPage />
};
export default components[pageID];