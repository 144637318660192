import React, { useContext, useState } from 'react';
import AnimatedPage from './animatedPage';
import AppContext from '../global/AppContext';
import { SendRequest } from '../../webRequests';
import ItemTextEditable from '../elements/itemTextEditable';
import { useUserPrefs } from '../global/userPrefs';
import ButtonWithIcon from '../elements/buttonWithIcon';

const pageID = "NewOrganization";

const PageContent = () => {
    const { language, userPrefs, updateUserPrefs, getUserState, navBack } = useContext(AppContext);
    const [newOrgData, setNewOrgData] = useState('');

    const handleInputChange = (key, value) => {
        setNewOrgData(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        await updateUserPrefs('displayInfoLoading', 1);

        console.log("NewOrgData" , newOrgData);
        const responseData = await SendRequest('createorganization', { neworgdata:newOrgData });
        console.log("createorganization:", responseData);

        console.log("Hiding loading ", userPrefs.displayInfoLoading);
        await updateUserPrefs('displayInfoLoading', 0);
        console.log("Hidden loading ", userPrefs.displayInfoLoading);
        updateUserPrefs('infoMessage', language.orgCreated);
        updateUserPrefs('displayInfoMessage', 1);

        getUserState();
        navBack();
    };

    const back = async (event) => {
        event.preventDefault();
        navBack('HomeScreen');
    };

    return (
        <form onSubmit={handleSubmit}>
            <div style={{paddingBottom:'30px'}}></div>

            <div className='Group-Label'>{language.newOrg}</div>
            
            <ItemTextEditable 
                label={language.orgName} 
                //value={''}
                onFinishEditing={(value) => handleInputChange('orgName', value)}
                required={true}
            />

            <ItemTextEditable 
                label={language.orgIco} 
                //value={''}
                onFinishEditing={(value) => handleInputChange('orgIco', value)}
                required={true}
                type={'number'}
            />

            <div className='Group-Label'>{language.orgOwner}</div>

            <ItemTextEditable 
                label={language.firstName} 
                //value={''}
                onFinishEditing={(value) => handleInputChange('orgOwnerFirstName', value)}
                required={true}
            />

            <ItemTextEditable 
                label={language.lastName} 
                //value={''}
                onFinishEditing={(value) => handleInputChange('orgOwnerLastName', value)}
                required={true}
            />

            <ItemTextEditable 
                label='E-mail'
                //value={''}
                onFinishEditing={(value) => handleInputChange('orgOwnerEmail', value)}
                required={true}
                type={'email'}
            />

            <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                <ButtonWithIcon text={language.create} leftIcon={'domain_add'} width={"90%"} style={{marginTop:"10px"}}/>
                <ButtonWithIcon text={language.cancel} leftIcon={'cancel'} rightIcon={'arrow_back_ios'} width={"90%"} style={{marginTop:"10px"}}
                    onClick={(event) => back(event)}/>
            </div>
        </form>
    );
};

const EnhancedPage = AnimatedPage(PageContent, pageID);
const components = {
    [pageID]: () => <EnhancedPage />
  };
export default components[pageID];