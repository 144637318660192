import React, {useEffect, useState} from 'react';
import './App.css';
import Webpages from './components/webpages';
import ConsoleLogger from './components/global/consoleLogger';
import ErrorBoundary from './components/global/errorBoundary';
import NotificationsPermissionCheck from './components/parts/notificationsPermissionCheck';
import FontLoader from './components/global/fontLoader';
import ViewportManager from './components/global/viewportManager';
import { AppProvider } from './components/global/AppContext';
import { useLanguage } from './components/global/language';
import { useUserState } from './components/global/userState';
import { useUserPrefs } from './components/global/userPrefs';
import { useConfirmation } from './components/global/confirmation';
import { useOptions } from './components/global/options';
import 'react-material-symbols/outlined';
import useAppVisibility from './components/global/useAppVisibility';

const App = () => {
  const { language, changeLanguage } = useLanguage();
  const { userState, verifyLogIn, logIn, logOut, getUserState, updateUserState } = useUserState();
  const { userPrefs, themes, updateScrollY, resetUserPrefs, updateUserPrefs, enterApp, navBack } = useUserPrefs();
  const { confirmation, showConfirmation, hideConfirmation} = useConfirmation();
  const { options, showOptions, hideOptions} = useOptions();
  const [ themeLoaded, setThemeLoaded] = useState(false);

  const contextValue = {
    language,
    changeLanguage,
    userState,
    verifyLogIn,
    logIn,
    logOut, 
    getUserState,
    updateUserState,
    userPrefs,
    themes,
    updateScrollY,
    resetUserPrefs,
    updateUserPrefs,
    enterApp,
    navBack,
    confirmation,
    showConfirmation,
    hideConfirmation,
    options,
    showOptions,
    hideOptions
  };

  useAppVisibility(getUserState);

  useEffect(() => {
    const loadTheme = () => {
      const linkId = 'dynamic-theme';
      let link = document.getElementById(linkId);

      if (!link) {
        link = document.createElement('link');
        link.id = linkId;
        link.rel = 'stylesheet';
        document.head.appendChild(link);
      }

      const selectedTheme = themes[userPrefs.theme] || themes.default;
      link.href = selectedTheme.path;

      // Listen for when the CSS file is fully loaded
      link.onload = () => {
        setThemeLoaded(true);  // Mark the theme as loaded
      };

      link.onerror = () => {
        console.error("Theme failed to load");
        setThemeLoaded(true);  // Allow the app to continue even if the theme fails
      };
    };

    loadTheme();
  }, [userPrefs.theme, themes]);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', (event) => {
        console.log('Received message from service worker:', event.data);

        if (event.data?.type === 'PUSH_EVENT') {
          // Handle the push event data in your application
          handlePushEvent(event.data.payload);
        }
      });
    }
  }, []);

  const handlePushEvent = (data) => {
    // Your custom logic for handling push event data in the application
    console.log('Push event data:', data);
    if (data.reschedulecalendar){
      getUserState();
    }
    // For example, you could update the state or trigger some functionality in the app
  };

  const isFontLoaded = FontLoader('Material Symbols Outlined');
  const isAppReady = themeLoaded && isFontLoaded;

  return (
    <AppProvider value={contextValue}>
      <ErrorBoundary>
        
        {!isAppReady ? (
          <div style={{position:"fixed", top:"50%", left:"50%", zIndex:"100", transform:"translate(-50%,-50%)"}}>
            <p>{language.loading}...</p>
            <div className='loader' style={{marginInline: 'auto', marginBlock: '10px', width:'50px', background:'#07a8ca', marginBottom:'30px', animationDuration:'1s'}}></div>
          </div>
        ) : (
          <>
            <div className="App-Background CLR-BG-1"></div>
            {/* <ConsoleLogger/> */}
            {/* <DisableZoom /> */}
            <ViewportManager/>
            <NotificationsPermissionCheck/>
            <Webpages/>
          </>
        )}

      </ErrorBoundary>
    </AppProvider>
  );
};

export default App;