import React from 'react';
import { appPath } from '../global/utils';
import { MaterialSymbol } from 'react-material-symbols';

const ImageItem = ({ name, postId, index, greyedOut, imagesCount, isLink = false, aspect, onClick, onClose = undefined }) => {

  const filePath = appPath() + `/postattachments/${postId}/${name}`;

  const handleClick = () => {
    if (isLink) {
      console.log(`Clicked on item at index: ${index}`);
      window.open(filePath, '_blank');
    }
  };

  // Calculate the aspect ratio if defined
  let containerStyle = {
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  if (aspect) {
    const [widthRatio, heightRatio] = aspect.split(':').map(Number);
    const paddingPercentage = (heightRatio / widthRatio) * 100;
    containerStyle.paddingBottom = `${paddingPercentage}%`; // Apply padding to maintain aspect ratio
  } else {
    containerStyle.height = 'auto'; // Allow the container to adjust based on image's original height
  }

  const imageBorderStyle ={
    height:'calc(100% - (clamp(0px, 1vw, 20px)))',
    width:'calc(100% - (clamp(0px, 1vw, 20px)))',
    borderRadius:'clamp(0px, 5vw, 20px)'
  }

  const imageStyle = aspect
    ? {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        height: '100%',
        objectFit: 'cover', // Ensure the image covers the container
        cursor: isLink ? 'pointer' : 'default',
        ...(greyedOut && { opacity: "0.4" }),
      }
    : {
        width: '100%', // Use full width
        height: 'auto', // Maintain original aspect ratio
        cursor: isLink ? 'pointer' : 'default',
      };

  return (
    <div className="image-item" style={containerStyle}>
        {greyedOut && (
            <div style={{position:"absolute", top:"0",zIndex:"1", fontSize:"clamp(0px,10vw,500px)", fontWeight:"500", color:"#00000073", height:"100%", display:"flex", justifyContent:"center", alignItems:"center", pointerEvents:"none"}}>
                <MaterialSymbol icon="image" weight={100}/>
                <div>+{imagesCount-3}</div>
            </div>
        )}
      <img
        src={filePath}
        alt={name}
        style={{ ...imageStyle, ...imageBorderStyle }} 
        onClick={onClick ? onClick : handleClick}
      />

        {onClose != undefined && (

          <button
          onClick={() => onClose()}
          style={{
            cursor: "pointer",
            backgroundColor: "red",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "clamp(0px,6vw,25px)",
            height: "clamp(0px,6vw,25px)",
            borderRadius: "100%",
            position: "absolute",
            top: "0px",
            right: "0px"
          }}
          >
          <MaterialSymbol
            icon={'close'}
            size={'clamp(0px,5vw,20px)'}
            grade={25}
            weight={300}
            style={{ color: "white" }}
            />
          </button>
        )}
    </div>

    
  );
};

export default ImageItem;