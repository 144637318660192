import React, { useContext, useState, useEffect } from 'react';
import AppContext from '../global/AppContext';
import AdminTools from './adminTools';
import UserAvatar from '../elements/userAvatar';
import PermitNotificationButton from '../elements/permitNotificationButton';
import ButtonWithIcon from '../elements/buttonWithIcon';
// import Dropdown from '../elements/dropDown';

const MainMenu = () => {
    const { language, userPrefs, userState, updateUserPrefs, logOut, themes } = useContext(AppContext);
    const [isVisible, setIsVisible] = useState(true);
    const [shouldRender, setShouldRender] = useState(false);
    const pageID = "Main-Menu";
    
    
    useEffect(() => {
        if (userPrefs.currentMenuPage === undefined){
            console.log("Undef update.........");
            //updateUserPrefs("currentMenuPage", pageID);
        }

        let isVisibleTimeout;
        let shouldRenderTimeout;
        let renderConditions = userPrefs.currentMenuPage === pageID;

        if (userPrefs && userState  && userPrefs.currentMenuPage !== undefined) {
            if (renderConditions) {
                setShouldRender(true);
                 isVisibleTimeout = setTimeout(() => {
                    setIsVisible(true);
                }, 50);
            } else {
                setIsVisible(false);
                shouldRenderTimeout = setTimeout(() => {
                    setShouldRender(false);
                }, 500); 
            }
        }
        return () => {
            clearTimeout(isVisibleTimeout);
            clearTimeout(shouldRenderTimeout);
        };
    }, [userPrefs, userState, updateUserPrefs]);

    const wantLogOut = () => {
        updateUserPrefs('wantLogIn', 0);
        updateUserPrefs('menuOpen', 0);
        logOut();
    };

    const editProfile = (user) => {
        updateUserPrefs("editUser", user);
        updateUserPrefs("currentPage", "EditProfile");
        updateUserPrefs('menuOpen', 0);
    };

    const editOrganizaion = (org) => {
        updateUserPrefs("devOrgDetail", 0);
        updateUserPrefs("organizationDetail", org);
        updateUserPrefs("currentOrg", org);
        updateUserPrefs('menuOpen', 0);
        updateUserPrefs("currentPage", "OrganizationDetail");
    };

    const newOrg = () => {
        console.log("Creating new org");
        updateUserPrefs("currentMenuPage", "NewOrganizationMenu");
    };

    // const handleThemeChange = (value) => {
    //     updateUserPrefs('theme', value);
    // };
    const handleThemeChange = (event) => {
        updateUserPrefs('theme', event.target.value);
    };

    // const themeOptions = [
    //     { label: language.themeDefault, value: 'default' },
    //     { label: language.themeModern, value: 'modern' },
    // ];

    return ( shouldRender ?
        <div className={`${pageID} Page-Content CLR-BG-1`} style={{overflowY:'auto', height:'calc(-50px + 100%)'}}>
            <div className={`CLR-TXT-1 Fade FromBottom ${isVisible ? 'Visible' : ''}`} >

                <h2 style={{ marginTop: "70px", marginBottom: "0px"  }}>{language.mainMenuTitle}</h2>
                
                <div className={`ItemsList`}>
                        <ButtonWithIcon text={language.myProfile} leftIcon={'person'} width={"90%"} style={{marginTop:"10px"}}
                            onClick={() => editProfile(userState.user)}/>

                        <ButtonWithIcon text={language.logOut} leftIcon={'logout'} width={"90%"} style={{marginTop:"10px"}}
                            onClick={() => wantLogOut()}/>

                        <PermitNotificationButton
                            onSubscribed={(subscription) => console.log('User subscribed:', subscription)}
                        />
                </div>



                {/* {userState.userorganizations && userState.userorganizations.length > 0 && (
                    <>
                        <h2 style={{ marginTop: "20px", marginBottom: "0px"  }}>{language.organizationsTitle}</h2>
                        
                        <div className={`ItemsList`}>
                        {userState.userorganizations && userState.userorganizations.map((organization, index) => (
                            <div key={organization.orgid} style={{paddingInline:'10px'}}>
                            <button type="button" className="ListItem CLR-BTN-1" onClick={() => editOrganizaion(organization)}>
                            {organization.orgname}
                            </button>
                            </div>
                            ))}
                            </div>
                            </>
                            )} */}

                {userState.childusers && userState.childusers.length > 0 && (
                    <>
                        <h2 style={{ marginTop: "20px", marginBottom: "10px"}}>{language.myKids}</h2>
                        
                        <div className={`MyKidsList`} style={{display:"flex", justifyContent:"center"}}>
                            {userState.childusers && userState.childusers.map((child, index) => (
                                <div key={child.userid} style={{paddingInline:'10px'}}>
                                    <UserAvatar user={child} onClick={() => editProfile(child)} displayName={true} />
                                </div>
                            ))}
                        </div>
                    </>
                )}

                {/* {userState.user !== undefined && userState.user.usertype >= 98 && (
                    <>
                        <h2 style={{ marginTop: "20px", marginBottom: "0px" }}>{language.administration}</h2>
                        <div className={`ItemsList`}>
                                <button type="button" className="ListItem CLR-BTN-1" onClick={() => newOrg()}>
                                {language.newOrg}
                                </button>
                        </div>
                    </>
                )} */}

                {userState.user !== undefined && userState.user.usertype === 99 && (
                    <>
                        <h2 style={{ marginTop: "20px", marginBottom: "0px" }}>{language.adminTools}</h2>

                        <h5 style={{ marginTop: "0px", marginBottom: "0px" }}>{language.theme}</h5>
                        <div>
                                <select id="dropdown-select" className={`CLR-BTN-1`} value={userPrefs.theme} onChange={handleThemeChange}>
                                    {Object.keys(themes).map(key => (
                                        <option key={key} value={key}>
                                            {language[`theme${key?.charAt(0).toUpperCase() + key.slice(1)}`]}
                                        </option>
                                    ))}
                                </select>
                                {/* <Dropdown
                                    options={themeOptions}
                                    selected={themeOptions.find((opt) => opt.value === userPrefs.theme)?.label || 'Select Theme'}
                                    onChange={handleThemeChange}
                                    /> */}
                        </div>
                        <AdminTools/>
                    </>
                )}

            </div>
        </div>
        :
        null
    );
};

export default MainMenu;