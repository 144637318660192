export const formatPostDate = (dateInput, mode = 0) => {
    // mode : 0=full date , 1=no hours, 2=only hours
    const date = new Date(dateInput); // Ensure it's a Date object
    if (isNaN(date)) {
        return "-";
        throw new Error('Invalid date input');
    }

    const formattedDate = new Intl.DateTimeFormat('cs-CZ', {
        day: 'numeric',
        month: 'long', // This ensures the correct inflection is used
        year: 'numeric',
    }).format(date);

    if (mode === 1) {
        // If mode 1, return the date without the time part
        return formattedDate;
    } else if (mode === 2) {
        // If mode 2, return only the time part (hours:minutes)
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    }

    // Default (mode 0): full date with hours and minutes
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${formattedDate} ${hours}:${minutes}`;
};

export const appPath = () => {
    return window.location.pathname.endsWith('/') ? window.location.pathname.slice(0, -1) : window.location.pathname;
};

export const getRandomGradient = (id) => {
    const colors = ['#bbe0e4', '#a1eaff', '#a2b2ff', '#91e8ff', '#dba6ff', '#ffbcf2'];
    const color1 = colors[id % colors.length];
    const color2 = colors[(id + 1) % colors.length];
    return `linear-gradient(135deg, ${color1}, ${color2})`;
};