import { useState, useEffect, useMemo } from 'react';
import { saveDataToIDB, loadDataFromIDB } from '../../utils/IDB';
import { appPath } from './utils';

export const useConfirmation = () => {

    const [confirmation, setConfirmation] = useState({
        isOpen: false,
        message: '',
        onConfirm: null,
        onCancel: null,
    });
    
    const showConfirmation = (message, onConfirm, onCancel) => {
    setConfirmation({
        isOpen: true,
        message,
        onConfirm,
        onCancel,
    });
    };

    const hideConfirmation = () => {
    setConfirmation({
        ...confirmation,
        isOpen: false,
    });
    };

    return { confirmation, showConfirmation, hideConfirmation };
};