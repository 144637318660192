import React, { useContext, useState } from 'react';
import AnimatedPage from './animatedPage';
import AppContext from '../global/AppContext';
import { SendRequest } from '../../webRequests';
import ItemTextEditable from '../elements/itemTextEditable';
import { useUserPrefs } from '../global/userPrefs';

const pageID = "NewOrganizationMenu";

const PageContent = () => {
    const { language, userPrefs, updateUserPrefs, getUserState, navBack } = useContext(AppContext);
    const [newOrgData, setNewOrgData] = useState('');

    const handleInputChange = (key, value) => {
        setNewOrgData(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        await updateUserPrefs('displayInfoLoading', 1);

        console.log("NewOrgData" , newOrgData);
        const responseData = await SendRequest('createorganization', { neworgdata:newOrgData });
        console.log("createorganization:", responseData);

        console.log("Hiding loading ", userPrefs.displayInfoLoading);
        await updateUserPrefs('displayInfoLoading', 0);
        console.log("Hidden loading ", userPrefs.displayInfoLoading);
        updateUserPrefs('infoMessage', language.orgCreated);
        updateUserPrefs('displayInfoMessage', 1);

        getUserState();
        navBack();
    };

    const back = async () => {
        updateUserPrefs('currentMenuPage', 'Main-Menu');
    };

    return (
        <form onSubmit={handleSubmit}>
            <div style={{paddingBottom:'30px'}}></div>

            <div className='Group-Label'>{language.newOrg}</div>
            
            <ItemTextEditable 
                label={language.orgName} 
                //value={''}
                onFinishEditing={(value) => handleInputChange('orgName', value)}
                required={true}
            />

            <ItemTextEditable 
                label={language.orgIco} 
                //value={''}
                onFinishEditing={(value) => handleInputChange('orgIco', value)}
                required={true}
                type={'number'}
            />

            <div className='Group-Label'>{language.orgOwner}</div>

            <ItemTextEditable 
                label={language.firstName} 
                //value={''}
                onFinishEditing={(value) => handleInputChange('orgOwnerFirstName', value)}
                required={true}
            />

            <ItemTextEditable 
                label={language.lastName} 
                //value={''}
                onFinishEditing={(value) => handleInputChange('orgOwnerLastName', value)}
                required={true}
            />

            <ItemTextEditable 
                label='E-mail'
                //value={''}
                onFinishEditing={(value) => handleInputChange('orgOwnerEmail', value)}
                required={true}
                type={'email'}
            />

            <button type="submit" className="ListItem CLR-BTN-1">
                {language.create}
            </button>
            <button type="button" className="ListItem CLR-BTN-1" onClick={() => back()}>
                {language.back}
            </button>
        </form>
    );
};

const EnhancedPage = AnimatedPage(PageContent, pageID, 'currentMenuPage');
const components = {
    [pageID]: () => <EnhancedPage />
  };
export default components[pageID];