import React, { useContext, useEffect, useState } from 'react';
import AnimatedPage from './animatedPage';
import AppContext from '../global/AppContext';
import { SendRequest } from '../../webRequests';

const pageID = "OrganizationsManagement";

const PageContent = () => {
  const { language, updateUserPrefs } = useContext(AppContext);
  const [organizations, setOrganizations] = useState([]);
  
    useEffect(() => {
        const getOrganizations = async () => {
        try {
            const response = await SendRequest('getorganizations');
            console.log("getorganizations:", response);
            setOrganizations(response.organizations);
        } catch (error) {
            console.error('getorganizations Error:', error);
        }
        };

        getOrganizations();
    }, []);
  
    const editOrganization = (org) => {
        console.log('Edit organization:', org);
        updateUserPrefs("devOrgDetail", 1);
        updateUserPrefs("currentOrg", org);
        updateUserPrefs("organizationDetail", org);
        updateUserPrefs("currentPage", "OrganizationDetail");
    };

  return (
    <>
      {organizations.length > 0 && (
        <>
          <h2 style={{ marginTop: "20px", marginBottom: "0px", textAlign:"center" }}>{language.organizationsTitle}</h2>
          <div className="ItemsList">
            {organizations.map((organization) => (
              <div key={organization.orgid} style={{ paddingInline: '10px' }}>
                <button type="button" className="ListItem CLR-BTN-1" onClick={() => editOrganization(organization)}>
                  {organization.orgname}
                </button>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

const EnhancedPage = AnimatedPage(PageContent, pageID);
const components = {
  [pageID]: () => <EnhancedPage />
};

export default components[pageID];