import React, { useEffect, useState, useContext } from 'react';
import AppContext from '../global/AppContext';
import { SendRequest } from '../../webRequests';

const NotificationsPermissionCheck = ({ onSubscribed }) => {
  const { language, userPrefs, showOptions, updateUserPrefs} = useContext(AppContext);
  const [notificationsPermissionStatus, setNotificationsPermissionStatus] = useState('');
  const [notificationsSubscriptionStatus, setNotificationsSubscriptionStatus] = useState('inactive');
  const pushsubscriber = process.env.REACT_APP_PUSH_SUBSCRIBER;

  useEffect(() => {
    if (userPrefs.pwaMode) {
      // Check Notification permission status
      const permissionStatus = Notification.permission;
      console.log("Notifications check:", permissionStatus);
  
      setNotificationsPermissionStatus(permissionStatus);
  
      switch (permissionStatus) {
        case 'granted':
          // Check if the user is subscribed to push notifications
          navigator.serviceWorker.ready.then((registration) => {
            registration.pushManager.getSubscription().then((subscription) => {
              if (subscription) {
                setNotificationsSubscriptionStatus('subscribed');
              } else {
                setNotificationsSubscriptionStatus('none');
              }
            });
          });
          break;
  
        case 'default':
          console.log("isNotificationsPermissionStatus:", permissionStatus);
          askToEnableNotifications();
          break;
  
        case 'denied':
          // Add any additional handling for denied permissions here
          console.warn("Notifications are denied by the user.");
          displayDeniedInfo();
          break;
  
        default:
          console.error("Unexpected notification permission status:", permissionStatus);
          break;
      }
    }
  }, [userPrefs.pwaMode]);

  const urlBase64ToUint8Array = (base64String) => {
    const base64 = base64String.replace(/-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    return Uint8Array.from([...rawData].map((char) => char.charCodeAt(0)));
  };

  const askToEnableNotifications = async () => {
    showOptions({
      message: (
        <>
          {language.notificationsQuestion}<br/>
        </>
      ),
      buttons: [
        {
          text: language.no,
          onClick: () => console.log('Action canceled'),
          style: { marginInline: '5px' }
        },
        {
          text: language.dontAskAgain,
          onClick: () => console.log('Action canceled'),
          style: { marginInline: '5px' }
        },
        {
          text: language.yes,
          onClick: async () => {
            console.log('Action confirmed');
            Notification.requestPermission().then(permission => {
              if (permission === 'granted') {
                subscribeUser();
              }
            });
          },
          style: { marginInline: '5px' }
        }
      ]
    });
  };

  const displayDeniedInfo = async () => {
    showOptions({
      message: (
        <>
          {language.notificationsDenied}<br/>
        </>
      ),
      buttons: [
        {
          text: language.dontShowAgain,
          onClick: () => console.log('Action canceled'),
          style: { marginInline: '5px' }
        },
        {
          text: language.ok,
          onClick: () => console.log('Action canceled'),
          style: { marginInline: '5px' }
        }
      ]
    });
  }

  const subscribeUser = async () => {
    console.log("Permission granted");

    const registration = await navigator.serviceWorker.ready;

    console.log("navigator.serviceWorker.ready");

    try {
        const subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array('BGKOyzPWA8MmhotvxF-f2S0uW67oZ3c22wbWTLKtdWFHZBczqVZp1spHJqLTjdXppTppmz7iAmHGEMOBeI4vDNw'),
        });

        console.log("Sending push notification subscription");

        const inputData = {
          subscription : subscription
      };

      const responseData = await SendRequest('savesubscription', { inputdata: inputData });
      console.log("Server response:", responseData);

      if (responseData.result.success) {
        setNotificationsSubscriptionStatus('active');
          if (onSubscribed) {
              onSubscribed(subscription);
          }
      } else {
          console.error('Subscription failed:', responseData.result);
      }
    } catch (error) {
        console.error('Subscription error:', error);
    }
  };

  const handleButtonClick = () => {
    if (Notification.permission === 'granted') {
      subscribeUser();
    } else {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          subscribeUser();
        }
      });
    }
  };
};

export default NotificationsPermissionCheck;