import { useEffect } from 'react';

const useAppVisibility = (getUserState) => {
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                console.log('App is back in focus!');
                handleAppResume();
            } else {
                console.log('App went into the background.');
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    const handleAppResume = () => {
        if (getUserState) {
            getUserState();
            console.log("Running code on app resume");
        } else {
            console.warn("getUserState is not available");
        }
    };
};

export default useAppVisibility;