import React from "react";

const AppContext = React.createContext();

export default AppContext;

export const AppProvider = ({ children, value }) => {
    return (
      <AppContext.Provider value={value}>
        {children}
      </AppContext.Provider>
    );
  };