import React, { useContext, useRef, useState, useEffect } from 'react';
import AnimatedPage from './animatedPage';
import AppContext from '../global/AppContext';
import { SendRequest } from '../../webRequests';
import ItemTextEditable from '../elements/itemTextEditable';
import UsersList from '../elements/usersList';
import ButtonWithIcon from '../elements/buttonWithIcon';

const pageID = "InviteUser";

const PageContent = () => {
    const { language, userPrefs, updateUserPrefs, userState } = useContext(AppContext);
    const orgId = userPrefs.currentOrg.orgid;
    const groupId = userPrefs?.groupDetail?.groupid;
    const inviteFirstNameRef = useRef();
    const inviteLastNameRef = useRef();
    const inviteEmailRef = useRef();
    const [ orgUsers, setOrgUsers ] = useState('');
    const [ userPending, setUsersPending ] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (userPrefs.inviteUserTo === "Organization"){
            const inviteData = {
                orgid: orgId,
                invitedFirstName: inviteFirstNameRef.current.value,
                invitedLastName: inviteLastNameRef.current.value,
                invitedEmail: inviteEmailRef.current.value,
            };

            updateUserPrefs('displayInfoLoading', 1);

            const responseData = await SendRequest('inviteusertoorganization', { invitedata: inviteData });
            console.log(responseData);

            updateUserPrefs('displayInfoLoading', 0);

            if(responseData.message == "access denied"){
                updateUserPrefs('infoMessage', language.accessDenied);
                updateUserPrefs('displayInfoMessage', 1);
            }
            else{
                updateUserPrefs('infoMessage', language.done);
                updateUserPrefs('displayInfoMessage', 1);


                const requestData = { orgid: orgId };
                const responseData = await SendRequest('getorganizationusers', { requestdata: requestData });
                console.log("getorganizationusers:", responseData);
        
                const usersData = {
                    orgid: orgId,
                    orgusers: responseData.orgusers.sort((a, b) => b.role - a.role),
                };
                updateUserPrefs('orgUsers', usersData);

            }
        }


        goBack();
    };

    const handleGroupInvite = async (userid) => {

        console.log("Group invite for userid:", userid, " orgid:" , orgId, " groupid:" , groupId);

        const inviteData = {
            orgid: orgId,
            groupid: groupId,
            invitedUserId: userid,
            grouprole: userPrefs.inviteUserTo === "GroupChild" ? 0 : 1
        };

        const responseData = await SendRequest('inviteusertogroup', { invitedata: inviteData });
        console.log(responseData);

        goBack();
    };

    const goBack = (event) => {
        if (event) {
            event.preventDefault(); // Prevent the default action
        }

        if (userPrefs.inviteUserTo === "Organization"){
            updateUserPrefs("currentPage", "OrganizationUsers");
        }
        else if (userPrefs.inviteUserTo === "OrganizationChild"){
            updateUserPrefs("currentPage", "OrganizationChildren");
        }
        else if (userPrefs.inviteUserTo === "Group"){
            updateUserPrefs("currentPage", "GroupUsers");
        }
        else if (userPrefs.inviteUserTo === "GroupChild"){
            updateUserPrefs("currentPage", "GroupChildren");
        }
    };

    const newChild = () => {
        updateUserPrefs("currentPage", "NewChildStep1");
    };

    const currentOrg = userState.userorganizations.find(org => org.orgid === orgId);
    const currentGroup = userState.usergroups.find(group => group.groupid === groupId);
    
    const getOrgUsers = async () => {

        const requestData = { orgid: orgId };
        const responseData = await SendRequest('getorganizationusers', { requestdata: requestData });
        console.log("getorganizationusers:", responseData);

        const usersData = {
            orgid: orgId,
            orgusers: responseData.orgusers.sort((a, b) => b.role - a.role),
        };

        await setOrgUsers(usersData);
        updateUserPrefs('orgUsers', usersData);
        // console.log("orgUsers: ", orgUsers);
        setUsersPending(false);
    };

    useEffect(() => {
        if (!currentOrg) {
            console.log("Current org not set for OrganizationDetail");
            updateUserPrefs("currentPage", "Wall");
        }
        else if (!userPending && orgId != orgUsers.orgid) {
            console.log("Getting orgusers");
            setUsersPending(true);
            getOrgUsers();
        }
        else{
            console.log("No conditions met orgusers");
        }
    }, []);
    
    const groupUsers = userPrefs.groupUsers || [];
    
    const availableUsers = userPrefs?.orgUsers?.orgusers?.filter(orgUser =>
        !groupUsers.some(groupUser => groupUser.userid === orgUser.userid)
    );

    const usersListTitle = 
    userPrefs.removeUserFrom === "Group" ? language[`type_${currentGroup.grouptype}_usersInOrg`]
    : userPrefs.removeUserFrom === "GroupChild" ? language[`type_${currentGroup.grouptype}_childUsersInOrg`]
    : userPrefs.removeUserFrom === "Organization" ? language[`type_${currentOrg.orgtype}_usersInOrg`]
    : userPrefs.removeUserFrom === "OrganizationChildren" ? language[`type_${currentOrg.orgtype}_childUsersInOrg`]
    : "DefaultTitle";
  
    return (
        <>
            <form onSubmit={handleSubmit}>
                <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>

                    {/* Group invite */}
                    {(userPrefs.inviteUserTo === "Group" || userPrefs.inviteUserTo === "GroupChild")  && (
                        <>
                            <h3 style={{marginBottom:"0px"}}>
                                {userPrefs.inviteUserTo === "Group" ? language[`type_${currentGroup.grouptype}_addUserGroup`] : language[`type_${currentGroup.grouptype}_addUserGroupChild`]}
                            </h3>
                            <h2 style={{marginTop:"0px", marginBottom:"0px"}}>
                                {currentOrg?.orgname || "Organization Not Found"}
                            </h2>
                            <h2 style={{marginTop:"0px"}}>
                                {userPrefs?.currentGroup?.groupname || "Organization Not Found"}
                            </h2>

                            {/* Users */}
                            {availableUsers?.length > 0 ? (
                                // Apply filter and check if the filtered array is empty
                                (() => {
                                    const filteredUsers = availableUsers.filter(user =>
                                        (userPrefs.inviteUserTo === "Group" && user.role > 0) || 
                                        (userPrefs.inviteUserTo === "GroupChild" && user.role === 0)
                                    );

                                    return filteredUsers.length > 0 ? (
                                        <>
                                            {filteredUsers && (
                                                <UsersList 
                                                title={usersListTitle}
                                                users={filteredUsers}
                                                opensPublicProfiles={false}
                                                onClick={(user) => handleGroupInvite(user.userid)}
                                                />
                                            )}
                                        </>
                                    ) : (
                                        <p style={{ textAlign: "center", marginTop: "0px" }}>
                                            {language.nooneToadd}
                                        </p>
                                    );
                                })()
                            ) : (
                                <p style={{ textAlign: "center", marginTop: "0px" }}>
                                    {language.nooneToadd}
                                </p>
                            )}
                        </>
                    )}
                    
                    {/* Organization invite */}
                    {userPrefs.inviteUserTo === "Organization" && (
                        <>
                            <h3 style={{marginBottom:"0px"}}>{language[`type_${currentOrg.orgtype}_addUserOrg`]}</h3>
                            <h2 style={{marginTop:"0px"}}>{currentOrg?.orgname || "Organization Not Found"}</h2>

                            <div style={{width:"100%"}}>
                                <ItemTextEditable 
                                    label={language.firstName} 
                                    ref={inviteFirstNameRef}
                                    required={true}
                                    />

                                <ItemTextEditable 
                                    label={language.lastName} 
                                    ref={inviteLastNameRef}
                                    required={true}
                                    />

                                <ItemTextEditable 
                                    label='E-mail'
                                    ref={inviteEmailRef}
                                    required={true}
                                    type={'email'}
                                    />
                            </div>

                            <ButtonWithIcon text={language[`type_${currentOrg.orgtype}_addUser`]} leftIcon={'person_add'} width={"90%"} style={{marginTop:"10px"}}/>
                        </>
                    )}

                    {/* {userPrefs.inviteUserTo === "GroupChild" && (
                        <button type="button" className="ListItem CLR-BTN-1" onClick={newChild}>
                            {language.newChild}
                        </button>
                    )} */}

                    <ButtonWithIcon text={language.cancel} leftIcon={'cancel'} rightIcon={'arrow_back_ios'} width={"90%"} style={{marginTop:"10px"}}
                        onClick={goBack}/>
                </div>
            </form>
        </>
    );
};

const EnhancedPage = AnimatedPage(PageContent, pageID);
const components = {
    [pageID]: () => <EnhancedPage />
};
export default components[pageID];
