import React from 'react';
import { MaterialSymbol } from 'react-material-symbols';
import { appPath } from '../global/utils';

const FileItem = ({ name, postId, index, fileType, onRemove, isLink = false }) => {
  
  // Define a dictionary to map file types to emojis
  const fileTypeEmojis = {
    "application/pdf": "📄",   // PDF documents
    "image/jpeg": "🖼️",       // JPEG images
  };

  // Default emoji logic if the fileType doesn't exactly match the dictionary
  let emoji = "❓"; // Default emoji for unknown file types
  if (fileType) {
    if (fileTypeEmojis[fileType]) {
      emoji = fileTypeEmojis[fileType];
    } else if (fileType.includes("application") || fileType.includes("document")) {
      emoji = "📄"; // Default for application files
    } else if (fileType.includes("image")) {
      emoji = "🖼️"; // Default for image files
    }
  }

  const filePath = appPath() + `/postattachments/${postId}/${name}`;

  const onClick = () => {
    if (isLink) {
      console.log(`Clicked on item at index: ${index}`);
      // Open the file in a new tab/window
      window.open(filePath, '_blank');
    }
  };

  return (
    <div
      className="selected-image-item"
      style={{
        fontSize: "clamp(0px, 3.5vw, 15px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "clamp(0px,1vw,5px) 0",
        backgroundColor: "white",
        borderRadius: "10vw",
        boxShadow: "inset 0 0 0 1px #ff000069",
        ...(isLink && { cursor: "pointer" }),
      }}
      onClick={isLink ? () => onClick() : undefined}
    >
      <div
        style={{
          overflow: "hidden",
          whiteSpace: "nowrap",
          paddingLeft: "clamp(0px,1.5vw,5px)",
          display: "flex",
          alignItems: "center",
        }}
      >
        <span style={{ marginRight: "clamp(0px,0.5vw,3px)"}}>{emoji}</span> {/* Displaying the emoji */}
        <div style={{ overflow:"hidden", textOverflow: "ellipsis"}}>
          {name} {/* Displaying image name or path */}
        </div>
      </div>

      {onRemove != null && (
        <button
          onClick={() => onRemove(index)}
          style={{
            cursor: "pointer",
            backgroundColor: "red",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "clamp(0px,6vw,25px)",
            height: "clamp(0px,6vw,25px)",
            borderRadius: "100%",
          }}
        >
          <MaterialSymbol
            icon={'close'}
            size={'clamp(0px,5vw,20px)'}
            grade={25}
            weight={300}
            style={{ color: "white" }}
          />
        </button>
      )}
    </div>
  );
};

export default FileItem;