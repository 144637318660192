import React, { useRef, useState, useEffect, useContext } from 'react';
import './profileImage.css';
import { MaterialSymbol } from 'react-material-symbols';
import { UploadRequest } from '../../webRequests';
import { appPath, getRandomGradient } from '../global/utils';
import AppContext from '../global/AppContext';

const ProfileImage = ({user, canUploadNew = true}) => {
    const { updateUserPrefs } = useContext(AppContext);
    const fileInputRef = useRef(null);
    const [imageUrl, setImageUrl] = useState('');
    const [imageExists, setImageExists] = useState(true);
    const [loading, setLoading] = useState(false);

    const handleEdit = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = async (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        if (file) {
            console.log('Selected file:', file);
            setLoading(true);
            try {
                const requestData = { imguser: user.userid, oldimage: user.profileimagename};
                const responseData = await UploadRequest('uploaduserimage', file, requestData);
                
                if (!responseData || responseData.status !== 'success') {
                    throw new Error(responseData ? responseData.message : 'Failed to upload image');
                }

                // console.log('Upload successful:', responseData);
                // Update the profile image state with the new image URL or handle the response as needed
                setImageUrl(appPath() + `/profileimages/${user.profileimagename}`);
                user.profileimagename = responseData.filename;
                updateUserPrefs('editUser', user);
                setImageExists(true);
            } catch (error) {
                console.error('Error uploading image:', error);
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        console.log("Profile image path:", user);
        const profileImageUrl = appPath() + `/profileimages/${user.profileimagename}`;

        const checkImageExists = async () => {
            try {
                const response = await fetch(profileImageUrl, { method: 'HEAD' });
                const contentType = response.headers.get('content-type');
                //console.log("ProfileImage Image fetch:", response, "Contentype:", contentType);
                if (!response.ok || !contentType.includes('image')) {
                    setImageExists(false);
                } else {
                    setImageExists(true);
                }
            } catch (error) {
                setImageExists(false);
            }
        };

        checkImageExists();
        setImageUrl(profileImageUrl);
    }, [user.userid, user.profileimagename]);

    const backgroundStyle = imageExists
        ? { backgroundImage: `url(${imageUrl})` }
        : { background: getRandomGradient(user.userid) };

    return (
        <div className='Profile-Image' style={backgroundStyle}>
            {loading && <div className="Loader-Container"><div className="loader" style={{width:'42vw'}}></div></div>}
            {!imageExists && (
                <div className='Profile-Image-Initials' >
                    {user?.firstname?.charAt(0)}{user?.lastname?.charAt(0)}
                </div>
            )}
            {canUploadNew && (
                <div className="Profile-Image-Edit">
                <button 
                    type="button" 
                    className="Icon-Circle CLR-BTN-1" 
                    onClick={handleEdit} 
                    style={{ float: 'right' }}
                    >
                    <MaterialSymbol icon="photo_camera" grade={25} weight={600}/>
                </button>
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                    accept=".jpg"
                    />
                </div>
            )}
        </div>
    );
};

export default ProfileImage;