import React from 'react';
import UserAvatar from './userAvatar';

const UserPresence = ({ userData, startHour = 0, endHour = 24, showDetails = false }) => {
    const { firstname, lastname, profileimagename, absentNotes, presence } = userData;

    // Convert datetime strings into hours for timeline rendering
    const parseHour = (datetime) => new Date(datetime).getHours() + new Date(datetime).getMinutes() / 60;

    // Render a timeline bar
    const renderTimelineBar = (entries, color) => {
        return entries.map((entry, index) => {
            const start = parseHour(entry.from);
            const end = parseHour(entry.to);
            const clampedStart = Math.max(start, startHour); // Clamp to startHour
            const clampedEnd = Math.min(end, endHour); // Clamp to endHour
            if (clampedStart >= clampedEnd) return null; // If the entry is outside the time range, skip it

            const width = (clampedEnd - clampedStart) * 100 / (endHour - startHour); // Width as percentage of time range
            const left = (clampedStart - startHour) * 100 / (endHour - startHour); // Left offset as percentage of time range

            return (
                <div
                    key={index}
                    style={{
                        position: 'absolute',
                        left: `${left}%`,
                        width: `${width}%`,
                        height: '30%',
                        backgroundColor: color,
                        borderRadius: '5px',
                        top: color === '#00bb00' ? '20%' : '60%', // Stack lines
                    }}
                />
            );
        });
    };

    // Render hour labels
    const renderHourLabels = () => {
        const hours = Array.from({ length: endHour - startHour + 1 }, (_, i) => startHour + i); // Custom range of hours
        return hours.map((hour) => (
            <React.Fragment key={hour}>
                {/* Hour label */}
                <div
                    style={{
                        position: 'absolute',
                        left: `${((hour - startHour) * 100) / (endHour - startHour)}%`,
                        transform: 'translateX(-50%)',
                        textAlign: 'center',
                        fontSize: 'clamp(0px,2vw,15px)',
                        top: '100%',
                    }}
                >
                    {hour}
                </div>
    
                {/* Vertical line */}
                <div
                    style={{
                        position: 'absolute',
                        left: `${((hour - startHour) * 100) / (endHour - startHour)}%`,
                        top: '0%',
                        width: '1px',
                        height: '100%', // Full height of the timeline bar
                        backgroundColor: '#0000002e',
                        zIndex: 1, // Ensure the vertical lines are behind the timeline entries
                    }}
                />
            </React.Fragment>
        ));
    };

    return (
        <div className='Shadow-1' style={{ border: "1px solid #ddd", padding: "clamp(0px,2vw,10px)", borderRadius: "8px", margin:"clamp(0px,2vw,10px)" }}>
            {profileimagename && (
                <img
                    src={`/path/to/profile/images/${profileimagename}`}
                    alt={`${firstname} ${lastname}`}
                    style={{ width: "50px", height: "50px", borderRadius: "50%", marginBottom: "10px" }}
                />
            )}

            <div style={{ display: "flex", alignItems:"center" }}>
                <UserAvatar user={userData.user} style={{marginRight:"clamp(0px,2vw,10px)"}}/>
                <div style={{width:"100%"}}>
                    <div style={{marginLeft:"clamp(0px,2vw,20px)"}}>
                        {userData.user.firstname} {userData.user.lastname}
                    </div>
                    <div style={{ position: 'relative', height: 'clamp(0px, 5vw, 30px)', marginInline:"clamp(0px,2vw,10px)", marginBottom:"clamp(0px,2vw,15px)"}}>
                        {renderTimelineBar(absentNotes, '#e378e3')}
                        {renderTimelineBar(presence, '#00bb00')}
                        {renderHourLabels()}
                    </div>
                </div>
            </div>

            {showDetails && (
                <>
                    <div>
                        <h5>Absent Notes</h5>
                        {absentNotes.length > 0 ? (
                            <ul>
                            {absentNotes.map((note, index) => (
                                <li key={index}>
                                <strong>From:</strong> {note.from}, <strong>To:</strong> {note.to}, <strong>Notes:</strong> {note.notes}
                            </li>
                            ))}
                        </ul>
                        ) : (
                            <p>No absent notes for this date.</p>
                        )}
                    </div>
                    <div>
                        <h5>Presence</h5>
                        {presence.length > 0 ? (
                            <ul>
                            {presence.map((entry, index) => (
                                <li key={index}>
                                <strong>From:</strong> {entry.from}, <strong>To:</strong> {entry.to}
                            </li>
                            ))}
                        </ul>
                        ) : (
                            <p>No presence records for this date.</p>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default UserPresence;