import React, { useContext, useEffect, useState } from 'react';
import AnimatedPage from './animatedPage';
import AppContext from '../global/AppContext';
import { SendRequest } from '../../webRequests';
import ButtonWithIcon from '../elements/buttonWithIcon';
import UsersList from '../elements/usersList';

const pageID = "GroupUsers";

const PageContent = () => {
    const { language, userPrefs, updateUserPrefs, userState } = useContext(AppContext);
    const groupId = userPrefs.groupDetail.groupid;
    const orgId = userPrefs.currentOrg.orgid;
    const [ userPending, setUsersPending ] = useState(false);
    const [ groupUsers, setGroupUsers ] = useState('');

    const addUser = () => {
        updateUserPrefs("inviteUserTo", "Group");
        updateUserPrefs("currentPage", "InviteUser");
        updateUserPrefs("groupUsers", groupUsers.groupusers);
        console.log("Invite user to group", currentGroup?.groupname);
    };

    const removeUser = () => {
        updateUserPrefs("removeUserFrom", "Group");
        updateUserPrefs("currentPage", "RemoveUser");
        updateUserPrefs("groupUsers", groupUsers.groupusers);
        console.log("Remove user from group", currentGroup?.groupname);
    };

    const getGroupUsers = async () => {

        const requestData = {
            orgid: orgId,
            groupid: groupId,
        };

        const responseData = await SendRequest('getgroupusers', { requestdata: requestData });
        console.log("getgroupusers:", responseData);

        const usersData = {
            groupid: groupId,
            groupusers: responseData.groupusers?.sort((a, b) => b.role - a.role),
        };

        await setGroupUsers(usersData);
        // console.log("orgUsers: ", orgUsers);
        setUsersPending(false);
    };

    const currentGroup = userState.usergroups.find(group => group.groupid === groupId);
    const currentOrg = userState.userorganizations.find(org => org?.orgid === currentGroup.orgid);
    const isUserInGroup = groupUsers.groupusers?.some(user => user.userid === userState.user.userid);
    const filteredUsers = groupUsers?.groupusers?.filter(user => user.role > 0);

    useEffect(() => {
        if (!currentGroup) {
            updateUserPrefs("currentPage", "Wall");
        }
        else if (!userPending && groupId != groupUsers.groupid){
            setUsersPending(true);
            getGroupUsers();
        }

        //console.log("CurrentGroup:" , currentGroup , "CurrentOrg:", currentOrg);
    }, [currentOrg, updateUserPrefs]);
    
  
    return (
        <>
            <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                <div className='CLR-BG-NavBar Shadow-2' style={{ width: "100%", textAlign: "center", paddingBlock:"10px"}}>
                    <h4 style={{ marginTop: "00px", marginBottom: "0px" }}>{language[`type_${currentGroup.grouptype}_groupDetailTitle`]}</h4>
                    <h2 style={{ marginTop: "0px", marginBottom: "0px" }}>{currentGroup?.groupname || "Group Not Found"}</h2>
                    <h4 style={{ marginTop: "00px", marginBottom: "0px" }}>{currentOrg?.orgname || "Organization Not Found"}</h4>
                </div>
                {/* <GroupsList orgData={currentOrg}/> */}

                <h3 style={{ marginTop: "20px", marginBottom: "0px" }}>{language.administration}</h3>

                {(currentGroup.role > 98 || (currentGroup.orgid == currentOrg.orgid && currentOrg.role > 98)) && (
                    <>
                        <ButtonWithIcon text={language[`type_${currentGroup.grouptype}_addUser`]} leftIcon={'group_add'} width={"90%"} style={{marginTop:"10px"}}
                            onClick={() => addUser()}/>

                        {groupUsers.groupusers && groupUsers.groupusers.length > 0 && (
                            <ButtonWithIcon text={language[`type_${currentGroup.grouptype}_removeUser`]} leftIcon={'group_remove'} width={"90%"} style={{marginTop:"10px"}}
                                onClick={() => removeUser()}/>
                        )}
                    </>
                )}

                {groupUsers.groupusers && groupUsers.groupusers.length > 0 && (
                    <UsersList 
                        title={language[`type_${currentGroup.grouptype}_usersInGroup`]}
                        users={filteredUsers}
                    />
                )}
            </div>
        </>
        );
  };

const EnhancedPage = AnimatedPage(PageContent, pageID);
const components = {
    [pageID]: () => <EnhancedPage />
  };
export default components[pageID];
