import React, { useContext, useRef } from 'react';
import AnimatedPage from './animatedPage';
import AppContext from '../global/AppContext';
import { SendRequest } from '../../webRequests';
import ItemTextEditable from '../elements/itemTextEditable';
import UserAvatar from '../elements/userAvatar';

const pageID = "NewChildStep2";

const PageContent = () => {
    const { language, userPrefs, updateUserPrefs, userState } = useContext(AppContext);
    const orgId = userPrefs.currentOrg.orgid;
    const groupId = userPrefs?.groupDetail?.groupid;
    const inviteFirstNameRef = useRef();
    const inviteLastNameRef = useRef();
    const inviteEmailRef = useRef();

    const handleSubmit = async (event) => {
        event.preventDefault();

        const requestData = {
            orgid: orgId,
            childFirstName: inviteFirstNameRef.current.value,
            childLastName: inviteLastNameRef.current.value,
            ParentData: userPrefs.inviteChildParentData,
        };

        console.log("requestData:" , requestData);

        updateUserPrefs('displayInfoLoading', 1);

        const responseData = await SendRequest('createchilduser', { requestdata: requestData });
        console.log("responseData", responseData);

        updateUserPrefs('displayInfoLoading', 0);
        updateUserPrefs('infoMessage', language.done);
        updateUserPrefs('displayInfoMessage', 1);
        updateUserPrefs("currentPage", "OrganizationDetail");
    };

    const handleChildOrgInvite = async (event, userid) => {
        event.preventDefault();
        console.log("Invite user " , userid , " to orgid " , orgId);

        const requestData = { userid: userid, orgid: orgId };
        
        updateUserPrefs('displayInfoLoading', 1);

        const responseData = await SendRequest('invitechilduser', { requestdata: requestData });
        console.log("responseData", responseData);

        updateUserPrefs('displayInfoLoading', 0);
        updateUserPrefs('infoMessage', language.done);
        updateUserPrefs('displayInfoMessage', 1);
        updateUserPrefs("currentPage", "OrganizationDetail");
    };

    const goBack = () => {
        if (userPrefs.inviteUserTo === "Organization" || userPrefs.inviteUserTo === "OrganizationChild"){
            updateUserPrefs("currentPage", "OrganizationDetail");
        }
        else if (userPrefs.inviteUserTo === "Group" || userPrefs.inviteUserTo === "GroupChild"){
            updateUserPrefs("currentPage", "GroupDetail");
        }
    };
  
    const currentOrg = userState.userorganizations.find(org => org.orgid === orgId);
    const currentGroup = userState.usergroups.find(group => group.groupid === groupId);
  
    return (
            <form onSubmit={handleSubmit}>
                <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>

                    {/* Options */}
                    {console.log("userPrefs.newChildOptions:", userPrefs.newChildOptions)}
                    {userPrefs.newChildOptions && userPrefs.newChildOptions.length > 0 && (
                        <>                        
                            <h3 style={{ marginTop: "20px", paddingInline: "20px", textAlign:"center" }}>{language.userHasChildren}</h3>
                            <div className={`usersList`} style={{display:"flex", justifyContent:"center", paddingTop:"10px",paddingBottom:"10px", flexWrap:"wrap", gap:"10px 0px"}}>
                                {userPrefs.newChildOptions
                                    .map((user, index) => (
                                        <div key={user.userid} style={{paddingInline:'10px'}}>
                                            <UserAvatar
                                                userid={user.userid}
                                                userName={{firstname: user.firstname, lastname: user.lastname}}
                                                outlineStyle={user.role >= 98 ? { boxShadow: '0 0 0 4px #fdd046' } : {}}
                                                displayName={true}
                                                onClick={(event) => handleChildOrgInvite(event, user.userid)}
                                            />
                                        </div>
                                    ))}
                            </div>
                            <h3 style={{ marginTop: "20px", marginBottom: "0px" }}>{language.orCreateNewChild}</h3>
                        </>
                    )}
                    
                    <h3 style={{marginBottom:"20px"}}>{language.enterChildInfo}</h3>
                    {/* <h2 style={{marginTop:"0px", marginBottom:"0px"}}>{currentOrg?.orgname || "Organization Not Found"}</h2>
                    <h2 style={{marginTop:"0px"}}>{currentGroup?.groupname || "Organization Not Found"}</h2> */}


                    <ItemTextEditable 
                        label={language.firstName} 
                        ref={inviteFirstNameRef}
                        required={true}
                    />

                    <ItemTextEditable 
                        label={language.lastName} 
                        ref={inviteLastNameRef}
                        required={true}
                    />

                    <button type="submit" className="ListItem CLR-BTN-1">
                        {language.continue}
                    </button>

                    <button type="button" className="ListItem CLR-BTN-1" onClick={goBack}>
                        {language.cancel}
                    </button>
                </div>
            </form>
    );
};

const EnhancedPage = AnimatedPage(PageContent, pageID);
const components = {
    [pageID]: () => <EnhancedPage />
};
export default components[pageID];
