import React, { useContext, useState, useEffect, useRef } from 'react';
import AppContext from '../global/AppContext';
import ButtonLayered from '../elements/buttonLayered';
import { MaterialSymbol } from 'react-material-symbols';
import './postAddModal.css';

const InfoOptions = () => {
  const { language, userState, userPrefs } = useContext(AppContext);
  const [isVisible, setIsVisible] = useState(false);
  const [shouldRender, setShouldRender] = useState(false);
  const { options, hideOptions } = useContext(AppContext);
  const textareaRef = useRef(null);

  useEffect(() => {
    let isVisibleTimeout;
    let shouldRenderTimeout;
    const conditionsMet = options?.isOpen;

    if (userPrefs && userState) {
      if (conditionsMet) {
        setShouldRender(true);
        isVisibleTimeout = setTimeout(() => {
          setIsVisible(true);
        }, 50);
      } else {
        setIsVisible(false);
        shouldRenderTimeout = setTimeout(() => {
          setShouldRender(false);
        }, 500);
      }
    }
    return () => {
      clearTimeout(isVisibleTimeout);
      clearTimeout(shouldRenderTimeout);
    };
  }, [userPrefs, userState, options]);

  const handleButtonClick = (action) => {
    if (action) {
      action();
    }
    hideOptions();
  };

  const close = () => {
    if (typeof options.onClose === 'function') {
      options.onClose(); // Call the onClose action if it exists
    }
    hideOptions();
  };

  return shouldRender ? (
    <div className='Custom-Modal Page-Container' style={{ transition: '0.5s', zIndex: '3', backgroundColor: isVisible ? '#00000078' : '', position: 'fixed', maxHeight: '100%', overflowY: 'hidden', fontSize:"clamp(0px, 5vw, 20px)" }}>
      <div className={`Box CLR-BG-2 Shadow-1 Fade FromTop ${isVisible ? 'Visible' : ''}`} style={{ width: '80%', position:"fixed"}}>
        {options.canClose !== false && (
        <button type="button" className="Icon-Circle CLR-BTN-Close" onClick={() => close()} style={{ position: 'fixed', top:"0px", right:"0px"}}>
            <MaterialSymbol icon="close" grade={-25} />
        </button>
        )}
        {options.showIcon !== false && ( // Only render icon if showIcon is not explicitly false
          <div>
            <MaterialSymbol icon="question_mark" grade={25} weight={600} style={{ fontSize: '40px', marginBottom: '20px', color: '#bebebe' }} />
          </div>
        )}
        <div className='CLR-TXT-1' style={{ marginBottom: '0px' }}>
          {options.message}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          {(options.buttons || []).map((button, index) => (
            <ButtonLayered
              key={index}
              type="submit"
              text={button.text}
              disabled={button.disabled}
              onClick={() => handleButtonClick(button.onClick)}
              style={button.style}
            />
          ))}
        </div>
      </div>
    </div>
  ) : null;
};

export default InfoOptions;