import React, { useContext, useEffect, useState } from 'react';
import AnimatedPage from './animatedPage';
import AppContext from '../global/AppContext';
import { SendRequest } from '../../webRequests';
import ButtonWithIcon from '../elements/buttonWithIcon';
import UsersList from '../elements/usersList';

const pageID = "OrganizationChildren";

const PageContent = () => {
    const { language, userPrefs, updateUserPrefs, userState } = useContext(AppContext);
    const groupId = userPrefs.groupDetail.groupid;
    const orgId = userPrefs.currentOrg.orgid;
    const [ userPending, setUsersPending ] = useState(false);
    const [ orgUsers, setOrgUsers ] = useState('');

    const addChildUser = () => {
        updateUserPrefs("inviteUserTo", "OrganizationChild");
        updateUserPrefs("currentPage", "NewChildStep1");
        console.log("Invite user to group", currentOrg?.groupname);
    };

    const removeChildUser = () => {
        updateUserPrefs("removeUserFrom", "OrganizationChild");
        updateUserPrefs("currentPage", "RemoveUser");
        console.log("Remove child user from group", currentOrg?.groupname);
    };

    const getOrgUsers = async () => {

        const requestData = {
            orgid: orgId,
            groupid: groupId,
        };

        const responseData = await SendRequest('getorganizationusers', { requestdata: requestData });
        console.log("getorganizationusers:", responseData);

        const usersData = {
            groupid: groupId,
            groupusers: responseData.groupusers?.sort((a, b) => b.role - a.role),
        };

        await setOrgUsers(usersData);
        // console.log("orgUsers: ", orgUsers);
        setUsersPending(false);
    };

    const currentGroup = userState.usergroups.find(group => group.groupid === groupId);
    // const currentOrg = userState.userorganizations.find(org => org?.orgid === currentGroup.orgid);
    const currentOrg = userPrefs.currentOrg;
    //const isUserInGroup = groupUsers.orgUsers?.some(user => user.userid === userState.user.userid);
    const filteredUsers = userPrefs?.orgUsers?.orgusers?.filter(user => user.role == 0);

    useEffect(() => {
        if (!currentOrg && userPrefs.currentPage != "Wall"){
            updateUserPrefs("currentPage", "Wall");
        }
        else if (!userPending && groupId != orgUsers.groupid){
            setUsersPending(true);
            getOrgUsers();
        }

        //console.log("CurrentGroup:" , currentGroup , "CurrentOrg:", currentOrg);
    }, [currentOrg, updateUserPrefs]);
    
  
    return (
        <>
            <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                <div className='CLR-BG-NavBar Shadow-2' style={{ width: "100%", textAlign: "center", paddingBlock:"10px"}}>
                    <h4 style={{ marginTop: "00px", marginBottom: "0px" }}>{language[`type_${currentOrg.orgtype}_organizationDetailTitle`]}</h4>
                    <h2 style={{ marginTop: "0px", marginBottom: "0px" }}>{currentOrg.orgname || "Organization Not Found"}</h2>
                </div>
                {/* <GroupsList orgData={currentOrg}/> */}

                <h3 style={{ marginTop: "20px", marginBottom: "0px" }}>{language[`type_${currentOrg.orgtype}_childUsers`]}</h3>

                {(currentOrg.role > 98 ) && (
                    <>
                        <ButtonWithIcon text={language[`addChildUser`]} leftIcon={'group_add'} width={"90%"} style={{marginTop:"10px"}}
                            onClick={() => addChildUser()}/>

                        {filteredUsers.length > 0 && (
                            <ButtonWithIcon text={language[`removeChildUser`]} leftIcon={'group_remove'} width={"90%"} style={{marginTop:"10px"}}
                                onClick={() => removeChildUser()}/>
                        )}
                    </>
                )}

                {filteredUsers.length > 0 && (
                    <UsersList 
                        title={language[`type_${userPrefs.currentOrg.orgtype}_childUsersInOrg`]}
                        users={filteredUsers}
                    />
                )}
            </div>
        </>
        );
  };

const EnhancedPage = AnimatedPage(PageContent, pageID);
const components = {
    [pageID]: () => <EnhancedPage />
  };
export default components[pageID];
