import React, { useContext, useState, useEffect } from 'react';
import ItemProfileEditable from '../elements/itemTextEditable';
import { SendRequest } from '../../webRequests';
import AppContext from '../global/AppContext';

const AdminTools = () => {
    const { updateUserPrefs, getUserState } = useContext(AppContext);
    const [pwaMode, setPwaMode] = useState(false);
    
    const loginAs = () => async (newValue) => {
        if (newValue == ""){
            return;
        }

        try {
            const responseData = await SendRequest('loginas', { username: newValue });
            console.log("Login as:", responseData);
            // const orgdata = await SendRequest('getuserorgdata');
            // await updateUserPrefs('orgdata', orgdata);
            updateUserPrefs('infoMessage', responseData.message);
            updateUserPrefs('displayInfoMessage', 1);

            if (responseData.resultcode === 0){
                getUserState();
            }
        } catch (error) {
            console.error('Error updating user property:', error);
        }
    };

    const handleDisplayMessage = () => (newValue) => {
        console.log("Message is:", newValue);
        updateUserPrefs('infoMessage', newValue);
        updateUserPrefs('displayInfoMessage', 1);
    };

    const displayLoading = () => {
        console.log("displayInfoLoading");
        updateUserPrefs('displayInfoLoading', 1);
    };

    const manageOrganizations = async () => {
        updateUserPrefs('menuOpen', 0);
        updateUserPrefs("currentPage", "OrganizationsManagement");
    };

    const sendTestNotification = async () => {
        const responseData = await SendRequest('sendtestnotification');
        console.log("responseData");
        updateUserPrefs('infoMessage', 'Testovací notifikace odeslána');
        updateUserPrefs('displayInfoMessage', 1);
    };

    useEffect(() => {
        const checkPwaMode = () => {
          const isPWA = window.matchMedia('(display-mode: standalone)').matches ||
                        window.matchMedia('(display-mode: fullscreen)').matches ||
                        window.matchMedia('(display-mode: minimal-ui)').matches ||
                        window.navigator.standalone === true;
          setPwaMode(isPWA);
        };
    
        checkPwaMode();
      }, []);
    
    return ( 
        <>
            <ItemProfileEditable 
                label="Login as"
                value="" 
                onFinishEditing={loginAs()} 
            />

            <ItemProfileEditable 
                label="Display message"
                value="" 
                onFinishEditing={handleDisplayMessage()} 
            />

            <button type="button" className="ListItem CLR-BTN-1" onClick={displayLoading}>
                {"Display loading"}
            </button>

            <button type="button" className="ListItem CLR-BTN-1" onClick={manageOrganizations}>
                {"Orgs management"}
            </button>

            <button type="button" className="ListItem CLR-BTN-1" onClick={sendTestNotification}>
                {"Test Notification"}
            </button>

            <div>
                {pwaMode ? 'PWA' : 'Web'} mode
            </div>
        </>
    );
};

export default AdminTools;