import React, { useContext } from 'react';
import AppContext from '../global/AppContext';
import { MaterialSymbol } from 'react-material-symbols';
import './postIcon.css';
// import IconAddPost from '../../assets/icons/svg/icon_add_post_1.svg';
import { ReactComponent as AddPostIcon } from '../../assets/icons/svg/icon_add_post_1.svg';

const PostIcon = ({ onClick }) => {
    const { language } = useContext(AppContext);

    return (
        <button className='PostIconButton' onClick={onClick}>
            {/* <div className='PostIcon'>
                <MaterialSymbol 
                    icon={'mode_comment'}
                    fill={true}
                    grade={25}  
                    weight={300} 
                    className="RightIcon"
                />
            </div>
            <div className='PostIconPlus'>
                <MaterialSymbol 
                    icon={'add_circle'}
                    fill={true}
                    grade={25}  
                    weight={300} 
                    className="RightIcon"
                />
            </div> */}
            <div className='PostIcon'>
                <AddPostIcon/>                
            </div>
            <div className='PostIconLabel'>
                {language.addPost}
            </div>
        </button>
    );
};

export default PostIcon;