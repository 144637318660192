import React, { useState, useRef, useEffect, useCallback, forwardRef } from 'react';
import { MaterialSymbol } from 'react-material-symbols';
import './itemTextEditable.css';

const ItemTextEditable = forwardRef(
  (
    { label, value, onFinishEditing = () => {}, required = false, type = 'text', simple = false, fontSize = "20px", clearValue, style = {}, isEditable = true }, 
    forwardedRef
  ) => {
    const [isEditing, setIsEditing] = useState(false); // Track if it's in editing mode
    const [inputValue, setInputValue] = useState(value || '');
    const [hasFocus, setHasFocus] = useState(false); // Track focus state
    const internalRef = useRef(null);
    const inputRef = forwardedRef || internalRef; // Use the forwarded ref or the internal ref

    useEffect(() => {
      setInputValue(value || '');
    }, [value]);

    const handleEdit = () => {
      if (!isEditable) return; // Do nothing if isEditable is false
      setIsEditing(true);
      setTimeout(() => {
        inputRef.current.focus(); // Focus on input field after a short delay
      }, 0);
    };

    const handleFocus = () => {
      if (!isEditable) return; // Prevent focusing if not editable
      console.log("inputValue ", inputValue, " clearValue ", clearValue);
      if (clearValue !== undefined && inputValue === clearValue) {
        setInputValue(''); // Clear input only if current value matches clearValue
      }
      setHasFocus(true); // Set focus state to true
      if (!isEditing) {
        setIsEditing(true); // Enable editing mode when focused
      }
    };

    const handleBlur = () => {
      setHasFocus(false); // Reset focus state on blur
      finishEditing();
    };

    const finishEditing = useCallback(() => {
      setHasFocus(false);
      setIsEditing(false);
      if (inputValue === "" && clearValue) {
        setInputValue(clearValue);
        onFinishEditing(clearValue);
      } else {
        onFinishEditing(inputValue);
      }
    }, [inputValue, onFinishEditing]);

    const handleKeyDown = useCallback((event) => {
      if (event.key === 'Enter') {
        inputRef.current.blur();
      }
    }, [finishEditing]);

    const handleClickOutside = useCallback((event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        finishEditing();
      }
    }, [finishEditing]);

    const handleInputChange = (event) => {
      setInputValue(event.target.value);
    };

    useEffect(() => {
      if (isEditing) {
        document.addEventListener('click', handleClickOutside, true);
      } else {
        document.removeEventListener('click', handleClickOutside, true);
      }

      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }, [isEditing, handleClickOutside]);

    return (
      <div className="Editable-Item" style={style}>
        {/* Conditionally render the edit icon if editable and not in simple mode */}
        {!simple && isEditable && (
          <div className="Edit-Icon">
            <button
              type="button"
              className="Icon-Circle CLR-BTN-1"
              onClick={handleEdit}
              style={{ float: 'right' }}
            >
              <MaterialSymbol icon="edit" grade={25} weight={600} />
            </button>
          </div>
        )}
        <div style={{ width: '100%' }}>
          {!simple && <div className="Label">{label}</div>}
          <div>
            <input
              required={required}
              type={type}
              value={inputValue}
              onChange={handleInputChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              ref={inputRef}
              readOnly={!isEditable} // Make the input read-only if not editable
              className={(isEditing || hasFocus) ? 'Input-highlight' : ''}
              style={{ fontSize: fontSize, ...style }}
            />
          </div>
        </div>
      </div>
    );
  }
);

export default ItemTextEditable;