import React, { useContext, useEffect, useState } from 'react';
import AnimatedPage from './animatedPage';
import AppContext from '../global/AppContext';
import ButtonWithIcon from '../elements/buttonWithIcon';
import { MaterialSymbol } from 'react-material-symbols';

const pageID = "MyGroups";

const PageContent = () => {
  const { language, updateUserPrefs, userState } = useContext(AppContext);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
      setGroups(userState.usergroups);
      //console.log("GROUPS:", userState.usergroups);
  }, [userState.usergroups]);

  const gotoGroup = (group) => {
    updateUserPrefs("currentGroup", group);
    updateUserPrefs("currentOrg", userState.userorganizations.find(org => org?.orgid === group.orgid));
    updateUserPrefs("groupDetail", group);
    updateUserPrefs("currentPage", "GroupDetail");
  };

  const addGroup = (orgid) => {
    updateUserPrefs("currentOrg", userState.userorganizations.find(org => org?.orgid === orgid));
    updateUserPrefs("currentPage", "NewGroup");
  };

  // Group groups by organization
  const groupedByOrganization = groups.reduce((acc, group) => {
    const { orgid, orgname } = group; // Extract orgid and orgname
    if (!acc[orgid]) {
      acc[orgid] = { orgname, groups: [] };
    }
    acc[orgid].groups.push(group); // Push the entire group object, including orgid and orgname
    //console.log("ACC:", acc);
    return acc;
  }, {});

  return (
    <div style={{ padding: "clamp(0px, 1vw, 30px)", paddingTop:"clamp(0px, 3vw, 30px)" }}>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", position: "relative" }}>
        <div>
          <h3 style={{ marginBlock: "0px", position: "relative", left: '50%', transform: "translate(-50%)", paddingBottom: "10px" }}>
            {language.myGroups}
          </h3>
        </div>
        <div style={{ width: '100%' }}>
          {Object.keys(groupedByOrganization).map(orgid => {
            const { orgname, groups } = groupedByOrganization[orgid];
            const canAddPost = userState.highroleorgs.includes(Number(orgid)); // Check if the orgid is in highroleorgs array
            
            return (
              <div key={orgid} style={{ marginBottom: '20px', textAlign: 'center' }}>
                <div style={{display: "flex", alignItems: "center", position: "relative"}}>
                  <h3 style={{ marginBlock: "0px", position: "relative", left: '50%', transform: "translate(-50%)" }}>
                    {orgname}
                  </h3>

                  {canAddPost && ( // Conditionally render the add button
                    <div style={{marginLeft:"auto"}}>
                      <button
                        type="button"
                        className="Icon-Circle"
                        onClick={() => addGroup(Number(orgid))}
                        style={{ marginRight: "20px", color:"white", backgroundColor:"#fdd046", boxShadow:"2px 2px 3px #00000036"}}
                      >
                        <MaterialSymbol icon="add" grade={25} weight={600} />
                      </button>
                    </div>
                  )}
                </div>

                <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                  {groups.map(group => (
                    <ButtonWithIcon
                      key={group.groupid}
                      text={group.groupname}
                      leftIcon="group"
                      rightIcon="arrow_forward_ios"
                      width={"90%"}
                      onClick={() => gotoGroup(group)} // Handle button click
                      style={{ margin: '5px' }} // Add margin for spacing between buttons
                    />
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const EnhancedPage = AnimatedPage(PageContent, pageID);
const components = {
  [pageID]: () => <EnhancedPage />
};
export default components[pageID];