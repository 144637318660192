import React, { useContext, useState, useLayoutEffect, useMemo } from 'react';
import AppContext from '../global/AppContext';
import './navBar.css';
import { MaterialSymbol } from 'react-material-symbols';

const NavBar = () => {
    const { language, userState, userPrefs, updateUserPrefs } = useContext(AppContext);
    const [activeIndex, setActiveIndex] = useState(-1);
    const [highlightStyle, setHighlightStyle] = useState({});

    const navItems = useMemo(() => {
        return [
            [0, 'HomeScreen', language.home, 'home'], // 'home' is the icon name for the Wall
            [1, 'Wall', language.wall, 'assignment'], // 'home' is the icon name for the Wall
            [2, 'GamesAndApps', language.gamesAndApps, 'sports_esports'], // 'sports_esports' for Games and Apps
            [3, 'AbsentNotes', language.absentNotes, 'person_cancel'], // 'note' for Absent Notes
            [4, 'Calendar', language.calendar, 'calendar_today'], // 'calendar_today' for Calendar
            // [4, 'ToggleMenu', userPrefs.menuOpen === 0 ? language.more : language.less, userPrefs.menuOpen === 0 ? 'menu' : 'menu'] // 'menu' or 'expand_less' for Toggle
        ];
    }, [language, userPrefs.menuOpen]);

    const openCloseMenu = () => {
        updateUserPrefs('menuOpen', userPrefs.menuOpen === 0 ? 1 : 0);
    };

    const loginstatus = userState?.loginstatus || 0;

    useLayoutEffect(() => {
        const updateHighlight = () => {
            const index = navItems.findIndex(item => item[1] === userPrefs.currentPage);
            setActiveIndex(index !== -1 ? index : 0); // Default to first item if not found
            
            const activeButton = document.querySelectorAll('.NavItems button')[index];
            // console.log("Highlight updated " + userPrefs.currentPage + " button " + activeButton);

            if (activeButton) {
                setHighlightStyle({
                    width: activeButton.offsetWidth,
                    left: activeButton.offsetLeft,
                    boxShadow: 'inset 0px -2px 0 #fdd046'
                });
            } else {
                setHighlightStyle(prevStyle => ({
                    ...prevStyle,
                    backgroundColor: 'transparent',
                    boxShadow: 'inset 0px -2px 0 #ffffff00',
                }));
            }
        };

        const timeoutId = setTimeout(updateHighlight, 50);

        return () => clearTimeout(timeoutId);
    }, [userPrefs.currentPage, navItems]);

    const handleButtonClick = (item) => {
        const [index, id, name] = item;
        setActiveIndex(index);

        if (id !== "ToggleMenu") {
            updateUserPrefs("currentPage", id);
        }

        if (name !== language.more && name !== language.less) {
            updateUserPrefs('menuOpen', 0);
        } else {
            openCloseMenu();
        }
    };

    return (
        <div className={`NavBar Fade FromBottom CLR-BG-NavBar Shadow-2 ${loginstatus === 1 ? 'Visible' : ''}`}>
            {loginstatus === 1 && (
                <div className='NavItems'>
                    <div className='NavHighlight' style={highlightStyle}></div>
                    {navItems.map((item, index) => (
                            <button
                            key={index}
                            type="button"
                            className={`CLR-TXT-NavBar ${activeIndex === index ? 'active' : ''} ${index === 4 ? 'more-btn' : ''}`}
                            onClick={() => {
                                handleButtonClick(item);
                                if (item[2] === language.more || item[2] === language.less) {
                                    openCloseMenu();
                                }
                            }}
                        >
                            <MaterialSymbol 
                                icon={item[3]} 
                                grade={25}  
                                weight={300} 
                                style={{color:"gray"}} 
                            />
                            {item[2]}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};

export default NavBar;