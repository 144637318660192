import React, { useContext, useState, useEffect, useRef } from 'react';
import AppContext from '../global/AppContext';

const AnimatedPage = (WrappedComponent, pageID, userPrefsProperty = 'currentPage', safeTopPadding = true, safeBottomPadding = true) => {
  const AnimatedPageComponent = (props) => {
    const { userPrefs, userState } = useContext(AppContext);
    const [isVisible, setIsVisible] = useState(false);
    const [shouldRender, setShouldRender] = useState(false);
    const prevUserPrefs = useRef();

    useEffect(() => {
      let isVisibleTimeout;
      let shouldRenderTimeout;

      const renderConditions = userPrefsProperty === 'login' 
        ?
        (userPrefs?.wantLogIn || 0) && userState?.loginstatus !== 1
        :
        userPrefs[userPrefsProperty] === pageID && userState?.loginstatus === 1;

      if (userPrefs && userState) {
        if (renderConditions) {
          setShouldRender(true);

          isVisibleTimeout = setTimeout(() => {
            setIsVisible(true);
          }, 50);
        } else {
          setIsVisible(false);
          shouldRenderTimeout = setTimeout(() => {
            setShouldRender(false);
          }, 500);
        }
      }

      prevUserPrefs.current = userPrefs;

      return () => {
        clearTimeout(isVisibleTimeout);
        clearTimeout(shouldRenderTimeout);
      };
    }, [userPrefs, userState]);

    // useEffect(() => {

    //   if (!shouldRender) {
    //     if (initialized.current){
    //       updateScrollY(0);
    //       initialized.current = false;
    //     }
    //     return;
    //   }

    //   const handleScroll = () => {
    //     if (scrollableElement) {
    //       const scrollY = scrollableElement.scrollTop;
    //       updateScrollY(scrollY);
    //     }
    //   };

    //   const scrollableElement = scrollableRef.current;

    //   if (scrollableElement) {

    //     if (shouldRender && isVisible && !initialized.current){
    //       updateScrollY(0);
    //       initialized.current = true;
    //     }

    //     scrollableElement.addEventListener('scroll', handleScroll);

    //     return () => {
    //       scrollableElement.removeEventListener('scroll', handleScroll);
    //     };
    //   }
    // }, [shouldRender, isVisible, updateScrollY]);

    const containerStyle = userPrefsProperty === 'login'  ? {
      display: "flex",
      height: "100%",
      position: "relative",
      zIndex: "1"
    } : {
      paddingTop: safeTopPadding ? "50px" : "0px",
      paddingBottom: "100px",
      height: safeBottomPadding ? "calc(100% - 100px)" : "100%",
      overflowY: "auto",
    };

    const fadeFrom = userPrefsProperty === 'login' ? 'FromTop' : 'FromBottom';

    const combinedStyle = {
      ...containerStyle,
      ...props.style // Custom styles passed through props
    };

    return (
      shouldRender ? (
        <div className={`${pageID} Page-Content`}>
            <div className={`CLR-BG-2 CLR-TXT-1 Shadow-1 Fade ${props.className} ${fadeFrom} ${isVisible ? 'Visible' : ''}`} style={combinedStyle}>
            <WrappedComponent {...props} />
            </div>
        </div>
      ) : null
    );
  };

  return AnimatedPageComponent;
};

export default AnimatedPage;