import React, { useContext } from 'react';
import AnimatedPage from './animatedPage';
import AppContext from '../global/AppContext';

const pageID = "GamesAndApps";

const PageContent = () => {
  const { language } = useContext(AppContext);

  return (
    <h3 style={{ marginBottom: "15px", paddingInline: "15px" }}>{language.gamesAndApps}</h3>
  );
};

const EnhancedPage = AnimatedPage(PageContent, pageID);
const components = {
    [pageID]: () => <EnhancedPage />
  };
  export default components[pageID];