import React, { useContext, useEffect, useState } from 'react';
import AnimatedPage from './animatedPage';
import AppContext from '../global/AppContext';
import Picker from 'react-mobile-picker';
import { DayPicker } from 'react-day-picker';
import { cs } from 'date-fns/locale/cs';
import { formatPostDate } from '../global/utils';

const DatePickerModal = (onFinish) => {

    const { language, userPrefs, updateUserPrefs, userState, hideOptions } = useContext(AppContext);
    const [isVisible, setIsVisible] = useState(false);
    const [shouldRender, setShouldRender] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [month, setMonth] = useState(new Date());
    const [selectedDateTime, setSelectedDateTime] = useState(new Date());
    const [ignoreTime, setIgnoreTime] = useState(false);
    const [title, setTitle] = useState("");
    const [rangeStartDate, setRangeStartDate] = useState(undefined); // Define your start date (e.g., Jan 1, 2023)

    // const [rangeStartDate] = useState(new Date(2025, 0, 5)); // Define your start date (e.g., Jan 1, 2023)

    const [timePickerValue, setTimePickerValue] = useState({
        hour: '08',
        minute: '00'
    });

    const timeSelections = {
        hour: [...Array(24).keys()].map(n => n.toString().padStart(2, '0')),
        minute: [...Array(60).keys()].map(n => n.toString().padStart(2, '0'))
    };

    const normalizeDate = (date) => {
        const normalized = new Date(date);
        normalized.setHours(0, 0, 0, 0); // Reset hours, minutes, seconds, milliseconds
        return normalized;
    };

    const modifiers = {
        weekends: (date) => date.getDay() === 0,
        inRange: (date) =>
            rangeStartDate && selectedDate && date > rangeStartDate && date < selectedDate,
        rangeStart: (date) =>
            rangeStartDate && 
            normalizeDate(date).getTime() === normalizeDate(rangeStartDate).getTime() &&
            normalizeDate(rangeStartDate).getTime() !== normalizeDate(selectedDate).getTime(),
        rangeEnd: (date) =>
            selectedDate && 
            normalizeDate(date).getTime() === normalizeDate(selectedDate).getTime() &&
            normalizeDate(selectedDate).getTime() !== normalizeDate(rangeStartDate).getTime(),
    };

    const modifiersClassNames = {
        weekends: "sundays",
        inRange: 
            rangeStartDate && selectedDate && selectedDate > rangeStartDate 
                ? "rdp-in-range" 
                : undefined,
        rangeStart: 
            rangeStartDate 
                ? selectedDate && selectedDate > rangeStartDate 
                    ? "rdp-range-start rdp-range-start-day" 
                    : "rdp-range-start-day" 
                : undefined,
        rangeEnd: 
            rangeStartDate && selectedDate && selectedDate > rangeStartDate 
                ? "rdp-range-end" 
                : undefined,
    };

    useEffect(() => {
        let isVisibleTimeout;
        let shouldRenderTimeout;
        let conditionsMet = (userPrefs?.displayDatePickerModal || 0) && userState.loginstatus === 1;

        if (userPrefs && userState) {
            if (conditionsMet) {
                setShouldRender(true);
                initializeDate();
                isVisibleTimeout = setTimeout(() => {
                    setTimePickerValue({
                        hour: userPrefs?.displayDatePickerModal?.presetHour ? userPrefs.displayDatePickerModal.presetHour : '00',
                        minute: userPrefs?.displayDatePickerModal?.presetMinute ? userPrefs.displayDatePickerModal.presetMinute : '00',
                    });
                    setIgnoreTime(userPrefs?.displayDatePickerModal?.ignoreTime ? userPrefs?.displayDatePickerModal?.ignoreTime : false);
                    setTitle(userPrefs?.displayDatePickerModal?.title ? userPrefs?.displayDatePickerModal?.title : "");
                    setIsVisible(true);
                }, 50);
            } else {

                setIsVisible(false);
                shouldRenderTimeout = setTimeout(() => {
                    setShouldRender(false);
                }, 500);
            }
        }
        return () => {
            clearTimeout(isVisibleTimeout);
            clearTimeout(shouldRenderTimeout);
        };
    }, [userPrefs.displayDatePickerModal, userState]);

    const initializeDate = () => {
        if (userPrefs.displayDatePickerModal.fromDate){
            setRangeStartDate(userPrefs.displayDatePickerModal.fromDate);
        }
        else{
            setRangeStartDate(undefined);
        }

        if (userPrefs.datePickerStorage && userPrefs.datePickerStorage[`${userPrefs.displayDatePickerModal.dateStoreKey}`])
        {
            pickDateTimeWithDate(userPrefs.datePickerStorage[`${userPrefs.displayDatePickerModal.dateStoreKey}`]);
        }
        else if (userPrefs.displayDatePickerModal && userPrefs.displayDatePickerModal.initDate){
            pickDateTimeWithDate(userPrefs.displayDatePickerModal.initDate);
        }
        else{
            console.log("Init date none");
            setSelectedDate(new Date);
            setTimePickerValue({
                hour: '08',
                minute: '00'
            });
        }
    }

    useEffect(() => {
        const updatedDate = new Date(selectedDate);
        updatedDate.setHours(parseInt(timePickerValue.hour, 10));
        updatedDate.setMinutes(parseInt(timePickerValue.minute, 10));
        updatedDate.setSeconds(0);
        updatedDate.setMilliseconds(0);
        setSelectedDateTime(updatedDate);

    }, [selectedDate, timePickerValue]);

    const pickDateTimeWithDate = (date) => {        

        if (date) {
            setSelectedDate(date); 
            setSelectedDateTime(date);
            
            // Extract hours and minutes from the openDate and set it in the timePickerValue
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0'); // Ensure minutes are always two digits

            // Update the timePickerValue with extracted hours and minutes
            setTimePickerValue({
                hour: hours,
                minute: minutes
            });
        }
    }

    const Finish = (picked = false) => {

        if (picked && !isNaN(new Date(selectedDateTime))){
            console.log("SelectedDate" , selectedDateTime);

            if (rangeStartDate && selectedDateTime < rangeStartDate){
                updateUserPrefs('infoMessage', language.noteStartTimeHigherThanEnd);
                updateUserPrefs('displayInfoMessage', 1);
                return;
            }

            const currentStorage = userPrefs.datePickerStorage || {};
            const newValue  = {
                [`${userPrefs.displayDatePickerModal.dateStoreKey}`] : selectedDateTime,
            }
            const updatedStorage = {...currentStorage,...newValue};
            updateUserPrefs("datePickerStorage" , updatedStorage);

            updateUserPrefs("displayDatePickerModal", 0);
        }
        else if (picked) {
            updateUserPrefs('infoMessage', language.timeNotPicked);
            updateUserPrefs('displayInfoMessage', 1);
        }
        else{
            updateUserPrefs("displayDatePickerModal", 0);
        }
    };

    return (shouldRender ?
        <div className='Page-Container' style={{ transition: '0.5s', zIndex: '2', backgroundColor: isVisible ? '#00000078' : '', position: 'fixed', maxHeight: '100%', overflowY: 'hidden' }}>
            <div className={`Box CLR-BG-2 Shadow-1 Fade FromTop ${isVisible ? 'Visible' : ''}`} style={{ width: '80%', backgroundColor: 'white'}} >
                <div style={{display: title == "" ? "none" : "block", textAlign:"center", marginBottom:"clamp(0px,5vw,25px)"}}><strong>{title}</strong></div>
                <div style={{ display: ignoreTime ? "none" : "flex", justifyContent: "center", fontSize: "20px", fontWeight: "500" }}>
                    <Picker value={timePickerValue} onChange={setTimePickerValue} wheelMode="normal" height={100} itemHeight={35} className='picker' style={{ width: "90%", position: "relative", maskImage: "linear-gradient(to top, transparent, transparent 5%, white 50%, white 50%, transparent 95%, transparent)" }}>
                        <div style={{ position: "absolute", top: "50%", transform: "translate(0,-50%)", fontWeight: "700" }}>
                            :
                        </div>
                        {Object.keys(timeSelections).map(name => (
                            <Picker.Column key={name} name={name}>
                                {timeSelections[name].map(option => (
                                    <Picker.Item key={option} value={option}>
                                        {option}
                                    </Picker.Item>
                                ))}
                            </Picker.Column>
                        ))}
                    </Picker>
                </div>
                <DayPicker
                    modifiers={modifiers}
                    modifiersClassNames={modifiersClassNames}
                    fixedWeeks={true}
                    className='simplified'
                    mode="single"
                    captionLayout="dropdown"
                    selected={selectedDate}
                    onSelect={(date) => {
                        setSelectedDate(date);
                        if (date) { setMonth(date); }
                    }}
                    month={month}  // Control the displayed month with state
                    onMonthChange={setMonth}  // Update state when the user manually changes the month
                    startMonth={new Date(2020, 0)}
                    endMonth={new Date(2030, 11)}
                    locale={cs}
                    styles={{
                        root: { width: '100%' },
                        month: { width: '100%' },
                        months: { width: '100%', maxWidth: '100%' },
                        month_grid: { width: '100%' },
                        selected: { backgroundColor: 'red' },
                    }}
                />
                <div>
                    {selectedDateTime && !isNaN(new Date(selectedDateTime))  ? formatPostDate(selectedDateTime, ignoreTime ? 1 : 0) : "Vyberte den"}
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "clamp(0px,5vw,20px)" }}>
                    <button onClick={() => Finish()} style={{ fontSize: "15px", fontWeight: "700", backgroundColor: "#fdd046", borderStyle: "none", borderRadius: "10px", height: "30px", width: "30%" }}>{language.cancel}</button>
                    <button onClick={() => Finish(true)} style={{ fontSize: "15px", fontWeight: "700", backgroundColor: "#fdd046", borderStyle: "none", borderRadius: "10px", height: "30px", width: "30%" }}>{language.select}</button>
                    {/* <ButtonLayered type="submit" text={language.save} disabled={isSubmitting}/> */}
                </div>
            </div>
        </div>
        :
        null
    );
};

export default DatePickerModal;