import React, {useState, useContext} from 'react';
import ImageItem from './imageItem'; // Ensure the import path is correct
import AppContext from '../global/AppContext';
import { appPath } from '../global/utils';

const ImageItemsGroup = ({ images, postId }) => { // Destructure props correctly
    const { updateUserPrefs } = useContext(AppContext);
    const displayedImages = images.slice(0, 4);

    const openGallery = (index) => {
        const galleryImages = images.map(image => appPath() + `/postattachments/${image.postid}/${image.filename}`);
        updateUserPrefs("galleryImages", galleryImages);
        updateUserPrefs("galleryOpen", index);
    };

    const renderImages = () => (
        displayedImages.map((image, index) => {
            const isGreyedOutImage = images.length > 4 && index === 3; // Check if the image should be greyed out
            return (
                <div key={index} style={{ ...getImageTileStyle(index), borderRadius: 'clamp(0px,5vw,20px)' }}>
                    <ImageItem 
                        name={image.filename}
                        postId={postId}
                        index={index} 
                        fileType={image.fileType}
                        isLink={true}
                        aspect={displayedImages.length > 1 ? "1:1" : undefined}
                        greyedOut={isGreyedOutImage} // Pass the greyed out prop
                        imagesCount={images.length}
                        // onClick={isGreyedOutImage ? () => alert('This image is greyed out') : undefined} // Set onClick event
                        onClick={() => openGallery(index)} // Set onClick event
                    />
                </div>
            );
        })
    );

    // Function to determine styles based on the number of images
    const getContainerStyle = () => {
        if (images.length === 1) {
            return { display: "flex", justifyContent: "center" };
        } else if (images.length === 2) {
            return { 
                display: "flex", 
                justifyContent: "center", // Center both images
                alignItems: "center" // Ensure images are vertically centered
            };
        } else if (images.length === 3) {
            return {
                display: "flex", 
                height: "100%", // Maintain the full container height
            };
        } else {
            return { display: "flex", flexWrap: "wrap", justifyContent: "space-between" }; // Adjust for more than 3 images
        }
    };

    // Function to get styles for image tiles based on their index
    const getImageTileStyle = (index) => {
        if (images.length === 2) {
            return { 
                width: "50%", // Each image takes up almost half the container's width with minimal spacing
                height: "0", // Set height to zero to use padding for aspect ratio
                paddingBottom: "50%", // This creates a 1:1 aspect ratio
                overflow: "hidden", // Hide overflow to crop images
                position: "relative", // Position relative for absolute child positioning
            };
        }
        if (images.length === 3) {
            if (index === 0) {
                // Style for the big square image on the left
                return {
                    width: "100%", // Take up 2/3 of the container width
                    aspectRatio: "1",
                    position: "relative",
                    overflow: "hidden",
                };
            } else {
                // Style for the two smaller images on the right
                return {
                    width: "100%", // Take full width of the parent container
                    overflow: "hidden",
                    position: "relative",
                };
            }
        }
        if (images.length > 1) {
            return {
                width: "50%", // Each image takes up almost half the container's width with minimal spacing
                height: "0", // Set height to zero to use padding for aspect ratio
                paddingBottom: "50%", // This creates a 1:1 aspect ratio
                overflow: "hidden", // Hide overflow to crop images
                position: "relative", // Position relative for absolute child positioning
            };
        }
        return {}; // Default style for other cases
    };

    const renderedImages = renderImages(); // Store the rendered images

    return (
        <div style={{ marginTop: "clamp(0px, 2vw, 8px)", marginInline:"clamp(0px, 1vw, 20px)" }}>
            <div style={getContainerStyle()}>
                {images.length === 3 ? (
                    <>
                        {/* Container for the big image */}
                        <div style={{ width: "66.66%", position: "relative", aspectRatio:"1" }}>
                            {renderedImages[0]} 
                        </div>

                        {/* Container for the two smaller images */}
                        <div style={{ width: "33.33%", display: "flex", flexDirection: "column" }}>
                            {renderedImages.slice(1)} 
                        </div>
                    </>
                ) : images.length > 0 ? (
                    renderImages()
                ) : (
                    <p>No images to display.</p> // Message if no images are present
                )}
            </div>
        </div>
    );  
}

export default ImageItemsGroup; 