import React, { useContext, useEffect, useState } from 'react';
import AnimatedPage from './animatedPage';
import AppContext from '../global/AppContext';
import { SendRequest } from '../../webRequests';
import Posts from './posts';

const pageID = "GroupPosts";

const PageContent = () => {
  const { language } = useContext(AppContext);
  const {userState, userPrefs} = useContext(AppContext);
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const getGroupPosts = async () => {
      try {
        const response = await SendRequest('getgroupdata', { groupid: userPrefs.currentGroup.groupid, orgid: userPrefs.currentOrg.orgid });
        console.log('getgroupdata ', response);

        // Assuming response contains an array of posts
        if (response && response.groupdata) {
          setPosts(response.groupdata.posts);
        }
      } catch (error) {
        console.error('Error fetching wall data:', error);
      }
    };

    getGroupPosts();
  }, [userState]);

  return (
    <>
      <h3 style={{ marginBottom: "15px", paddingInline: "15px" }}>{language.wall}</h3>
      <div>
        <Posts posts={posts} />
      </div>
    </>
  );
};

const EnhancedPage = AnimatedPage(PageContent, pageID);
const components = {
  [pageID]: () => <EnhancedPage />
};
export default components[pageID];