import React, { useContext, useEffect, useState } from 'react';
import AnimatedPage from './animatedPage';
import AppContext from '../global/AppContext';
import UserPresence from '../elements/userPresence'; // Import the new component
import { formatPostDate } from '../global/utils';

const pageID = "Presence";

const PageContent = () => {
  const { language, userPrefs } = useContext(AppContext);
  const [groupedPresenceData, setGroupedPresenceData] = useState([]);

  const groupDataByUserAndDate = (data) => {
    const grouped = {};
  
    const extractDate = (datetime) => datetime.split(' ')[0];
  
    const ensureUserEntry = (date, userId, userInfo) => {
      if (!grouped[date]) grouped[date] = {};
      if (!grouped[date][userId]) {
        grouped[date][userId] = {
          absentNotes: [],
          presence: [],
          user: userInfo,
        };
      }
    };
  
    // Process absentNotes
    data.absentNotes.forEach((note) => {
      const date = extractDate(note.from);
      const userId = note.userid;
  
      const userInfo = {
        userid: note.userid,
        firstname: note.firstname,
        lastname: note.lastname,
        profileimagename: note.profileimagename,
      };
  
      ensureUserEntry(date, userId, userInfo);
      grouped[date][userId].absentNotes.push(note);
    });
  
    // Process presence
    data.presence.forEach((entry) => {
      const date = extractDate(entry.from);
      const userId = entry.userid;
  
      const userInfo = {
        userid: entry.userid,
        firstname: entry.firstname,
        lastname: entry.lastname,
        profileimagename: entry.profileimagename,
      };
  
      ensureUserEntry(date, userId, userInfo);
      grouped[date][userId].presence.push(entry);
    });
  
    // Now, sort users by firstname inside each date group
    Object.entries(grouped).forEach(([date, users]) => {
      const sortedUsers = Object.entries(users)
        .sort(([userIdA, userDataA], [userIdB, userDataB]) => {
          const nameA = userDataA.user.firstname.toLowerCase();
          const nameB = userDataB.user.firstname.toLowerCase();
          return nameA.localeCompare(nameB); // Sorting by first name
        })
        .reduce((result, [userId, userData], index) => {
          // Reassign users with a sorted index
          result[index] = userData;
          return result;
        }, {});
  
      // Replace the grouped[date] with sorted users
      grouped[date] = sortedUsers;
    });
  
    return grouped;
  };

  useEffect(() => {
    if (userPrefs.currentPage === pageID && userPrefs.presenceDisplayData) {
      const groupedData = groupDataByUserAndDate(userPrefs.presenceDisplayData);
      setGroupedPresenceData(groupedData);
      console.log("GroupedData:", groupedData);
    }
  }, [userPrefs.currentPage, userPrefs.presenceDisplayData]);

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <h3 style={{ marginBottom: "0px", paddingInline: "15px" }}>{language.presence}</h3>
      <h4 style={{ marginBlock: "0px", paddingInline: "15px" }}>{userPrefs.presenceDisplayData.title1}</h4>
      <h4 style={{ marginBlock: "0px", paddingInline: "15px" }}>{userPrefs.presenceDisplayData.title2}</h4>
      <div style={{textAlign:"center"}}>{`${formatPostDate(userPrefs.datePickerStorage.GroupBookDate, 1)}`}</div>

      {Object.entries(groupedPresenceData).map(([date, users]) => (
        <div key={date} style={{width: "100%" }}>
          {Object.entries(users).map(([userId, userData]) => (
            <UserPresence 
              key={userId} 
              userData={userData} 
              startHour={7} 
              endHour={17} 
            /> // Use the UserPresence component
          ))}
        </div>
      ))}
    </div>
  );
};

const EnhancedPage = AnimatedPage(PageContent, pageID);
const components = {
  [pageID]: () => <EnhancedPage />
};
export default components[pageID];