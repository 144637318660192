import React, { useContext } from 'react';
import AppContext from '../global/AppContext';
import UserAvatar from './userAvatar';
import './post.css';
import { formatPostDate } from '../global/utils';
import OpenPublicProfile from '../functions/openPublicProfile';
import { MaterialSymbol } from 'react-material-symbols';
import ButtonWithIcon from './buttonWithIcon';
import { SendRequest } from '../../webRequests';
import FileItem from './fileItem';
import ImageItem from './imageItem';
import ImageItemsGroup from './imageItemsGroup';
import Poll from './poll';
import PostConfirmation from './postConfirmation';

const Post = ({ postData, showChildIndicators = true }) => {
    const {language, userState, updateUserState, userPrefs, updateUserPrefs, showOptions, hideOptions} = useContext(AppContext);
    const openPublicProfile = OpenPublicProfile();

    console.log(postData);

    const applicationAttachments = postData?.attachments?.filter(attachment => 
        attachment.filetype.includes("application")
    );
    const imageAttachments = postData?.attachments?.filter(attachment => 
        attachment.filetype.includes("image")
    );

    const editPost = () => {
        
        const editPostData = { ...postData };
        
        editPostData.text = postData.textcontent;
        editPostData.importantIconChecked = postData.important == 1 ? true : false;
        delete editPostData.important;
        editPostData.requiresConfirmation = postData.requiresconfirmation == 1 ? true : false;
        delete editPostData.requiresconfirmation;
        editPostData.poll = postData?.polls?.length > 0 ? postData.polls[0] : {};
        delete editPostData.polls;
        editPostData.poll.pollTitle = editPostData.poll.polltitle;
        delete editPostData.poll.polltitle;
        editPostData.poll.pollOptions = editPostData.poll.polloptions;
        delete editPostData.poll.polloptions;
        delete editPostData.poll.pollvotes;
        editPostData.originalAttachments = editPostData.attachments;
        delete editPostData.attachments;
        if (editPostData.calendarevent.id != undefined){
            editPostData.calendarEvent = editPostData.calendarevent;
            editPostData.calendarEvent.eventdate = editPostData.calendarEvent.eventdate.replace(' ', 'T') + '.000Z';
            delete editPostData.calendarevent;
        }

        if (editPostData.requiresConfirmation){
            updateUserPrefs('infoMessage',language.editPostWarning);
            updateUserPrefs('displayInfoMessage', 1);
        }
        
        updateUserPrefs('newPost', editPostData);
        hideOptions();
        updateUserPrefs('currentPage', 'NewPost');
        console.log("editPost:" , editPostData);

    };

    const deletePost = async (post) => {

        try {
            const requestData = {
                postid: post.postid,
                postgroupid: post.groupid
            };

            updateUserPrefs('displayInfoLoading', 1);

            const responseData = await SendRequest('deletepost', requestData);
            console.log(responseData);
            
            const response = await SendRequest('getgroupdata', { groupid: post.groupid });
            updateUserPrefs("groupdata_" + post.groupid, response.groupdata);
            updateUserPrefs('refreshWallData', true);

            if (responseData.result === 'success'){
                hideOptions();
            }

            if (responseData.deletedevents){
                const updatedPostEvents = userState.calendarevents.postevents.filter(
                    event => event.postid !== post.postid
                  );
                  
                  // Update the postevents in userState
                  updateUserState('calendarevents', {
                    ...userState.calendarevents,
                    postevents: updatedPostEvents
                  });
            }

            updateUserPrefs('displayInfoLoading', 0);
            updateUserPrefs('infoMessage',language.postDeleteSuccess);
            updateUserPrefs('displayInfoMessage', 1);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const openOptions = (post) => {
    showOptions({
        message: (
            <>
                <div style={{paddingTop:"20px"}}>
                    {language.postOptions}<br/>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                    <ButtonWithIcon
                        text={language.postEdit}
                        leftIcon="edit"
                        rightIcon="arrow_forward_ios"
                        width={"90%"}
                        onClick={() => editPost()} // Pass the full org object and group to the modal
                        style={{ margin: '5px' }} // Add margin for spacing between buttons
                        />
                    <ButtonWithIcon
                        text={language.postDelete}
                        leftIcon="delete"
                        rightIcon="arrow_forward_ios"
                        width={"90%"}
                        onClick={() => deletePost(post)} // Pass the full org object and group to the modal
                        style={{ margin: '5px' }} // Add margin for spacing between buttons
                        />
                </div>
                <div style={{ width: '100%', paddingBottom:"20px"}}>
                </div>
            </>
        ),
        showIcon: false,
        buttons: []
    });
    }

    const postevents = userState?.calendarevents?.postevents?.filter(event => event.postid === postData.postid);

    return (
        <div className="CLR-BG-4 Shadow-2 Post">
            <div className="Post-Header">
                <UserAvatar onClick={() => openPublicProfile(postData.byuser.userid)} user={postData.byuser} userName={{firstname: postData.firstname, lastname: postData.lastname}}/>
                <div style={{marginLeft: "15px"}}>
                    <div style={{fontWeight:"500"}}>
                        {postData?.byuser?.firstname} {postData?.byuser?.lastname}
                    </div>
                    <div style={{fontSize:'clamp(0px, 3.2vw, 16px)'}}>
                        {postData.orgname} {postData.groupname}
                    </div>
                    <div style={{fontSize:'clamp(0px, 3.2vw, 16px)'}}>
                        {formatPostDate(postData.created_at)}
                    </div>
                    
                {/* Events */}
                    {postevents.map((event, index) => {
                        const eventDate = new Date(event.eventdate);
                        
                        // Format the date to "8. October 2024"
                        const formattedDate = eventDate.toLocaleDateString('cs-CZ', { 
                            day: 'numeric', 
                            month: 'long', 
                            year: 'numeric' 
                        });

                        // Format the time to "16:04"
                        const formattedTime = eventDate.toLocaleTimeString('cs-CZ', {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false // 24-hour format
                        });

                        return (
                            <div key={index} style={{color:"#40bbe1", fontSize:"clamp(0px, 2.5vw, 13px)", paddingTop:"clamp(0px, 1vw, 5px)"}}>
                                <div style={{height:"1px", width:"100%", background:"#40bbe1"}}></div>
                                {language.postHasEvent}&nbsp;
                                {`${formattedDate} ${formattedTime}`}
                            </div>
                        );
                    })}

                {/* Icons */}
                </div>
                <div className='Post-Options-Icons' style={{marginLeft:'auto', display:'flex', flexDirection:"column", gap:"clamp(0px,1vw,5px)"}}>
                    <div style={{display:"flex"}}>
                        {showChildIndicators && (
                            <>
                            {postData.foruser && Array.isArray(postData.foruser) && postData.foruser.map(foruser => (
                                <div key={foruser.userid} style={{marginLeft:'clamp(0px,1vw,5px)'}}>
                                    <UserAvatar 
                                        user={foruser}
                                        small={true}
                                        onClick={() => openPublicProfile(foruser.userid)}
                                        />
                                </div>
                            ))}
                            </>
                        )}
                        {postData.important === 1 && (
                            <div style={{fontSize:"clamp(0px,5vw,20px)", height:"clamp(0px, 7vw, 25px)", display:"flex", alignItems:"center"}}>
                                ⚠️
                            </div>                        
                        )}
                        {(postData.userid === userState.user.userid || userState.highroleorgs?.includes(postData.orgid)) && (
                            <div className='Post-Options-Icon' onClick={() => openOptions(postData)}>
                                <MaterialSymbol icon="more_horiz" />
                            </div>
                        )}
                    </div>
                    <div>
                        {postevents?.length > 0 && (
                            <div className = 'Post-Options-Icon' style={{backgroundColor:"#40bbe1", marginLeft:"auto"}} onClick={() => updateUserPrefs("currentPage", "Calendar")}>
                                <MaterialSymbol icon="calendar_today"/>
                            </div>                        
                        )}
                    </div>
                </div>
            </div>

            {/* Text content */}
            <div style={{whiteSpace: 'pre-line', paddingInline:"clamp(0px,3vw,10px)", wordBreak:"break-word"}}>
                <div style={{fontWeight:"700", fontSize:"clamp(0px, 4.5vw, 25px)", marginBottom:"clamp(0px,2vw,15px)", color:"#40bbe1"}}>
                    {postData.title}
                </div>
                {postData.textcontent}
            </div>

            {/* Poll */}
            {postData?.polls?.length > 0 && (
                postData.polls.map((pollData, index) => {
                    //console.log("Polldata:", pollData);
                    return <Poll key={index} pollData={pollData} postData={postData}/>;
                })
            )}

            {/* Document attachments */}
            {applicationAttachments?.length > 0 && (
                <div style={{ marginTop: "clamp(0px, 2vw, 8px)", paddingInline:"clamp(0px,3vw,10px)" }}>
                    <div style={{ fontWeight: "500" }}>
                        {language.documents}:
                    </div>
                    {applicationAttachments.map((attachment, index) => {
                        return (
                            <FileItem 
                            key={index} 
                            name={attachment.filename} 
                            fileType={attachment.filetype}
                            index={index}
                            isLink={true}
                            postId={postData.postid}
                            />
                        );
                    })}
                </div>
            )}

            {/* Confirmations */}
            {postData.requiresconfirmation > 0 && (
                <PostConfirmation postData={postData}/>
            )}

            {/* Image attachments */}
            {imageAttachments?.length > 0 && (
                <ImageItemsGroup images={imageAttachments} postId={postData.postid}/>
            )}
        </div>
    );
};

export default Post;