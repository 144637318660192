const ajaxprocessor = process.env.REACT_APP_AJAX_PROCESSOR;

export const SendFormRequest = async (requestType, form) => {
    const formData = new FormData(form);
  
    try {
      const response = await fetch(ajaxprocessor, {
        method: 'POST',
        credentials: 'include',
        body: formData
      });
  
      const data = await response.json();
      console.log('Form Request Response:', data);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
export const SendRequest = async (requestType, data = null) => {
    const requestData = data ? { ...data, requesttype: requestType } : { requesttype: requestType };
    //console.log("SendRequest " , requestType);
    try {
        const response = await fetch(ajaxprocessor, {
          method: 'POST',
          credentials: 'include',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(requestData)
        });

        var responseText = await response.text();
        try {
            var responseData = JSON.parse(responseText);
            //console.log('Response Data:\n', responseData);
            return responseData;
        } catch (jsonError) {
            console.log('Cannot parse JSON:\n', jsonError, '\n\nResponse Text:\n\n', responseText);
            return null;
        }
    } catch (error) {
        console.error('Error at SendRequest:', error);
    }
};

export const UploadRequest = async (requestType, files, params) => {
  const formData = new FormData();
  formData.append('requesttype', requestType);
  formData.append('inputdata', JSON.stringify(params));

  if (files instanceof File){
    console.log("Uploading single file:", files);
    formData.append('file', files);
  }
  else if (Array.isArray(files) && files.length > 0){
    console.log("Uploading array of files:", files);
    files.forEach((files) => {
      formData.append(`file[]`, files); // Use 'file[]' to indicate multiple files on the server-side
    });
    console.log("FormData Entries:", Array.from(formData.entries()));
  }
  else{
    console.warn("UploadRequest without files:", files);
  }

  console.log("Upload request for files: " , files);

  try {
      const response = await fetch(ajaxprocessor, {
          method: 'POST',
          credentials: 'include',
          body: formData,
      });

      const responseText = await response.text();
      try {
          const responseData = JSON.parse(responseText);
          return responseData;
      } catch (jsonError) {
          console.error('Error parsing JSON:', jsonError);
          console.log('Response Text:\n', responseText);
          return null;
      }
  } catch (error) {
      console.error('Error at uploadRequest:', error);
  }
};

// function sendFormRequest(requestType, form){
//     var data = $(form).serializeArray();
//     sendRequest(requestType, data);
// }

// function sendRequest(requestType, data = null) {

//     var reqdata = "requesttype=" + requestType;

//     if (data){
//         var serializedData = $.param(data);
//         var reqdata = reqdata + "&" + serializedData;
//     }

//     console.log("sendRequest: " + reqdata);

//     $.ajax({
//         type: "POST",
//         url: "./ajax/ajaxprocessor.php",
//         data: reqdata,
//         success: function(response) {
//             console.log("Ajax ajaxprocessor response", JSON.parse(response));
//         }
//     });
// }