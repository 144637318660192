import React, { useContext, useEffect } from 'react';
import AnimatedPage from './animatedPage';
import AppContext from '../global/AppContext';
import UserAvatar from '../elements/userAvatar';
import { MaterialSymbol } from 'react-material-symbols';
import { Button } from 'react-day-picker';
import ButtonWithIcon from '../elements/buttonWithIcon';
import { ReactComponent as SvgMyOrg } from '../../assets/icons/svg/Dia_moje_organizace.svg';
import { ReactComponent as SvgNewOrg } from '../../assets/icons/svg/Nova_Organizace.svg';
import { ReactComponent as SvgWall } from '../../assets/icons/svg/Dia_nastenka.svg';
import { ReactComponent as SvgCalendar } from '../../assets/icons/svg/Dia_kalendar.svg';
import { ReactComponent as SvgGroups } from '../../assets/icons/svg/Dia_moje_skupiny.svg';
import { ReactComponent as SvgAbsents } from '../../assets/icons/svg/Dia_omluvenky.svg';
import { ReactComponent as SvgNewPost } from '../../assets/icons/svg/Dia_novy_prispevek.svg';
import { ReactComponent as SvgGrpBk } from '../../assets/icons/svg/Dia_tridnice.svg';
import { ReactComponent as ModryPulkruh } from '../../assets/icons/svg/Modry_Pulkruh.svg';
import { ReactComponent as Domek } from '../../assets/icons/svg/Domek.svg';

const pageID = "HomeScreen";

const PageContent = () => {
  const { language, updateUserPrefs, userState, showOptions, hideOptions } = useContext(AppContext);

  const editProfile = (user) => {
    updateUserPrefs("editUser", user);
    updateUserPrefs("currentPage", "EditProfile");
    console.log("Edit");
  };

  const newOrg = () => {
    console.log("Creating new org");
    updateUserPrefs("currentPage", "NewOrganization");
  };

  const openPostModal = (org, group) => {
    hideOptions();
    updateUserPrefs("currentOrg", org);
    updateUserPrefs('currentGroup', group);
    // updateUserPrefs('postModalOpen', 1);
    updateUserPrefs("currentPage", "NewPost");
    updateUserPrefs("newPost", {});
  };

  const addPost = () => {
    // Create a map of organizations by their orgid for quick lookup
    const organizationsMap = userState.userorganizations.reduce((acc, org) => {
      acc[org.orgid] = org;
      return acc;
    }, {});
  
    // Group usergroups by organization and filter groups with role > 0
    const groupedByOrganization = userState.usergroups.reduce((acc, group) => {
      const org = organizationsMap[group.orgid]; // Find the corresponding organization based on orgid
      if (!org || group.role <= 0) {
        return acc; // Skip if no organization is found for the group or if role <= 0
      }
  
      // If the organization doesn't exist in the accumulator, initialize it
      if (!acc[org.orgname]) {
        acc[org.orgname] = { org, groups: [] }; // Store the full org object and an array of groups
      }
  
      // Push group details into the corresponding organization
      acc[org.orgname].groups.push(group);
  
      return acc;
    }, {});
  
    // Display the options modal with the grouped organizations and groups
    showOptions({
      message: (
        <>
          <div style={{paddingTop:"clamp(0px, 6vw, 30px)"}}>
            {language.selectPostGroup}<br/>
          </div>
          <div style={{ width: '100%', paddingBottom:"clamp(0px, 6vw, 30px)"}}>
            {Object.keys(groupedByOrganization).map(orgName => (
              <div key={orgName} style={{ marginBottom: '0px', textAlign: 'center' }}>
                <h4 style={{marginBottom:"0px"}}>{orgName}</h4>
                <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                  {groupedByOrganization[orgName].groups.map(group => (
                    <ButtonWithIcon
                      key={group.groupid}
                      text={group.groupname}
                      leftIcon="group"
                      rightIcon="arrow_forward_ios"
                      width={"90%"}
                      onClick={() => openPostModal(groupedByOrganization[orgName].org, group)} // Pass the full org object and group to the modal
                      style={{ margin: '5px' }} // Add margin for spacing between buttons
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </>
      ),
      showIcon: false,
      buttons: []
    });
  };

  const menuContainerStyle = {display:"flex", flexWrap:"wrap", width:"clamp(0px,100%, 800px)"};
  const menuItemStyle = {fontSize:"clamp(5px, 5vw, 20px)", width:"43%", marginLeft:"4.666%", marginBlock:"clamp(0px,1.5%,10px)", flexDirection:"column", justifyContent:"center", padding:"2%" };
  const menuItemLayout = "menuItem";

  return (    
    <div>
      <div style={{display: "flex", flexDirection:"column", alignItems: "center", position: "relative", marginBottom:"30px"}}>
      <ModryPulkruh className='Signature-Graphic-1'/>
      <Domek style={{width:"clamp(0px,45vw,200px)", marginTop:"clamp(0px,20vw,50px)"}}/>
      <div className='Spacer-Line-1' style={{marginBlock:"clamp(0px,5vw,20px)"}}></div>
        {/* <div>
          <h3 style={{ marginBlock: "0px", position: "relative", left: '50%', transform: "translate(-50%)", paddingBlock:"10px" }}>{language.homeScreenTitle}</h3>
        </div> */}


        {/* User Status */}
        <button style={{backgroundColor:"transparent", display:"flex", fontFamily:"unset", fontSize:"unset", fontWeight:"unset", textAlign:"left", alignItems:"center", borderStyle:"none", width:"90%", justifyContent:"space-between", marginBottom:"10px"}} onClick={() => editProfile(userState.user)}>
          <UserAvatar isButton={false} size={80} user={userState.user}/>
          <div>
            <div style={{fontWeight:"500"}}>
              {`${userState.user.firstname} ${(userState.user.lastname)}`}
            </div>
            <div  style={{fontSize:"12px"}}>
              {userState.user.usertype === 99 ? (
                <div style={{color:"#18af00"}}>
                  Developer
                  <br />
                </div>
              ) : ""}
              {userState.user.usertype === 98 ? (
                <div  style={{color:"#18af00"}}>
                  Administrátor
                  <br />
                </div>
              ) : ""}
              Tel: {userState.user.phonenumber} <br />
              {language.account}: {userState.user.username}
            </div>
          </div>
          <div style={{fontSize:"30px", height:"30px"}}>
            <MaterialSymbol 
              icon='edit'
              //fill={1}
              grade={25}  
              weight={300} 
              style={{color:"#369d41"}} 
              />
          </div>
        </button>


        {/* Menu */}
        <div style={menuContainerStyle}>

          {userState.highroleorgs.length > 0 && (
            // <ButtonWithIcon text={language.myOrganizations} leftIcon={'domain'} layout={menuItemLayout} style={menuItemStyle}
            <ButtonWithIcon text={language.myOrganizations} leftIcon={<SvgMyOrg className='Svg-Icon-Home'/>} layout={menuItemLayout} style={{...menuItemStyle, color:"#0076a9"}} 
            onClick={() => {
              if (userState.highroleorgs.length > 1){
                updateUserPrefs("currentPage", "MyOrganizations");
              }
              else {
                updateUserPrefs("currentOrg", userState.userorganizations.find(org => org.orgid === userState.highroleorgs[0]));
                updateUserPrefs("currentPage", "OrganizationDetail");
              }
            }}
            />
          )}

          {userState.usergroups.length > 0 && (
            userState.usergroups.length > 1 ? (
              <ButtonWithIcon
                text={language.myGroups}
                leftIcon={<SvgGroups className='Svg-Icon-Home' />}
                layout={menuItemLayout}
                style={{...menuItemStyle, color:"#80a917"}}
                onClick={() => updateUserPrefs("currentPage", "MyGroups")}
              />
            ) : (
              <ButtonWithIcon
                text={userState.usergroups[0].groupname}
                leftIcon={<SvgGroups className='Svg-Icon-Home' />}
                layout={menuItemLayout}
                style={{...menuItemStyle, color:"#80a917"}}
                onClick={() => {
                  const group = userState.usergroups[0];
                  updateUserPrefs("currentGroup", group);
                  updateUserPrefs("currentOrg", userState.userorganizations.find(org => org?.orgid === group.orgid));
                  updateUserPrefs("groupDetail", group);
                  updateUserPrefs("currentPage", "GroupDetail");
                }}
              />
            )
          )}

          <ButtonWithIcon text={language.wall} leftIcon={<SvgWall className='Svg-Icon-Home'/>} layout={menuItemLayout} style={{...menuItemStyle, color:"#c0338a"}}
            onClick={() => updateUserPrefs("currentPage", "Wall")}/>
            
          <ButtonWithIcon text={language.absentNotes} disabled={userState.childusers.length > 0 ? false : true} leftIcon={<SvgAbsents className='Svg-Icon-Home'/>} layout={menuItemLayout} style={{...menuItemStyle, color:"#ef7e1e", opacity: userState.childusers.length > 0 ? "1" : "0.3"}}
            onClick={() => {
              updateUserPrefs("absentNotesDisplayData", "parent");
              updateUserPrefs("currentPage", "AbsentNotesParentPanel")}
            } 
          />

          <ButtonWithIcon text={language.calendar} leftIcon={<SvgCalendar className='Svg-Icon-Home'/>} layout={menuItemLayout} style={{...menuItemStyle, color:"#823513"}}
            onClick={() => updateUserPrefs("currentPage", "Calendar")}/>

          {userState.usergroups.length > 0 && (
            <>
              <ButtonWithIcon text={language.newPost} leftIcon={<SvgNewPost className='Svg-Icon-Home'/>} layout={menuItemLayout} style={{...menuItemStyle, color:"#994995"}}
                onClick={() => addPost()}/>
              <ButtonWithIcon text={language.classBook} disabled={true} layout={menuItemLayout} leftIcon={<SvgGrpBk className='Svg-Icon-Home'/>} style={{...menuItemStyle, color:"#7da91e", opacity:"0.3"}}/>
            </>
          )}

        </div>


        {/* Admin */}
        {userState.user !== undefined && userState.user.usertype >= 98 && (
          <>
              {/* <h3 style={{ marginTop: "20px", marginBottom: "0px" }}>{language.administration}</h3> */}

              <ButtonWithIcon text={language.newOrg} leftIcon={<></>} rightIcon={<></>} width={"90%"} style={{marginTop:"10px", color:"#c3511f", width:"70%"}}
                onClick={() => newOrg()}/>
          </>
        )}


        {/* Children */}
        {userState.childusers && userState.childusers.length > 0 && (
            <>
                <h4 style={{ marginTop: "20px", marginBottom: "10px"}}>{language.myKids}</h4>
                
                <div className={`MyKidsList`} style={{display:"flex", justifyContent:"center"}}>
                    {userState.childusers && userState.childusers.map((child, index) => (
                        <div key={child.userid} style={{paddingInline:'10px'}}>
                            <UserAvatar user={child} size={60} onClick={() => editProfile(child)} displayName={true} />
                        </div>
                    ))}
                </div>
            </>
        )}
      </div>
    </div>
  );
};

const EnhancedPage = AnimatedPage(PageContent, pageID, 'currentPage', false, false);
const components = {
    [pageID]: () => <EnhancedPage />
  };
  export default components[pageID];