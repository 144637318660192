import React, { useContext, useState, useEffect } from 'react';
import AnimatedPage from './animatedPage';
import AppContext from '../global/AppContext';
import { SendRequest } from '../../webRequests';
import ProfileImage from '../elements/profileImage';
import ItemTextEditable from '../elements/itemTextEditable';
import ButtonWithIcon from '../elements/buttonWithIcon';

const pageID = "EditProfile";

const PageContent = () => {
    const { language, userPrefs, userState, navBack, updateUserState, updateUserPrefs } = useContext(AppContext);
    const [saveAvailable, setSaveAvailable] = useState(false);
    const [changedValues, setChangedValues] = useState({});
    const [user, setUser] = useState(userPrefs.editUser);

    useEffect(() => {
        setUser(userPrefs.editUser);
    }, [userPrefs.editUser]);

    const handleFinishEditing = (variableName) => (newValue) => {
        setChangedValues(prevData => ({
            ...prevData,
            [variableName]: newValue  // Add or update the field
        }));
        setSaveAvailable(true);  // Enable the save button
    };

    const save = (variableName) => async (newValue) => {
        try {
            
            const requestData = {
                userid: user.userid,
                changedvalues: changedValues,
            };

            console.log("requestData:", requestData);

            updateUserPrefs("displayInfoLoading", 1);
            const responseData = await SendRequest("updateuser", requestData);
            updateUserPrefs("displayInfoLoading", 0);

            // Update userState based on the current user or child user
            if (user.userid === userState.user.userid) {
                // Update the current user
                const updatedUser = { ...userState.user, ...changedValues };
                updateUserState('user', updatedUser);
                updateUserPrefs('editUser', updatedUser);
            } else {
                // Update a child user
                const updatedChildUsers = userState.childusers.map(childUser => 
                    childUser.userid === user.userid ? { ...childUser, ...changedValues } : childUser
                );
                updateUserState('childusers', updatedChildUsers);

                const updatedChildUser = updatedChildUsers.find(childUser => childUser.userid === user.userid);
                if (updatedChildUser) {
                    updateUserPrefs('editUser', updatedChildUser);
                }
            }

            setChangedValues({});
            console.log("Save result:", responseData);
    
            updateUserPrefs("infoMessage", language.done);
            updateUserPrefs("displayInfoMessage", 1);
            setSaveAvailable(false);  // Disable the save button after saving
        } catch (error) {
            console.error('Error saving user data:', error);
        }
    };

    // const oldSave = (variableName) => async (newValue) => {
    //     try {
    //         const responseData = await SendRequest('updateuserproperty', { userid:user.userid, property: variableName, value: newValue });
    //         console.log("Edit result:", responseData);
    //         console.log("Edit user:", user);
    //         if (responseData.result === "success"){
    //             console.log("EditProfile Updating state:", variableName , " to " , newValue);

    //             // Standard update
    //             if (user === userState.user){
    //                 console.log('Standard update');
    //                 var userData = userState.user;
    //                 userData[variableName] = newValue;
    //                 updateUserState('user', userData);
    //             }
    //             // Child update
    //             else {
    //                 console.log("looking for", user, "in", userState.childusers );
    //                 var childIndex = userState.childusers.indexOf(user);
    //                 if (childIndex !== -1){
    //                     userData = userState.childusers[childIndex];
    //                     userData[variableName] = newValue;
    //                     // updateUserState('user', userData);
    //                     console.log("Child update");
    //                 }
    //                 else{
    //                     console.log("User not found");
    //                 }
    //             }

    //             updateUserPrefs('editUser', userData);

    //             updateUserPrefs('infoMessage', 'Hodnota uložena');
    //             updateUserPrefs('displayInfoMessage', 1);
    //         }
    //     } catch (error) {
    //         console.error('Error updating user property:', error);
    //     }
    // };

    return (
        <>
            {/* <h2>{language.myProfile}</h2> */}
                <div style={{paddingBottom:'30px'}}></div>

                <ProfileImage user={user}/>

                <div className='Group-Label'>{language.name}</div>
                
                <ItemTextEditable 
                    label={language.firstName} 
                    value={user.firstname} 
                    onFinishEditing={handleFinishEditing("firstname")} 
                />

                <ItemTextEditable 
                    label={language.lastName} 
                    value={user.lastname} 
                    onFinishEditing={handleFinishEditing("lastname")} 
                />

                <div className='Group-Label' style={{paddingTop:'20px'}}>{language.address}</div>

                <ItemTextEditable 
                    label='Stát' 
                    value={user.state} 
                    onFinishEditing={handleFinishEditing("state")} 
                />

                <ItemTextEditable 
                    label='Město' 
                    value={user.city} 
                    onFinishEditing={handleFinishEditing("city")} 
                />

                <ItemTextEditable 
                    label='Ulice' 
                    value={user.street} 
                    onFinishEditing={handleFinishEditing("street")} 
                />

                <ItemTextEditable 
                    label='Číšlo popisné' 
                    value={user.streetnumber} 
                    onFinishEditing={handleFinishEditing("streetnumber")} 
                />

                <div className='Group-Label' style={{paddingTop:'20px'}}>{language.contacts}</div>

                <ItemTextEditable 
                    label='Telefon' 
                    value={user.phonenumber}
                    onFinishEditing={handleFinishEditing("phonenumber")} 
                />

                <ItemTextEditable 
                    label='E-mail' 
                    value={user.emailcontact}
                    onFinishEditing={handleFinishEditing("emailcontact")} 
                />

                <div style={{display:"flex", justifyContent:"center", paddingBlock:"clamp(0px, 3vw, 20px)", marginBottom:"20px"}}>
                    <ButtonWithIcon text={language.save} leftIcon={"save"} style={{width:"90%"}} disabled={!saveAvailable} onClick={save()}/>
                </div>
        </>
    );
};

const EnhancedPage = AnimatedPage(PageContent, pageID);
const components = {
    [pageID]: () => <EnhancedPage />
  };
export default components[pageID];