export function saveDataToIDB(databaseName, version, objectStoreName, key, value) {
    var request = indexedDB.open(databaseName, version);

    //console.log("saving key:" , key, " value:" , value);
    request.onerror = function(event) {
        console.error('Error opening database:', event.target.error);
    };

    request.onupgradeneeded = function(event) {
        var db = event.target.result;
        //console.log("IDB: upgrade executed");
        if (!db.objectStoreNames.contains(objectStoreName)) {
            db.createObjectStore(objectStoreName);
            //console.log("Object store created:", objectStoreName);
        }
    };

    request.onsuccess = function(event) {
        try {
            var db = event.target.result;

            if (!db.objectStoreNames.contains(objectStoreName)) {
                console.error(`Error: Object store ${objectStoreName} not found`);
                return;
            }
    
            var transaction = db.transaction([objectStoreName], 'readwrite');
            var objectStore = transaction.objectStore(objectStoreName);
    
            var addRequest = objectStore.put(value, key);
    
            addRequest.onsuccess = function(event) {
                //console.log('Data saved successfully');
            };
    
            addRequest.onerror = function(event) {
                console.error('Error saving data:', event.target.error);
            };
    
            transaction.oncomplete = function(event) {
                //console.log('Transaction completed.');
            };
    
            transaction.onerror = function(event) {
                console.error('Transaction error:', event.target.error);
            };
        } catch (error) {
            console.error('Transaction error:', error.message);
        }
    };
}

export function loadDataFromIDB(databaseName, version, objectStoreName) {
    return new Promise(function(resolve, reject) {
        var request = indexedDB.open(databaseName, version);

        request.onupgradeneeded = function(event) {
            var db = event.target.result;
            //console.log("IDB: upgrade executed");
            if (!db.objectStoreNames.contains(objectStoreName)) {
                db.createObjectStore(objectStoreName);
                //console.log("Object store created:", objectStoreName);
            }
        };

        request.onerror = function(event) {
            console.error('Error opening database:', event.target.error);
        };

        request.onsuccess = function(event) {
            try {
                var db = event.target.result;
                if (!db.objectStoreNames.contains(objectStoreName)) {
                    reject('IDB: Object store not found');
                    return;
                }
                var transaction = db.transaction([objectStoreName], 'readonly');
                var objectStore = transaction.objectStore(objectStoreName);
                var cursorRequest = objectStore.openCursor();

                var storage = {};
                
                cursorRequest.onsuccess = function(event) {
                    var cursor = event.target.result;
                    if (cursor) {
                        storage[cursor.key] = cursor.value;
                        cursor.continue();
                    } else {
                        resolve(storage);
                    }
                };

                cursorRequest.onerror = function(event) {
                    console.error('Error iterating through object store:', event.target.error);
                    reject(event.target.error);
                };

                transaction.oncomplete = function(event) {
                    //console.log('Transaction completed.');
                };

                transaction.onerror = function(event) {
                    console.error('Transaction error:', event.target.error);
                    reject(event.target.error);
                };
            } catch (error) {
                console.error('Transaction error:', error.message);
            }
        };
    });
}

export function loadDataFromIDBWithKey(databaseName, version, objectStoreName, key) {
    return new Promise(function(resolve, reject) {
        var request = indexedDB.open(databaseName, version);

        request.onupgradeneeded = function(event) {
            var db = event.target.result;
            //console.log("IDB: upgrade executed");
            if (!db.objectStoreNames.contains(objectStoreName)) {
                db.createObjectStore(objectStoreName);
                //console.log("Object store created:", objectStoreName);
            }
        };

        request.onerror = function(event) {
            console.error('Error opening database:', event.target.error);
        };

        request.onsuccess = function(event) {
            try {
                var db = event.target.result;
                if (!db.objectStoreNames.contains(objectStoreName)) {
                    reject('IDB: Object store not found');
                    return;
                }
                var transaction = db.transaction([objectStoreName], 'readonly');
                var objectStore = transaction.objectStore(objectStoreName);
    
                var getRequest = objectStore.get(key);
    
                getRequest.onsuccess = function(event) {
                    var data = event.target.result;
                    if (data) {
                        resolve(data.value); // Resolve with the direct value
                    } else {
                        //console.log('Data not found for key:', key);
                        resolve(null);
                    }
                };
    
                getRequest.onerror = function(event) {
                    console.error('Error retrieving data:', event.target.error);
                    reject(event.target.error);
                };
    
                transaction.oncomplete = function(event) {
                    //console.log('Transaction completed.');
                };
    
                transaction.onerror = function(event) {
                    console.error('Transaction error:', event.target.error);
                    reject(event.target.error);
                };
            } catch (error) {
                console.error('Transaction error:', error.message);
            }
        };
    });
}


export function deleteObjectStoreFromIDB(databaseName, version, objectStoreName) {
    var key = 'Key';
    
    var request = indexedDB.open(databaseName, version);
    
    request.onerror = function(event) {
        console.error('Error opening database:', event.target.error);
    };
    
    request.onsuccess = function(event) {
        var db = event.target.result;
        var transaction = db.transaction([objectStoreName], 'readwrite');
        var objectStore = transaction.objectStore(objectStoreName);
        
        var deleteRequest = objectStore.delete(key);
        
        deleteRequest.onsuccess = function(event) {
            //console.log('Data deleted successfully for key:', key);
        };
        
        deleteRequest.onerror = function(event) {
            console.error('Error deleting data:', event.target.error);
        };
        
        transaction.oncomplete = function(event) {
            //console.log('Transaction completed.');
        };
        
        transaction.onerror = function(event) {
            console.error('Transaction error:', event.target.error);
        };
    };
}

export function deleteIDB(databaseName){
    //var deleteRequest = indexedDB.deleteDatabase(databaseName);
}
