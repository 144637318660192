import React, { Component, useContext } from 'react';
import ButtonWithIcon from '../elements/buttonWithIcon';
import { SendRequest } from '../../webRequests';
import AppContext from './AppContext'; // Make sure the path is correct

class ErrorBoundary extends Component {

  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = { 
      errors: [], // Store multiple errors
      errorsSent: false // Track if errors have been sent
    };
  }

  componentDidCatch(error, errorInfo) {
    // Append the new error to the existing list of errors
    this.setState((prevState) => ({
      errors: [
        ...prevState.errors,
        { error, errorInfo, stack: error.stack, errorMessage: error.message }
      ]
    }));

    // Optionally log to an external service or the console
    console.error('ErrorBoundary caught an error:', error, "\nStackTrace\n\n", errorInfo);
  }

  async sendErrors(errors) {
    console.log(errors);

    try {
      // Send the errors to the server
      const responseData = await SendRequest('logerror', { errors: errors });

      console.log(responseData);

      // If successful, update the state to hide the button
      this.setState({ errorsSent: true });
    } catch (error) {
      console.error('Failed to send errors:', error);
    }
  }

  async resetApp(errors) {
    console.log('Reset');
    const { resetUserPrefs } = this.context;
    resetUserPrefs();
  }

  render() {
    const { errors, errorsSent } = this.state;

    if (errors.length > 0) {
      return (
        <div>
          <h2>Ooops, něco se pokazilo.</h2>

          <div style={{ paddingBottom: "20px" }}>
            <ButtonWithIcon 
              text={"Resetovat aplikaci"} 
              leftIcon={'error'} 
              style={{ marginInline: "auto", marginBlock: "20px" }}
              onClick={() => this.resetApp(errors)} 
            />
          </div>

          <details style={{ whiteSpace: 'pre-wrap', marginTop: '10px' }}>
            <summary><strong>View Error Details ({errors.length} errors)</strong></summary>
            {errors.map((err, index) => (
              <div key={index} style={{ marginBottom: '20px' }}>
                <p><strong>{err.error.message}</strong></p>
                <pre>{err.stack}</pre> {/* Full stack trace */}
                <pre>{err.errorInfo.componentStack}</pre> {/* Component stack trace from React */}
              </div>
            ))}
            {!errorsSent && (
              <div style={{ paddingBottom: "20px" }}>
                <ButtonWithIcon 
                  text={"Odeslat zprávu o chybách"} 
                  leftIcon={'error'} 
                  style={{ marginInline: "auto", marginBlock: "20px" }}
                  onClick={() => this.sendErrors(errors)} 
                />
              </div>
            )}
          </details>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;