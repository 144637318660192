import React, { useContext, useState, useEffect, useRef } from 'react';
import AnimatedPage from './animatedPage';
import AppContext from '../global/AppContext';
import DatePicker from '../elements/datePicker';
import ButtonUniversal from '../elements/buttonUniversal';
import AppendedToDocument from '../elements/appendedToDocument';
import GroupBookEntry from '../elements/groupBookEntry';
import { SendRequest } from '../../webRequests';

const pageID = "GroupBook";

const PageContent = () => {

    const { language, userPrefs, updateUserPrefs, userState, showOptions, hideOptions } = useContext(AppContext);
    const [groupUsers, setGroupUsers] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [changed, setChanged] = useState(false);
    const [presenceData, setPresenceData] = useState(null);

    const currentUserInGroup = groupUsers.groupusers?.find(user => user.userid === userState.user.userid);
    const userRole = currentUserInGroup?.role;

    const presenceDisplay = useRef(null);
    const absentNotesDisplay = useRef(null);
    const entryDate = useRef(null);
    const morningEntry = useRef(null);
    const eveningEntry = useRef(null);
    const auditEntry = useRef(null);

    const textAreaRef = useRef(null);

    const noEntryMessage =
        <div style={{ color: "red" }}>
            {language.noEntry}
        </div>
        ;

    useEffect(() => {
        async function getPresenceData() {
            // Format the date to DB compatible format

            const formattedDate = selectedDate.toISOString().split('T')[0];
            console.log("Date changed ", formattedDate);

            const requestData = {
                groupid: userPrefs.currentGroup.groupid,
                orgid: userPrefs.currentGroup.orgid,
                date: formattedDate,
            };

            try {
                const responseData = await SendRequest('getgroupbook', { requestdata: requestData });
                console.log("getgroupbook:", responseData);
                console.log("responseData.groupbookentry.morning:", responseData.groupbookentry.morning);

                entryDate.current.updateContent(<><div style={{ display: "flex", justifyContent: "space-between" }}>{formatFullDate2(selectedDate)} {responseData.result == "none" && (noEntryMessage)}</div></>);
                morningEntry.current.updateContent(responseData.result.morning);
                eveningEntry.current.updateContent(responseData.result.evening);
                auditEntry.current.updateContent(responseData.result.audit);

                setPresenceData({
                    title1: userPrefs.currentOrg.orgname,
                    title2: userPrefs.currentGroup.groupname,
                    absentNotes: responseData.absentNotes.map((note) => ({
                        ...note, // Spread the existing note properties
                        child: {
                            userid: note.userid,
                            firstname: note.firstname,
                            lastname: note.lastname,
                            profileimagename: note.profileimagename
                        }
                    })),
                    presence: responseData.presence,
                });

                const MORNING_START = "08:00:00";
                const MORNING_END = "12:00:00";
                const EVENING_START = "12:00:00";
                const EVENING_END = "16:00:00";
                const ARRIVAL_TOLERANCE_MINUTES = 15;
                const MINIMAL_PRESENCE_MORNING_MINUTES = 210;
                const MINIMAL_PRESENCE_EVENING_MINUTES = 210;

                function parseTimeToMinutes(time) {
                    const [hours, minutes, seconds] = time.split(":").map(Number);
                    return hours * 60 + minutes; // Convert hours and minutes to total minutes
                }

                function addMinutesToTime(time, minutes) {
                    const totalMinutes = parseTimeToMinutes(time) + minutes;
                    const hours = Math.floor(totalMinutes / 60).toString().padStart(2, "0");
                    const mins = (totalMinutes % 60).toString().padStart(2, "0");
                    return `${hours}:${mins}:00`;
                }

                function getOverlapInMinutes(startTime, endTime, rangeStart, rangeEnd) {
                    const start = Math.max(parseTimeToMinutes(startTime), parseTimeToMinutes(rangeStart));
                    const end = Math.min(parseTimeToMinutes(endTime), parseTimeToMinutes(rangeEnd));
                    return Math.max(0, end - start); // Return overlap in minutes or 0 if no overlap
                }

                const presenceSummary = {
                    morning: new Set(),
                    evening: new Set()
                };

                responseData.presence.forEach(entry => {
                    // Apply tolerance to the start and end times
                    const adjustedFrom = addMinutesToTime(entry.from.split(" ")[1], -ARRIVAL_TOLERANCE_MINUTES);
                    const adjustedTo = addMinutesToTime(entry.to.split(" ")[1], ARRIVAL_TOLERANCE_MINUTES);

                    // Calculate morning presence
                    const morningMinutes = getOverlapInMinutes(adjustedFrom, adjustedTo, MORNING_START, MORNING_END);
                    if (morningMinutes >= MINIMAL_PRESENCE_MORNING_MINUTES) {
                        presenceSummary.morning.add(entry.userid);
                    }

                    // Calculate evening presence
                    const eveningMinutes = getOverlapInMinutes(adjustedFrom, adjustedTo, EVENING_START, EVENING_END);
                    if (eveningMinutes >= MINIMAL_PRESENCE_EVENING_MINUTES) {
                        presenceSummary.evening.add(entry.userid);
                    }
                });

                // Convert sets to arrays for output
                presenceSummary.morning = Array.from(presenceSummary.morning);
                presenceSummary.evening = Array.from(presenceSummary.evening);

                console.log(presenceSummary);

                const presenceResultDisplay =
                    <>
                        <div>
                            <div style={{ display: "flex" }}>
                                {language.morning}:<div style={{ marginInline: "clamp(0px,1vw,10px)", color: "#00bb00", fontWeight: "700" }}>{presenceSummary.morning.length}</div> {language.children}
                            </div>
                            <div style={{ display: "flex" }}>
                                {language.evening}:<div style={{ marginInline: "clamp(0px,1vw,10px)", color: "#00bb00", fontWeight: "700" }}>{presenceSummary.evening.length}</div> {language.children}
                            </div>
                        </div>
                    </>
                    ;
                presenceDisplay.current.updateContent(presenceResultDisplay);

                const absentNotesResultDisplay =
                    <>
                        <div>
                            <div style={{ display: "flex" }}>
                                {language.absentNotesThisDay}:<div style={{ marginInline: "clamp(0px,1vw,10px)", color: "#00bb00", fontWeight: "700" }}>{responseData.absentNotes.length}</div> {language.children}
                            </div>
                        </div>
                    </>
                    ;
                absentNotesDisplay.current.updateContent(absentNotesResultDisplay);



            } catch (error) {
                console.error("Error fetching group book data:", error);
            }
        }

        getPresenceData();
    }, [userPrefs.currentGroup.groupid, selectedDate]);

    const openTextInput = (ref) => {

        showOptions({
            message: (
                <>
                    <div style={{ marginTop: "clamp(0px,5vw,25px)", marginBottom: "clamp(0px,2vw,10px)" }}>
                        {ref.current.entryName}
                    </div>
                    <form onSubmit={(e) => entryChanged(e, ref, textAreaRef)}>
                        <textarea
                            ref={textAreaRef}
                            className='RoundedInput Input-Outline'
                            required={true}
                            defaultValue={ref.current.getContent()}
                            style={{ width: "100%", marginBottom: "clamp(0px,2vw,10px)", backgroundColor: "white", overflow: "auto", maxHeight: "80vh" }}
                            onChange={handleTextareaChange}
                        />
                        <ButtonUniversal
                            textColor={"white"}
                            backgroundColor={"#40bbe1"}
                            text={language.done}
                            preventDefault={false} // Pass the add button functionality
                            style={{ marginLeft: "auto", width: "30%", justifyContent: "center" }}
                        />
                    </form>
                </>
            ),
            showIcon: false
        });

        setTimeout(() => {
            handleTextareaChange(); // Trigger adjustment manually
        }, 100);
    };

    const handleTextareaChange = () => {
        // Adjust the textarea height dynamically
        if (textAreaRef.current) {
            textAreaRef.current.style.height = 'auto';
            if (textAreaRef.current.scrollHeight > textAreaRef.current.clientHeight) {
                textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
            }
        }
    };

    const entryChanged = async (event, ref, textAreaRef) => {
        event.preventDefault();

        console.log("Changed ", ref.current.entryName, " to ", textAreaRef.current.value);
        ref.current.updateContent(textAreaRef.current.value);

        if (ref.current.getContent() != textAreaRef.current.value)
            setChanged(true);

        hideOptions();
    };

    const changeDate = (unit, direction) => {
        setSelectedDate(prevDate => {
            const newDate = new Date(prevDate);
            if (unit === 'day') newDate.setDate(newDate.getDate() + direction);
            if (unit === 'month') newDate.setMonth(newDate.getMonth() + direction);

            setTimeout(() => {
                const datePickerStorage = userPrefs?.datePickerStorage || {};
                const updatedDatePickerStorage = {
                    ...datePickerStorage, // Copy existing datePickerStorage (or empty object)
                    GroupBookDate: newDate, // Update GroupBookDate
                };
    
                // Use the updater function to update userPrefs
                updateUserPrefs("datePickerStorage", updatedDatePickerStorage);
            }, 0);

            return newDate;
        });
        setChanged(false);
    };

    const saveEntry = async () => {
        console.log("saveEntry:");
        const formattedDate = selectedDate.toISOString().split('T')[0];

        const requestData = {
            groupid: userPrefs.currentGroup.groupid,
            orgid: userPrefs.currentGroup.orgid,
            date: formattedDate,
            morning: morningEntry.current.getContent() ?? "",
            evening: eveningEntry.current.getContent() ?? "",
            audit: auditEntry.current.getContent() ?? "",
        };

        try {
            const responseData = await SendRequest('savegroupbookentry', { requestdata: requestData });
            console.log("savegroupbookentry:", responseData);
            setChanged(false);
        } catch (error) {
            console.error("Error fetching group book data:", error);
        }
    };

    const displayPresence = async () => {
        updateUserPrefs("presenceDisplayData", presenceData);
        updateUserPrefs("currentPage", "Presence");
    };

    const displayAbsentNotes = async () => {
        updateUserPrefs("absentNotesDisplayData", presenceData.absentNotes);
        updateUserPrefs("currentPage", "AbsentNotes");
    };

    // Date picker
    const openCalendarPicker = (dateStoreKey) => {
        const datePickerData = { initDate : userPrefs?.datePickerStorage?.[`${dateStoreKey}`] == null ? new Date() : userPrefs?.datePickerStorage?.[`${dateStoreKey}`], dateStoreKey : dateStoreKey }
        updateUserPrefs("displayDatePickerModal", datePickerData)
    }

    useEffect(() => {
        if (userPrefs.datePickerStorage?.GroupBookDate){
            setSelectedDate(userPrefs.datePickerStorage.GroupBookDate);
        }
    }, [userPrefs.datePickerStorage]);

    // Formatting function for the header display date
    const formatFullDate = (date) =>
        `${new Intl.DateTimeFormat('en-US', { day: 'numeric' }).format(date)}. ${new Intl.DateTimeFormat('cs-CZ', { month: 'long' }).format(date)} ${date.getFullYear()}`;

    const formatFullDate2 = (date) =>
        `${new Intl.DateTimeFormat('cs-CZ', { day: 'numeric', month: 'long', year: 'numeric' }).format(date)}`;

    const saveButton =
        <ButtonUniversal
            text={language.save}
            backgroundColor={changed ? "orange" : "#ffa5005e"}
            onClick={changed ? () => saveEntry() : null}
            style={{ zIndex: 1, color: "white", borderRadius: "clamp(0px,5vw,20px)", paddingInline: "clamp(0px,10vw,30px)", position: "fixed", top: "calc(var(--viewport-height) - (50px + clamp(0px,4vw,20px))", right: "clamp(0px,4vw,20px)", transform: "translate(0px,-100%)", opacity: userPrefs.currentPage == pageID ? 1 : 0 }}
        />
        ;

    return (
        <>
            <AppendedToDocument className="Save-Group-Book-Floating-Button">{saveButton}</AppendedToDocument>

            <DatePicker selectedDate={selectedDate} onChangeDate={changeDate} onOpenCalendar={()=>openCalendarPicker("GroupBookDate")} mode={1} />

            <div className='CLR-BG-NavBar Shadow-2 Page-Header' style={{ width: "100%", textAlign: "center", backgroundColor: "white", color: "#464646" }}>
                <h2 style={{ marginTop: "0px", marginBottom: "0px", color: "rgb(120, 120, 120)" }}>{language.groupBook}</h2>
                <h4 style={{ marginTop: "0px", marginBottom: "0px" }}>{userPrefs?.currentGroup?.groupname || "Group Not Found"}</h4>
                <h5 style={{ marginTop: "0px", marginBottom: "0px", color: "rgb(120, 120, 120)" }}>{userPrefs?.currentOrg?.orgname || "Organization Not Found"}</h5>

            </div>

            <div className='Shadow-2' style={{ width: "100%", textAlign: "center", backgroundColor: "white", color: "#464646", paddingBlock: "clamp(0px,2vw,10px)", marginTop: "clamp(0px,4vw,20px)" }}>

                <GroupBookEntry
                    ref={entryDate}
                    entryName={language.day}
                    entryContent={formatFullDate2(selectedDate)}
                />

                <GroupBookEntry
                    ref={presenceDisplay}
                    entryName={language.childrenPresenceLog}
                    entryContent={""}
                    contentStyle={{ display: "flex" }}
                    buttonText={language.detail}
                    buttonIcon={"zoom_in"}
                    onClick={presenceData ? () => displayPresence() : null}
                />

                <GroupBookEntry
                    ref={absentNotesDisplay}
                    entryName={language.absentNotes}
                    entryContent={"Omluveno X dětí"}
                    contentStyle={{ display: "flex" }}
                    buttonText={language.detail}
                    buttonIcon={"zoom_in"}
                    onClick={presenceData ? () => displayAbsentNotes() : null}
                />

                <GroupBookEntry
                    ref={morningEntry}
                    entryName={language.morningEducationalActivities}
                    entryContent={"No value"}
                    buttonText={language.edit}
                    onClick={() => openTextInput(morningEntry)}
                />

                <GroupBookEntry
                    ref={eveningEntry}
                    entryName={language.afternoonEducationalActivities}
                    entryContent={"No value"}
                    buttonText={language.edit}
                    onClick={() => openTextInput(eveningEntry)}
                />

                <GroupBookEntry
                    ref={auditEntry}
                    entryName={language.auditLog}
                    entryContent={"No value"}
                    buttonText={language.edit}
                    onClick={() => openTextInput(auditEntry)}
                />

            </div>
            <div style={{ height: "clamp(0px,16vw,80px)" }}></div>
        </>
    );
};

const EnhancedPage = AnimatedPage(PageContent, pageID);
const components = {
    [pageID]: () => <EnhancedPage className={'CLR-BG-5'} />
};
export default components[pageID];