import { MaterialSymbol } from "react-material-symbols";
import './buttonWithIconUser.css';
import UserAvatar from "./userAvatar";
import OpenPublicProfile from '../functions/openPublicProfile';

const ButtonWithIconUser = ({ onClick, user, avatarSubTitle, userTitle, leftIcon, rightIcon, className, disabled, width, style }) => {
    const openPublicProfile = OpenPublicProfile();

    const combinedStyle = {
        position:"relative",
        width, 
        ...style // Spread the style prop to apply additional styles
    };

    return (
        <button
            onClick={() => openPublicProfile(user?.userid)}
            className={`ButtonWithIconUser ${className ? className : ""}`}
            disabled={disabled}
            style={combinedStyle} // Apply the width prop here
        >
            <div style={{position:"absolute", left:"clamp(0px,0.5vw,5px)",top:"5%", height:"80%", aspectRatio:"1"}}>
                <UserAvatar
                    user={user}
                    isButton={false}
                    style={{height:"100%"}}
                    outlineStyle={{borderRadius:"5px", height:"100%", width:"100%", boxShadow:"none"}}
                />
            </div>
            <div style={{position:"absolute", left:"0.5vw",bottom:"1%", fontSize:"30%"}}>
                {avatarSubTitle}
            </div>


            <MaterialSymbol 
                icon={leftIcon ? leftIcon : 'person'}
                //fill={1}
                grade={25}  
                weight={300} 
                className="LeftIcon"
                style={{opacity:"0"}}
            />

            <div style={{position:"relative"}}>
                <div>
                    {user?.firstname} {user?.lastname}
                </div>
                <div style={{position:"absolute", fontSize:"clamp(0px,3vw,12px)", left:"50%", transform:"translate(-50%,0px)", whiteSpace:"nowrap", color:"gray" }}>
                    {userTitle}
                </div>
            </div>

            <MaterialSymbol 
                icon={rightIcon ? rightIcon : 'arrow_forward_ios'}
                //fill={1}
                grade={25}  
                weight={300} 
                className="RightIcon"
            />

        </button>
    );
};

export default ButtonWithIconUser;