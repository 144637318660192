import React, { useContext, useState } from 'react';
import AnimatedPage from './animatedPage';
import AppContext from '../global/AppContext';
import { SendRequest } from '../../webRequests';
import ItemProfileEditable from '../elements/itemTextEditable';

const pageID = "NewGroup";

const PageContent = () => {
    const { language, userPrefs, updateUserPrefs, userState, getUserState } = useContext(AppContext);
    const orgId = userPrefs.currentOrg.orgid;
    const [groupName, setGroupName] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();

        const groupdata = { orgid: currentOrg.orgid, grouptype: currentOrg.orgtype, groupname: groupName };
        console.log("Create group for org", currentOrg?.orgname , " data:" , groupdata);
        const responseData = await SendRequest('creategroup', { newgroupdata:groupdata });
        console.log("createGroup:", responseData);
        getUserState();
        updateUserPrefs("currentPage", "OrganizationGroups");
    };

    const handleCancel = async (event) => {
        updateUserPrefs("currentPage", "OrganizationDetail");
    };
  
    // const currentOrg = userState.userorganizations.find(org => org.orgid === orgId);
    const currentOrg = userPrefs.currentOrg;
  
    return (
          <>
          <form onSubmit={handleSubmit}>
            <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                    <h2>{currentOrg?.orgname || "Organization Not Found"}</h2>
                    <h3>Nová skupina</h3>
                    {/* <GroupsList orgData={currentOrg}/> */}

                    <ItemProfileEditable 
                        label={language[`type_${userPrefs.currentOrg.orgtype}_groupName`]}
                        required={true}
                        onFinishEditing={setGroupName}
                    />

                    <button type="submit" className="ListItem CLR-BTN-1">
                        {language[`type_${userPrefs.currentOrg.orgtype}_createGroup`]}
                    </button>
                    <button type="button" className="ListItem CLR-BTN-1" onClick={() => handleCancel()}>
                        {language.cancel}
                    </button>
            </div>
        </form>
          </>
          );
  };

const EnhancedPage = AnimatedPage(PageContent, pageID);
const components = {
    [pageID]: () => <EnhancedPage />
  };
export default components[pageID];
