import React, { useContext, useState, useEffect } from 'react';
import './poll.css';
import AppContext from '../global/AppContext';
import ButtonUniversal from './buttonUniversal';
import UsersList from './usersList';
import { SendRequest } from '../../webRequests';

const Poll = ({ pollData, postData }) => {
    const { language, userState, showConfirmation, showOptions } = useContext(AppContext);
    const [ localPollData, setPollData] = useState(pollData);
    
    // State to keep track of the checked status of checkboxes
    const [checkedOptions, setCheckedOptions] = useState(
        Array(localPollData.polloptions.length).fill(false)
    );

    const totalVotes = localPollData.pollvotes.length;

    const percentages = localPollData.polloptions.map((option, index) => {
        const votesForOption = localPollData.pollvotes.filter(vote => vote.polloptionindex === index).length;
        const percentage = totalVotes > 0 ? (votesForOption / totalVotes) * 100 : 0; // Avoid division by zero
        return {
            optionIndex: index,
            percentage: percentage.toFixed(2) // Fix to 2 decimal places
        };
    });

    const sendVote = async (index, isChecked) => {
        console.log("Voted for option ", index, "Checked:", isChecked);
        
        const inputData = {
            voteindex: index,
            pollid: localPollData.pollid,
            ischecked : isChecked // Send the checked state
        };
    
        const responseData = await SendRequest('pollvote', { inputdata: inputData });
        console.log("Server response:", responseData);
    }

    const confirmToggleCheckbox = (index) => {
        if (postData.byuser.userid === userState.user.userid)
            return;

        const userVoted = localPollData.pollvotes.find(vote => vote.userid === userState.user.userid);
        if (!userVoted){
            showConfirmation(
                <>
                {language.pollConfirmation}
                </>,
                async () =>  {
                    handleToggleCheckbox(index);
                },
                () => {
                    console.log('Delete action canceled');
                }
            );
        }
    };

    // Function to handle toggling the checkbox
    const handleToggleCheckbox = (index) => {
        setCheckedOptions((prevCheckedOptions) => {
            // Determine if the checkbox is currently checked
            const isChecked = !prevCheckedOptions[index];
    
            // Create a new array of poll votes
            let updatedPollVotes;
    
            if (isChecked) {
                // If checking the checkbox, remove previous votes and add the new vote
                updatedPollVotes = localPollData.pollvotes
                    .filter(vote => vote.userid !== userState.user.userid) // Remove previous votes from the user
                    .concat({ userid: userState.user.userid, polloptionindex: index }); // Add the new vote
            } else {
                // If unchecking the checkbox, remove the specific vote
                updatedPollVotes = localPollData.pollvotes.filter(
                    vote => !(vote.userid === userState.user.userid && vote.polloptionindex === index)
                ); // Remove the user's vote for this option
            }
    
            // Calculate total votes and new percentages
            const totalVotes = updatedPollVotes.length;
            const updatedPercentages = localPollData.polloptions.map((option, idx) => {
                const votesForOption = updatedPollVotes.filter(vote => vote.polloptionindex === idx).length;
                const percentage = totalVotes > 0 ? (votesForOption / totalVotes) * 100 : 0; // Avoid division by zero
                return {
                    ...option,
                    percentage: percentage.toFixed(2) // Keep two decimal places
                };
            });
    
            // Update localPollData with new votes and percentages
            setPollData(prevPollData => ({
                ...prevPollData,
                pollvotes: updatedPollVotes,
                polloptions: updatedPercentages
            }));
    
            // Set the checked options
            const updatedCheckedOptions = Array(localPollData.polloptions.length).fill(false);
            if (isChecked) {
                updatedCheckedOptions[index] = true; // Check the current checkbox
            }
    
            // Send vote with the new checked state
            sendVote(index, isChecked); // Send the vote
    
            return updatedCheckedOptions; // Return the new checked state
        });
    };

    // useEffect to set the initial checked state
    useEffect(() => {
        const userVote = localPollData.pollvotes.find(vote => vote.userid === userState.user.userid);
        if (userVote) {
            const optionIndex = userVote.polloptionindex;
            const updatedChecked = Array(localPollData.polloptions.length).fill(false);
            updatedChecked[optionIndex] = true; // Check the appropriate checkbox
            setCheckedOptions(updatedChecked);
        }
    }, [localPollData.pollvotes, userState.user.userid, localPollData.polloptions.length]);

    const showVotingUsers = async () => {
        // Extract and sort the users from the poll votes
        const sortedUsers = localPollData.pollvotes
            .map((vote) => vote.user) // Assuming each vote has a user object
            .sort((a, b) => {
                // Sort primarily by lastname, secondarily by firstname
                const lastNameA = a.lastname.toLowerCase(); // Convert to lower case for case-insensitive comparison
                const lastNameB = a.lastname.toLowerCase();
                if (lastNameA < lastNameB) return -1; // a comes first
                if (lastNameA > lastNameB) return 1; // b comes first
                
                // If last names are equal, sort by first name
                const firstNameA = a.firstname.toLowerCase();
                const firstNameB = b.firstname.toLowerCase();
                if (firstNameA < firstNameB) return -1; // a comes first
                if (firstNameA > firstNameB) return 1; // b comes first
    
                return 0; // Names are equal
            });
    
        // Show the users in the options dialog
        showOptions({
            message: (
                <UsersList 
                    title={language.votersList} // Change the title as appropriate
                    users={sortedUsers} // Pass the sorted user list
                />
            ),
            showIcon: false,
            buttons: []
        });
    };

    return (
        <div style={{marginTop:"clamp(0px, 5vw, 20px)"}}>
            <div style={{ fontSize: "clamp(0px, 5vw, 25px)", fontWeight: "500", textAlign: "center", color: "#40bbe1" }}>
                {language.poll}
            </div>
            <div style={{ fontWeight: "500", textAlign: "center" }}>
                {localPollData.polltitle}
            </div>
            <div style={{ paddingInline: "clamp(0px, 5vw, 25px)" }}>
                {localPollData.polloptions.length > 0 && (
                    localPollData.polloptions.map((option, index) => (
                        <div key={index} style={{ paddingBlock: "clamp(0px, 1vw, 5px)" }}>
                            <ButtonUniversal
                                text={
                                    <>
                                        <div style={{width:"90%", textAlign:"left"}}>{option.text}</div>
                                        <div className='Poll-Percentage-Number'>
                                            {`${percentages[index].percentage}% (${localPollData.pollvotes.filter(vote => vote.polloptionindex === index).length} ${
                                                localPollData.pollvotes.filter(vote => vote.polloptionindex === index).length === 1 
                                                    ? language.pollGraphVote 
                                                    : localPollData.pollvotes.filter(vote => vote.polloptionindex === index).length > 4 || 
                                                    localPollData.pollvotes.filter(vote => vote.polloptionindex === index).length === 0 
                                                        ? language.pollGraphVotes 
                                                        : language.pollGraphVotesB
                                            })`}
                                        </div>
                                        <div className='Poll-Percentage-Graph Background'></div>
                                        <div className='Poll-Percentage-Graph Amount'  style={{width: `${percentages[index].percentage}%`}}></div>
                                    </>
                                }
                                style={{
                                    boxShadow: "1px 1px 5px 0px #00000063",
                                    width: "100%",
                                    padding: "clamp(0px,2vw,10px)",
                                    marginBlock: "clamp(0px,1vw,5px)",
                                    paddingBottom: "calc(clamp(0px, 2vw, 10px) + clamp(0px,1vw,5px))"
                                }}
                                rightIcon={
                                    postData.byuser.userid !== userState.user.userid && (
                                        <input
                                        className='Poll-Checkbox'
                                            type="checkbox"
                                            checked={checkedOptions[index]} // Bind to the state
                                            readOnly // Make the checkbox read-only so we control its state via the button click
                                            style={{
                                                accentColor: "#00ac00",
                                                width: "clamp(0px,6vw,25px)",
                                                height: "clamp(0px,6vw,25px)",
                                                margin: "0",
                                                marginLeft: "auto",
                                                transform: "translate(clamp(-10px,-2vw,0px), 0px)"
                                            }}
                                        />
                                    )
                                }
                                rightIconOnEnd={true}
                                onClick={() => confirmToggleCheckbox(index)} // Toggle checkbox on button click
                            />
                        </div>
                    ))
                )}

                {(localPollData.polloptions.length > 0 && postData.mygrouprole != 0 ) && (
                    <ButtonUniversal 
                        text={language.votersList}
                        style={{
                            boxShadow: "1px 1px 5px 0px #00000063",
                            width: "50%",
                            padding: "clamp(0px,2vw,10px)",
                            marginTop: "clamp(0px,4vw,20px)",
                            marginBottom: "clamp(0px,1vw,5px)",
                            justifyContent: "center",
                            marginInline: "auto"
                        }}
                        onClick={showVotingUsers}
                    />
                )}
            </div>
        </div>
    );
};

export default Poll;