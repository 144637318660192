import React, { useContext, useState, useEffect } from 'react';
import AppContext from '../global/AppContext';

const Introduction = () => {
    const { language, userPrefs, userState } = useContext(AppContext);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (userPrefs && userState && userState.loginstatus !== 1) {
            requestAnimationFrame(() => {
                setIsVisible(!userPrefs.wantLogIn);
            });
        }
    }, [userPrefs, userState]);

    if (!userPrefs || !userState || userState.loginstatus === 1) {
        return null; // or a loading spinner, or an empty div
    }

    return (
        <div className='Introduction Page-Container'>
            <div className={`Box CLR-BG-3 CLR-TXT-1 Shadow-1 Fade FromBottom ${isVisible ? 'Visible' : ''}`} style={{ padding: "50px" }}>
                <h1>{language.introductionTitle}</h1>
                {language.introductionText}
            </div>
        </div>
    );
};

export default Introduction;