import React, { useContext, useState, useEffect, useRef } from 'react';
import AppContext from '../global/AppContext';
import ButtonLayered from '../elements/buttonLayered';
import { MaterialSymbol } from 'react-material-symbols';
import { DayPicker } from 'react-day-picker';
import { cs } from 'date-fns/locale/cs';
import './postAddModal.css';
import  './calendarNewEvent.css';
import ItemTextEditable from '../elements/itemTextEditable';
import Picker from 'react-mobile-picker';
import { SendRequest } from '../../webRequests';
import ButtonUniversal from '../elements/buttonUniversal';

const CalendarNewEvent = () => {
    const { language, userState, userPrefs, updateUserPrefs, updateUserState, showConfirmation } = useContext(AppContext);

    const [isVisible, setIsVisible] = useState(false);
    const [shouldRender, setShouldRender] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [eventName, setEventName] = useState('Název upomínky');
    const [eventText, setEventText] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date());;
    const [month, setMonth] = useState(new Date());
    const eventTextRef = useRef(null);

    const [timePickerValue, setTimePickerValue] = useState({
        hour: '12',
        minute: '00'
    });

    const timeSelections = {
        hour: [...Array(24).keys()].map(n => n.toString().padStart(2, '0')),
        minute: [...Array(60).keys()].map(n => n.toString().padStart(2, '0'))
    };


    useEffect(() => {
        let isVisibleTimeout;
        let shouldRenderTimeout;
        let conditionsMet = (userPrefs?.displayCalendarNewEvent || 0) && userState.loginstatus === 1;

        if (userPrefs && userState) {
            if (conditionsMet) {
                setShouldRender(true);
                 isVisibleTimeout = setTimeout(() => {
                     
                    if (userPrefs.editCalendarEvent !== null && userPrefs.editCalendarEvent) {
                        const event = userPrefs.editCalendarEvent;
                        console.log("Editing event ", event);
                        
                        // Set event name
                        setEventName(event.name);
                        setEventText(event.text);
    
                        if (!userPrefs.isPostEvent){

                            // Extract and set the time picker values
                            const eventDate = new Date(event.eventdate);
                            const hours = eventDate.getHours().toString().padStart(2, '0');
                            const minutes = eventDate.getMinutes().toString().padStart(2, '0');
                            setTimePickerValue({ hour: hours, minute: minutes });
                            
                            // Set selected date
                            setSelectedDate(eventDate);
                            setMonth(eventDate);
                        }
                        else{
                            const eventDate = new Date(event.eventdate);

                            // Extract and set the time picker values
                            const hours = eventDate.getUTCHours().toString().padStart(2, '0');
                            const minutes = eventDate.getUTCMinutes().toString().padStart(2, '0');

                            console.log("hours:", hours , " minutes:" , minutes);

                            setTimePickerValue({ hour: hours, minute: minutes });

                            // Set selected date (local date based on UTC time)
                            setSelectedDate(eventDate);
                            setMonth(eventDate);
                        }
                    }
                    else if (userPrefs.selectedDate != null && !isVisible){
                        setSelectedDate(userPrefs.selectedDate);
                        setMonth(userPrefs.selectedDate);
                        setEventName('Název upomínky');
                    }
                    else if (!isVisible)
                    {
                        setSelectedDate(new Date());
                        setMonth(new Date());
                        setEventName('Název upomínky');
                    }
                    setIsVisible(true);
                }, 50);
            } else {
                setIsVisible(false);
                shouldRenderTimeout = setTimeout(() => {
                    setShouldRender(false);
                    setEventText('');
                    setTimePickerValue({
                        hour: '12',
                        minute: '00'
                    });
                }, 500); 
            }
        }
        return () => {
            clearTimeout(isVisibleTimeout);
            clearTimeout(shouldRenderTimeout);
        };
    }, [userPrefs, userState]);
    
    const close = async (event) => {
        event.preventDefault();
        updateUserPrefs('editCalendarEvent', null);
        updateUserPrefs('displayCalendarNewEvent', 0);
        console.log("Close");
    };

    const deleteEvent = async (event) => {
        event.preventDefault();

        if (userPrefs?.isPostEvent){
            let updatedPost = { ...userPrefs.newPost };
            delete updatedPost.calendarEvent;
            updateUserPrefs('newPost', updatedPost);
            updateUserPrefs('editCalendarEvent', null);
            updateUserPrefs('displayCalendarNewEvent', 0);
        }
        else{
            showConfirmation(
                <>
                {language.confirmCalendarEventDelete} <br/> <strong>{event.eventname}</strong>
                </>,
                async () =>  {
                    console.log('Delete action confirmed');
                    const inputData = {
                        eventid: userPrefs.editCalendarEvent?.id,
                    };
                    
                    updateUserPrefs('displayInfoLoading', 1);
                    const responseData = await SendRequest('deletemycalendarevent', { inputdata: inputData });
                    unCacheEvent(userPrefs.editCalendarEvent?.id);
                    updateUserPrefs('displayInfoLoading', 0);
                    updateUserPrefs('editCalendarEvent', null);
                    updateUserPrefs('displayCalendarNewEvent', 0);
                },
                () => {
                    console.log('Delete action canceled');
                }
            );
        }
    };

    const save = async (event) => {
        event.preventDefault();
        if (selectedDate){
            if (userPrefs?.isPostEvent){
                updateUserPrefs('editCalendarEvent', null);
                savePostEventData();
                updateUserPrefs('displayCalendarNewEvent', 0);
            }
            else{
                updateUserPrefs('displayInfoLoading', 1);
                await sendMyCalendarEvent();
                updateUserPrefs('displayInfoLoading', 0);
                updateUserPrefs('editCalendarEvent', null);
                updateUserPrefs('displayCalendarNewEvent', 0);
            }
        }
        else{
            updateUserPrefs('infoMessage', "Vyberte datum upomínky");
            updateUserPrefs('displayInfoMessage', 1);
        }
        console.log("Save:", "\nNázev upomínky:", eventName , "\nTime:", timePickerValue.hour + ':' + timePickerValue.minute, "\nDate:", selectedDate ? selectedDate.toLocaleDateString() : 'No date selected', "\nText:", eventText);
    };

    const finishEventNameEditing = (variableName) => async (newValue) => {
        console.log("Finished editing ", variableName, "value:" + newValue + ":");

        if (variableName === "Název upomínky") {
            setEventName(newValue);
        }
    };

    const modifiers = {
        weekends: (date) => date.getDay() === 0 ,
    };

    const savePostEventData = () => {
        var updatePost = userPrefs.newPost;
        updatePost.calendarEvent = eventData();
        updateUserPrefs('newPost', updatePost);
    }

    const eventData = () => {
        return {
            id: userPrefs.editCalendarEvent?.id,
            name: eventName,
            eventdate: pickedDateTime(),
            text: eventText,
        };
    }

    const pickedDateTime = () => {
        const time = timePickerValue.hour + ':' + timePickerValue.minute;
        const parsedDate = new Date(selectedDate);
        const [hours, minutes] = time.split(':').map(Number);
        const localDate = new Date(selectedDate);
        localDate.setHours(hours, minutes, 0, 0);
        const datetimeISO = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000).toISOString();
        return datetimeISO;
    }

    const sendMyCalendarEvent = async () => {

        console.log("Sending calendar event:", "\nNázev upomínky:", eventName , "\nTime:", timePickerValue.hour + ':' + timePickerValue.minute, "\nDate:", selectedDate ? selectedDate.toLocaleDateString() : 'No date selected', "\nText:", eventText);

        const time = timePickerValue.hour + ':' + timePickerValue.minute;
        const parsedDate = new Date(selectedDate);
        const [hours, minutes] = time.split(':').map(Number);
        const localDate = new Date(selectedDate);
        localDate.setHours(hours, minutes, 0, 0);
        const datetimeISO = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000).toISOString();

        console.log("Converted date from:", selectedDate, " to:" , datetimeISO)

        const inputData = {
            eventid: userPrefs.editCalendarEvent?.id,
            eventname: eventName,
            eventdate: datetimeISO,
            text: eventText,
        };

        const responseData = await SendRequest('addmycalendarevent', { inputdata: inputData });

        if (responseData.result.success === true) {
            inputData.eventid = responseData.result.id ? responseData.result.id : inputData.eventid;
            cacheEvent(inputData);
        }
        console.log(responseData);
    };

    const unCacheEvent = async (calendarEventId) => {
        // Retrieve the current events from the user state
        const existingEvents = userState.calendarevents.myevents || [];
    
        // Filter out the event that matches the given eventid
        const updatedEvents = existingEvents.filter(event => event.id !== calendarEventId);
    
        // Update the user state with the new events list
        updateUserState('calendarevents', {
            ...userState.calendarevents,
            myevents: updatedEvents
        });
    };

    const cacheEvent = async (calendarEvent) => {
        const formattedEvent = {
            id: calendarEvent.eventid,
            userid: userState.user.userid,
            groupid: null,
            orgid: null,
            createddate: formatDate(new Date()),
            eventdate: calendarEvent.eventdate.replace('T', ' ').split('.')[0],
            name: calendarEvent.eventname,
            text: calendarEvent.text
        };
    
        const existingEvents = userState.calendarevents.myevents || [];
        
        // Check if the event already exists in the list
        const eventIndex = existingEvents.findIndex(event => event.id === calendarEvent.eventid);
    
        let updatedEvents;
    
        if (eventIndex !== -1) {
            // If the event exists, overwrite it with the updated event
            updatedEvents = [...existingEvents];
            updatedEvents[eventIndex] = formattedEvent;
        } else {
            // If the event doesn't exist, add it to the list
            updatedEvents = [...existingEvents, formattedEvent];
        }
    
        // Update the user state with the new events list
        updateUserState('calendarevents', {
            ...userState.calendarevents,
            myevents: updatedEvents
        });
    };

    function formatDate(date) {
        const padZero = (num) => num.toString().padStart(2, '0');
    
        const year = date.getFullYear();
        const month = padZero(date.getMonth() + 1); // Months are 0-based in JS
        const day = padZero(date.getDate());
    
        const hours = padZero(date.getHours());
        const minutes = padZero(date.getMinutes());
        const seconds = padZero(date.getSeconds());
    
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
    
    return ( shouldRender ?
        <div className='Page-Container' style={{transition: '0.5s', zIndex: '2', backgroundColor: isVisible ? '#00000078' : '', position:'fixed', maxHeight: '100%', overflowY:'hidden'}}>
            <form onSubmit={save} className={`Box CLR-BG-2 Shadow-1 Fade FromTop ${isVisible ? 'Visible' : ''}`} style={{width:'80%',backgroundColor:'white', paddingBottom:'30px'}} >
                <div style={{display: "flex", alignItems: "center", position: "relative"}}>
                    {/* <div style={{position:"absolute", left:"50%", transform: "translate(-50%)"}}>
                        Nová 
                    </div> */}
                    <button type="button" className="CLR-BTN-Close" onClick={close} style={{ float: 'right', display:"flex", marginLeft:"auto", padding:"0" }}>
                        <MaterialSymbol icon="close" grade={-25} />
                    </button>
                </div>
                <div style={{display:"flex", justifyContent:"center", fontSize:"20px", fontWeight:"500"}}>
                    <Picker value={timePickerValue} onChange={setTimePickerValue} wheelMode="normal" height={100} itemHeight={35} className='picker' style={{width:"90%", position:"relative", maskImage:"linear-gradient(to top, transparent, transparent 5%, white 50%, white 50%, transparent 95%, transparent)"}}>
                        <div style={{position:"absolute", top:"50%", transform:"translate(0,-50%)", fontWeight:"700"}}>
                            :
                        </div>
                        {Object.keys(timeSelections).map(name => (
                            <Picker.Column key={name} name={name}>
                                {timeSelections[name].map(option => (
                                    <Picker.Item key={option} value={option}>
                                        {option}
                                    </Picker.Item>
                                ))}
                            </Picker.Column>
                        ))}
                    </Picker>
                </div>
                <DayPicker
                    modifiers={modifiers}
                    modifiersClassNames={{
                        weekends: "sundays"
                    }}
                    fixedWeeks={true}
                    className='simplified'
                    mode="single"
                    captionLayout="dropdown"
                    selected={selectedDate}
                    onSelect={(date) => {
                        setSelectedDate(date);
                        // Keep the selected month in view after choosing a day
                        if (date) {
                            setMonth(date);
                        }
                    }}
                    month={month}  // Control the displayed month with state
                    onMonthChange={setMonth}  // Update state when the user manually changes the month
                    startMonth={new Date(2020, 0)}
                    endMonth={new Date(2030, 11)}
                    locale={cs}
                    footer={
                        selectedDate ? `Vybráno: ${selectedDate.toLocaleDateString()}` : "Vyberte den"
                    }
                    styles={{
                        root: { width: '100%' },
                        month: { width: '100%' },
                        months: { width: '100%', maxWidth: '100%' },
                        month_grid: { width: '100%' },
                        selected: { backgroundColor: 'red' },
                    }}
                />
                <div style={{paddingTop:"15px"}}>
                <ItemTextEditable 
                    simple={true}
                    fontSize={"15px"}
                    value={eventName ? eventName : "Název upomínky"}
                    clearValue={"Název upomínky"}
                    onFinishEditing={finishEventNameEditing("Název upomínky")}
                    style={{textAlign:"center", fontWeight:"500"}}
                    />
                </div>
                <textarea 
                    style={{height:"20vw", boxShadow:"0 0.5vw 3vw rgb(0 0 0 / 25%)"}}
                    type="text"
                    placeholder="Text upomínky ..."
                    className="PostAddInput"
                    ref={eventTextRef}
                    value={eventText}
                    onChange={(e) => setEventText(e.target.value)}
                />

                {(userPrefs?.isPostEvent == 1 && userPrefs?.newPost?.text != undefined && userPrefs?.newPost?.text != "" ) && (
                    <ButtonUniversal
                        text={language.postEventCopyText}
                        style={{width:"100%", justifyContent:"center", paddingInline:"clamp(0px,5vw,50px)", marginTop:"clamp(0px,3vw,15px)"}}
                        onClick={() => setEventText(userPrefs?.newPost?.text)}
                    />
                )}

                <div style={{display:"flex", justifyContent:"space-between", marginTop:"clamp(0px,5vw,20px)"}}>
                    <button onClick={close} style={{fontSize:"15px", fontWeight:"700", backgroundColor:"#fdd046", borderStyle:"none", borderRadius:"10px", height:"30px", width:"30%"}}>{language.cancel}</button>
                    {userPrefs.editCalendarEvent && (
                        <button onClick={deleteEvent} style={{fontSize:"15px", fontWeight:"700", backgroundColor:"#fdd046", borderStyle:"none", borderRadius:"10px", height:"30px", width:"30%"}}>{language.delete}</button>
                    )}
                    <button style={{fontSize:"15px", fontWeight:"700", backgroundColor:"#fdd046", borderStyle:"none", borderRadius:"10px", height:"30px", width:"30%"}}>{language.save}</button>
                    {/* <ButtonLayered type="submit" text={language.save} disabled={isSubmitting}/> */}
                </div>
            </form>
        </div>
        :
        null
    );
};

export default CalendarNewEvent;