import React, { useContext } from 'react';
import AvatarButton from '../elements/avatarButton';
import AppContext from '../global/AppContext';
import OpenPublicProfile from '../functions/openPublicProfile';

const UsersList = ({ users, title, opensPublicProfiles = true, onClick }) => {
    const { userPrefs, updateUserPrefs } = useContext(AppContext);
    const openPublicProfile = OpenPublicProfile();

    //console.log("Users in list:", users);

    return (
        <>
            {users && users.length > 0 && (
                <>             
                    <h4 style={{ marginTop: "20px", marginBottom: "10px" }}>
                        {title}
                    </h4>        
                    <div className={`usersList`} style={{ width: "100%", display:"grid", justifyContent:"center", gridTemplateColumns:"repeat(auto-fit, minmax(0px, clamp(0px,33.3%,125px)))" }}>
                        {users.map((user, index) => (
                            <AvatarButton
                                key={index}
                                user={user}
                                outlineStyle={user.role >= 98 
                                    ? { boxShadow: '0 0 0 1vw #fdd046' } 
                                    : user.ispresent === 1 
                                      ? { boxShadow: '#00e000 0px 0px 0px 1vw' } 
                                      : {}
                                }
                                displayName={true}
                                displayFullName={true}
                                onClick={() => {
                                    if (opensPublicProfiles && !onClick) {
                                        openPublicProfile(user.userid); // Open the public profile
                                    }
                                    if (onClick) {
                                        onClick(user); // Pass the full `user` object to the onClick handler
                                    }
                                }}
                            />
                        ))}
                    </div>   
                </>
            )}
        </>
    );
};

export default UsersList;