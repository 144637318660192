import { useContext } from 'react';
import AppContext from '../global/AppContext';
import { SendRequest } from '../../webRequests';
import { useUserState } from '../global/userState';

// Custom hook to open a public profile
const OpenPublicProfile = () => {
  const { userState, updateUserPrefs } = useContext(AppContext);

  const openPublicProfile = async (userid) => {
    console.log("Open public profile for userid", userid, " myid:" , userState.user.userid, "childusers:" , userState.childusers);

    if (userid === userState.user.userid){
        console.log("Edit self", userState.user);
        updateUserPrefs("editUser", userState.user);
        updateUserPrefs("currentPage", "EditProfile");
    }
    else if (userState.childusers.some(user => Number(user.userid) === Number(userid))) {
        // Handle case where userid is in child users
        console.log("Edit child ", userState.childusers.find(user => Number(user.userid) === Number(userid)));
        updateUserPrefs("editUser", userState.childusers.find(user => Number(user.userid) === Number(userid)));
        updateUserPrefs("currentPage", "EditProfile");
    }
    else
    {
        updateUserPrefs('displayInfoLoading', 1);
    
        const responseData = await SendRequest('getuserpublic', { userid });
        console.log("Open public profile for userid", userid, "\nResult:\n", responseData);
    
        updateUserPrefs('publicDetailUser', responseData.user);
        updateUserPrefs("currentPage", 'PublicProfile');
    
        updateUserPrefs('displayInfoLoading', 0);
    }
  };

  return openPublicProfile;
};

export default OpenPublicProfile;