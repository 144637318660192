import React, { useState } from 'react';

const ButtonLayered = ({ onClick, text, className, disabled, width, style }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const combinedStyle = {
        width, 
        ...style // Spread the style prop to apply additional styles
    };

    return (
        <button
            onClick={onClick}
            className={`ButtonLayered CLR-BTN-2 ${className}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            disabled={disabled}
            style={combinedStyle} // Apply the width prop here
        >
            <div style={{ position: 'relative', zIndex: 2 }}>
                {text}
            </div>
            <div
                style={{
                    opacity: isHovered ? 1 : 0,
                }}
                className='ButtonLayered hover CLR-BTN-2-Hovered'
            />
        </button>
    );
};

export default ButtonLayered;