import React, { useContext, useState, useEffect, useRef } from 'react';
import AppContext from '../global/AppContext';
import ButtonLayered from '../elements/buttonLayered';
import { MaterialSymbol } from 'react-material-symbols';
import UserAvatar from '../elements/userAvatar';
import { SendRequest } from '../../webRequests';
import './postAddModal.css';

const PostAddModal = () => {
    const { language, userState, userPrefs, updateUserPrefs } = useContext(AppContext);

    const [isVisible, setIsVisible] = useState(false);
    const [shouldRender, setShouldRender] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false); 
    const textareaRef = useRef(null);

    useEffect(() => {
        let isVisibleTimeout;
        let shouldRenderTimeout;
        let conditionsMet = (userPrefs?.postModalOpen || 0) && userState.loginstatus === 1;

        if (userPrefs && userState) {
            if (conditionsMet) {
                setShouldRender(true);
                 isVisibleTimeout = setTimeout(() => {
                    setIsVisible(true);
                }, 50);
            } else {
                setIsVisible(false);
                shouldRenderTimeout = setTimeout(() => {
                    setShouldRender(false);
                }, 500); 
            }
        }
        return () => {
            clearTimeout(isVisibleTimeout);
            clearTimeout(shouldRenderTimeout);
        };
    }, [userPrefs, userState]);
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);

        const postText = textareaRef.current.value;
        
        try {
            console.log('Post text:' , postText);
            const responseData = await SendRequest('addpost', { postgroupid: userPrefs.currentGroup.groupid, postorgid:userPrefs.currentOrg.orgid, posttextcontent: postText });
            console.log(responseData);
            setIsSubmitting(false);
            
            const response = await SendRequest('getgroupdata', { groupid: userPrefs.currentGroup.groupid });
            console.log('Got groupdata ', response);
            updateUserPrefs("groupdata_" + userPrefs.currentGroup.groupid, response.groupdata);
            updateUserPrefs('refreshWallData', true);
            // var newPost = { groupid: userPrefs.currentGroup.groupid, userid: userState.userid, textcontent: postText, created_at: "2024-06-10 00:04:33"};
            // const currentGroupData = userPrefs["groupdata_" + userPrefs.currentGroup.groupid] || [];
            // console.log("Current groupdata " , currentGroupData);
            // const updatedPosts = [...currentGroupData.posts, newPost];
            // updateUserPrefs("groupdata_" + userPrefs.currentGroup.groupid,  { ...currentGroupData, posts: updatedPosts });

            if (responseData.result === 'success'){
                updateUserPrefs('postModalOpen', 0);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleCancel = async (event) => {
        event.preventDefault();
        setIsSubmitting(false);
        updateUserPrefs('postModalOpen', 0);
    };
    
    return ( shouldRender ?
        <div className='Page-Container' style={{transition: '0.5s', zIndex: '2', backgroundColor: isVisible ? '#00000078' : '', position:'fixed', maxHeight: '100%', overflowY:'hidden'}}>
            <form onSubmit={handleSubmit} className={`Box CLR-BG-2 Shadow-1 Fade FromBottom ${isVisible ? 'Visible' : ''}`} style={{width:'80%',backgroundColor:'white'}} >

                <button type="button" className="Icon-Circle CLR-BTN-Close" onClick={handleCancel} style={{ position: 'absolute', right: '10px' }}>
                    <MaterialSymbol icon="close" grade={-25} />
                </button>

                <div><h2 className='CLR-TXT-1' style={{ marginBottom: '0px'}}>{language.newPost}</h2></div>
                <div><h4 className='CLR-TXT-1' style={{ marginTop: '0px', opacity:'0.5'}}>{userPrefs.currentOrg.orgname} <br/> {userPrefs.currentGroup.groupname}</h4></div>
                <div style={{display:"flex", justifyContent:"center"}}><UserAvatar userid={userState.user.userid} userName={{firstname: userState.user.firstname, lastname: userState.user.lastname}}/></div>
                <div>
                    <textarea 
                        required
                        type="text"
                        placeholder={language.enterPostText}
                        className="PostAddInput"
                        ref={textareaRef}
                    />
                </div>
                <ButtonLayered type="submit" text={language.add} disabled={isSubmitting}/>
                <div className='loader' style={{marginInline: 'auto', marginBlock: '10px', transition:'0.5s', opacity: isSubmitting ? 1 : 0}}></div>
            </form>
        </div>
        :
        null
    );
};

export default PostAddModal;