import { useState } from 'react';
import langCZ from '../languages/cz.json';

export const useLanguage = () => {
  const [language, setLanguage] = useState(langCZ);

  const changeLanguage = (langCode) => {
    switch (langCode) {
      case 'cz':
        setLanguage(langCZ);
        break;
      default:
        setLanguage(langCZ);
    }
  };

  return { language, changeLanguage };
};