import React, { useContext, useState, useEffect } from 'react';
import './poll.css';
import AppContext from '../global/AppContext';
import ButtonUniversal from './buttonUniversal';
import { SendRequest } from '../../webRequests';
import UsersList from './usersList';

const PostConfirmation = ({ postData }) => {
    const [ localPostData, setLocalPostData] = useState(postData);
    const { language, userState, showConfirmation, showOptions } = useContext(AppContext); // Added userState

    // State to manage the checkbox checked state
    const [isChecked, setIsChecked] = useState(false);

    // Check for user's confirmation on initial load
    useEffect(() => {
        // Check if postData.confirmations contains an object with the matching userid
        const hasConfirmed = localPostData.confirmations.some(
            (confirmation) => confirmation.userid === userState?.user?.userid
        );

        // Set the initial checkbox state based on this check
        if (hasConfirmed) {
            setIsChecked(true);
        }
    }, [localPostData, userState]);

    const confirm = () => {
        const hasConfirmed = localPostData.confirmations.some(
            (confirmation) => confirmation.userid === userState?.user?.userid
        );

        if (!hasConfirmed){
            showConfirmation(
                <>
                    {language.postConfirmConfirm}
                </>,
                async () =>  {
                    // Handle checkbox toggle and send confirmation
                    handleToggleCheckbox();
                },
                () => {
                    console.log('Delete action canceled');
                }
            );
        }
    };

    // Toggle checkbox checked state and send confirmation
    const handleToggleCheckbox = async () => {
        const newCheckedState = !isChecked; // The new state after toggle
        setIsChecked(newCheckedState);
        
        // Update localPostData to reflect the new confirmation
        const updatedConfirmations = [
            ...localPostData.confirmations,
            { userid: userState.user.userid } // Add the current user's confirmation
        ];
        
        setLocalPostData((prevData) => ({
            ...prevData,
            confirmations: updatedConfirmations // Update confirmations array
        }));
    
        await sendConfirmation(newCheckedState); // Pass the new checked state
    };

    const sendConfirmation = async (checkedState) => {
        console.log("Checked:", checkedState);
        
        const inputData = {
            postid: localPostData.postid,
            ischecked: checkedState // Send the checked state
        };
    
        const responseData = await SendRequest('postconfirmread', { inputdata: inputData });
        console.log("Server response:", responseData);
    }

    const showConfirmedUsers = async () => {
        if (localPostData.confirmations.length == 0)
            return;

        // Extract and sort the users from the confirmations
        const sortedUsers = localPostData.confirmations
            .map((confirmation) => confirmation.user) // Assuming each confirmation has a user object
            .sort((a, b) => {
                // Sort primarily by lastname, secondarily by firstname
                const lastNameA = a.lastname.toLowerCase(); // Convert to lower case for case-insensitive comparison
                const lastNameB = b.lastname.toLowerCase();
                if (lastNameA < lastNameB) return -1; // a comes first
                if (lastNameA > lastNameB) return 1; // b comes first
                
                // If last names are equal, sort by first name
                const firstNameA = a.firstname.toLowerCase();
                const firstNameB = b.firstname.toLowerCase();
                if (firstNameA < firstNameB) return -1; // a comes first
                if (firstNameA > firstNameB) return 1; // b comes first
    
                return 0; // Names are equal
            });
    
        // Show the users in the options dialog
        showOptions({
            message: (
                <UsersList 
                    title={language.postConfirmedBy}
                    users={sortedUsers} // Pass the sorted user list
                />
            ),
            showIcon: false,
            buttons: []
        });
    };

    return (
        <>
            {/* Parent display */}
            {postData.mygrouprole == 0 ? (
                <div style={{marginTop:"clamp(0px, 5vw, 20px)", paddingInline: "clamp(0px, 5vw, 25px)" }}>
                <div style={{textAlign:"center", color: "#40bbe1", fontWeight:"500"}}>{language.postConfirmTitle}</div>
                <ButtonUniversal
                text={language.postConfirmText}
                style={{
                    boxShadow: "1px 1px 5px 0px #00000063",
                    width: "100%",
                    padding: "clamp(0px,2vw,10px)",
                    marginBlock: "clamp(0px,1vw,5px)",
                }}
                rightIcon={
                    <input
                    className='Poll-Checkbox'
                    type="checkbox"
                    readOnly
                    checked={isChecked} // Bind the checkbox to the state
                    style={{
                        accentColor: "#00ac00",
                        width: "clamp(0px,6vw,25px)",
                        height: "clamp(0px,6vw,25px)",
                        margin: "0",
                        marginLeft: "auto"
                    }}
                    />
                }
                rightIconOnEnd={true}
                onClick={confirm} // Handle button click to toggle checkbox
                />
                </div>
            ) : (
                // Teacher display
                <>
                    <div style={{marginTop:"clamp(0px, 5vw, 20px)", paddingInline: "clamp(0px, 5vw, 25px)" }}>
                        <ButtonUniversal
                        text={language.postConfirmationCount}
                        style={{
                            boxShadow: "1px 1px 5px 0px #00000063",
                            width: "100%",
                            padding: "clamp(0px,2vw,10px)",
                            marginBlock: "clamp(0px,1vw,5px)",
                        }}
                        rightIcon={
                            <div style={{fontSize:"clamp(0px, 4vw, 20px)", color:"#40bbe1", marginLeft:"auto"}}>
                                {postData.confirmations.length}
                            </div>
                        }
                        rightIconOnEnd={true}
                        onClick={showConfirmedUsers} // Handle button click to toggle checkbox
                        />
                    </div>
                </>
            )}
        </>
    );
};

export default PostConfirmation;