import React, { useContext, useEffect, useState, useRef } from 'react';
import AnimatedPage from './animatedPage';
import AppContext from '../global/AppContext';
import { SendRequest } from '../../webRequests';
import ButtonWithIcon from '../elements/buttonWithIcon';
import ButtonWithIconUser from '../elements/buttonWithIconUser';
import PostIcon from '../elements/postIcon';
import UsersList from '../elements/usersList';
import UserAvatar from '../elements/userAvatar';
import OpenPublicProfile from '../functions/openPublicProfile';
import ButtonUniversal from '../elements/buttonUniversal';

const pageID = "GroupDetail";

const PageContent = () => {
    const { language, userPrefs, updateUserPrefs, userState, getUserState, showOptions, hideOptions, showConfirmation } = useContext(AppContext);
    const groupId = userPrefs.groupDetail.groupid;
    const orgId = userPrefs?.currentOrg?.orgid;
    const [ userPending, setUsersPending ] = useState(false);
    const [ groupUsers, setGroupUsers ] = useState([]);
    const [ orgUsers, setOrgUsers ] = useState('');
    const newGroupNameFormRef = useRef(null);
    const newGroupNameTextareaRef = useRef(null);

    const openPublicProfile = OpenPublicProfile();

    const getGroupUsers = async () => {

        const requestData = {
            orgid: orgId,
            groupid: groupId,
        };

        const responseData = await SendRequest('getgroupusers', { requestdata: requestData });
        console.log("getgroupusers:", responseData);

        const usersData = {
            groupid: groupId,
            groupusers: responseData.groupusers?.sort((a, b) => b.role - a.role),
        };

        await setGroupUsers(usersData);
        // console.log("orgUsers: ", orgUsers);
        setUsersPending(false);
    };

    const getOrgUsers = async () => {

        const requestData = { orgid: orgId };
        const responseData = await SendRequest('getorganizationusers', { requestdata: requestData });
        console.log("getorganizationusers:", responseData);

        const usersData = {
            orgid: orgId,
            orgusers: responseData.orgusers.sort((a, b) => b.role - a.role),
        };

        await setOrgUsers(usersData);
        updateUserPrefs('orgUsers', usersData);
        // console.log("orgUsers: ", orgUsers);
        setUsersPending(false);
    };

    const deleteGroupConfirm = () => {
        showConfirmation(
            <>
            {language[`type_${currentGroup.grouptype}_groupDeleteConfirm`]}<br />
            <strong>{currentGroup?.groupname}</strong>
            </>,
            async () =>  {
                deleteGroup();
            },
            () => {
                console.log('Delete action canceled');
            }
        );
    }

    const deleteGroup = async () => {
        const requestData = { orgid :currentGroup.orgid, groupid: currentGroup.groupid };

        updateUserPrefs('displayInfoLoading', 1);
        const responseData = await SendRequest('deletegroup', { requestdata: requestData });
        console.log("deletegroup:", responseData);

        if (responseData.success){
            getUserState();
            updateUserPrefs('displayInfoLoading', 0);
            updateUserPrefs("currentPage", "OrganizationGroups");
        }
    };

    const enterNewGroupName = () => {
        showOptions({
            message: (
               <>
                <div style={{marginTop:"clamp(0px,5vw,25px)", marginBottom:"clamp(0px,2vw,10px)"}}>
                    {language[`type_${currentGroup.grouptype}_groupEditNameTitle`]}
                </div>
                    <form ref={newGroupNameFormRef} onSubmit={(e)=>renameGroup(e,newGroupNameTextareaRef.current.value)}>
                        <textarea
                            className='RoundedInput'
                            required={true}
                            defaultValue={currentGroup.groupname}
                            ref={newGroupNameTextareaRef}
                            style={{width:"100%", marginBottom:"clamp(0px,2vw,10px)", backgroundColor:"white", overflow:"hidden"}}
                            // onChange={handleTextareaChange}
                        />
                        <ButtonUniversal
                            textColor={"white"}
                            backgroundColor={"#40bbe1"}
                            text={language.change}
                            preventDefault={false} // Pass the add button functionality
                            style={{ marginLeft:"auto", width:"30%", justifyContent:"center"}}
                        />
                    </form>
               </> 
            ),
            showIcon: false
          });
    };

    const renameGroup = async (event, newName) => {
        event.preventDefault();

        const requestData = { orgid: currentGroup.orgid, groupid: currentGroup.groupid, newname: newName };

        updateUserPrefs('displayInfoLoading', 1);
        hideOptions();
        const responseData = await SendRequest('renamegroup', { requestdata: requestData });
        console.log("renamegroup:", responseData);

        if (responseData.success){
            getUserState();
            updateUserPrefs('displayInfoLoading', 0);

            var updatedGroup = userPrefs.currentGroup;
            updatedGroup.groupname = newName;
            updateUserPrefs("currentGroup", updatedGroup);
        }

    };
    
    const addPost = () => {
        // updateUserPrefs('postModalOpen', 1);
        updateUserPrefs("currentPage", "NewPost");
        updateUserPrefs("newPost", {});
        updateUserPrefs("newPostPoll");
    };

    const logChildPresence = async (user, present) => {
        console.log("logChildPresence:", user, "Current group:" , userPrefs.currentGroup, "Current org:", userPrefs.currentOrg, "present:", present);

        const requestData = { 
            userid : user.userid,
            groupid : userPrefs.currentGroup.groupid,
            targetstate : present
        };

        const responseData = await SendRequest('logpresence', { requestdata: requestData });
        console.log("logpresence result:", responseData);
        hideOptions();

        if (responseData.success){
            getGroupUsers();
        }
    }

    const childClick = (user) => {
        console.log(user);

        showOptions({
            message: (
                <>
                    <div style={{paddingBlock:"20px"}}>
                        <UserAvatar
                            user={user}
                            size={"20vw"}
                            isButton={false}
                            displayName={true}
                            displayFullName={true}
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>

                        {user.ispresent == 0 && (
                            <ButtonWithIcon
                                text={language.presenceEnter}
                                leftIcon="login"
                                rightIcon="arrow_forward_ios"
                                width={"90%"}
                                onClick={() => logChildPresence(user, true)}
                                style={{ margin: '5px' }}
                            />
                        )}

                        {user.ispresent == 1 && (
                            <ButtonWithIcon
                                text={language.presenceLeave}
                                leftIcon="logout"
                                rightIcon="arrow_forward_ios"
                                width={"90%"}
                                onClick={() => logChildPresence(user, false)}
                                style={{ margin: '5px' }}
                            />
                        )}

                        <ButtonWithIcon
                            text={language.showDetail}
                            leftIcon="search"
                            rightIcon="arrow_forward_ios"
                            width={"90%"}
                            onClick={() => {openPublicProfile(user.userid);hideOptions()}}
                            style={{ margin: '5px' }}
                        />

                    </div>
                    <div style={{ width: '100%', paddingBottom:"clamp(0px,3vw,50px)"}}>
                    </div>
                </>
            ),
            showIcon: false,
            buttons: []
        });
    };

    const managePosts = () => {
        updateUserPrefs("currentPage", "GroupPosts");
    };

    //const currentGroup = userState.usergroups.find(group => group.groupid === groupId);
    const currentGroup = userPrefs?.currentGroup;
    //const currentOrg = userState.userorganizations.find(org => org?.orgid === currentGroup?.orgid);
    const currentOrg = userState.userorganizations.find(org => org.orgid === userPrefs?.currentGroup?.orgid);
    const isUserInGroup = groupUsers.groupusers?.some(user => user.userid === userState.user.userid);
    const currentUserInGroup = groupUsers.groupusers?.find(user => user.userid === userState.user.userid);
    const userRole = currentUserInGroup?.role;
    const displayChildren = currentGroup.grouptype === 1 ? true : false;
    const filteredUsers = groupUsers?.groupusers?.filter(user => displayChildren ? user.role === 0 : user.role > 0).sort((a, b) => {
        const firstNameComparison = a.firstname.localeCompare(b.firstname);
        if (firstNameComparison !== 0) {
          return firstNameComparison;
        }
        return a.lastname.localeCompare(b.lastname);
      });

    useEffect(() => {
        //console.log("GROUPDETAIL: currentGroup:", currentGroup, " userPending:", userPending, " groupId:", groupId, " groupUsers.groupid", groupUsers.groupid);

        if (!currentGroup) {
            updateUserPrefs("currentPage", "Wall");
        }
        else if (!userPending && groupId != groupUsers?.groupid){
            setUsersPending(true);
            getGroupUsers();
            // getOrgUsers();
        }
    }, [currentOrg, updateUserPrefs]);

    const openGroupBook = () => {

        setTimeout(() => {
            const datePickerStorage = userPrefs?.datePickerStorage || {};
            const updatedDatePickerStorage = {
                ...datePickerStorage, // Copy existing datePickerStorage (or empty object)
                GroupBookDate: new Date(), // Update GroupBookDate
            };

            // Use the updater function to update userPrefs
            updateUserPrefs("datePickerStorage", updatedDatePickerStorage);
        }, 0);

        updateUserPrefs("currentPage", "GroupBook");
    };

    
    const twoItemsMenuContainerStyle = {display:"flex", flexWrap:"wrap", width:"90%", gap:"2.5%", marginTop:"2.5%"};
    const menuItemStyle = {flex: "1", height:"clamp(0px,18vw,100px)", flexDirection:"column", justifyContent:"center", alignItems:"start", padding:"3%" };
    const menuItemLayout = "menuItem";
  
    return (
        <>
            <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                <div className='CLR-BG-NavBar Shadow-2 Page-Header' style={{ width: "100%", textAlign: "center", backgroundColor:"white", color:"#464646"}}>
                    <h4 style={{ marginTop: "00px", marginBottom: "0px", color:"rgb(120, 120, 120)" }}>{language[`type_${currentGroup.grouptype}_groupDetailTitle`]}</h4>
                    <h2 style={{ marginTop: "0px", marginBottom: "0px" }}>{currentGroup?.groupname || "Group Not Found"}</h2>
                    <h4 style={{ marginTop: "00px", marginBottom: "0px", color:"rgb(120, 120, 120)"  }}>{currentOrg?.orgname || "Organization Not Found"}</h4>
                    {userRole > 0 && (
                        <PostIcon onClick={() => addPost()} />
                    )}
                </div>
                {/* <GroupsList orgData={currentOrg}/> */}

                <h4 style={{ marginTop: "20px", marginBottom: "0px" }}>{language.administration}</h4>

                <ButtonWithIconUser user={currentOrg?.owner} username={`${currentOrg?.owner?.firstname} ${currentOrg?.owner?.lastname}`} avatarSubTitle={language[`type_${currentOrg.orgtype}_orgOwner`]} userTitle={language[`type_${currentOrg.orgtype}_director`]} width={"90%"} style={{marginTop:"10px"}}/>

                {/* Default group type users */}
                {(currentGroup.grouptype == 0 || currentGroup.grouptype == 99) && (
                    <ButtonWithIcon text={language[`type_${currentGroup.grouptype}_userManagement`]} leftIcon={'group'} width={"90%"} style={{marginTop:"10px"}}
                    onClick={() => updateUserPrefs("currentPage", "GroupUsers")}/>
                )}

                {/* School group type users */}
                {currentGroup.grouptype == 1 && (
                    <div style={twoItemsMenuContainerStyle}>
                        <ButtonWithIcon text={language[`type_${currentGroup.grouptype}_userManagement`]} leftIcon={'group'} layout={menuItemLayout} style={menuItemStyle}
                            onClick={() => updateUserPrefs("currentPage", "GroupUsers")}/>

                        <ButtonWithIcon text={language[`type_${currentGroup.grouptype}_childsManagement`]} leftIcon={'group'} layout={menuItemLayout} style={menuItemStyle}
                            onClick={() => updateUserPrefs("currentPage", "GroupChildren")}/>
                    </div>
                )}

                { currentOrg.role == 99 && (
                        <div style={twoItemsMenuContainerStyle}>
                            <ButtonWithIcon text={language[`type_${currentGroup.grouptype}_groupDelete`]} leftIcon={'delete'} layout={menuItemLayout} style={menuItemStyle}
                                onClick={() => deleteGroupConfirm()}/>

                            <ButtonWithIcon text={language[`type_${currentGroup.grouptype}_groupEditName`]} leftIcon={'edit'} layout={menuItemLayout} style={menuItemStyle}
                                onClick={() => enterNewGroupName()}/>
                        </div>
                )}

                { userRole > 0 && (
                    <>

                        <h4 style={{ marginTop: "20px", marginBottom: "0px" }}>{language[`type_${currentGroup.grouptype}_groupDetailTitle`]}</h4>

                        {currentGroup.grouptype == 1 && (
                            <ButtonWithIcon text={language.groupBook} leftIcon={'menu_book'} width={"90%"} style={{marginTop:"10px"}}
                            onClick={() => openGroupBook()}/>
                        )}

                        {/* <ButtonWithIcon text={language.addPost} leftIcon={'assignment_add'} width={"90%"} style={{marginTop:"10px"}}
                            onClick={() => addPost("currentPage", "Wall")}/> */}

                        <ButtonWithIcon text={language[`type_${currentGroup.grouptype}_managePosts`]} leftIcon={'assignment'} width={"90%"} style={{marginTop:"10px"}}
                            onClick={() => managePosts()}/>
                    </>
                )}

                {groupUsers.groupusers && groupUsers.groupusers.length > 0 && (
                    <UsersList 
                        title={language[`type_${currentGroup.grouptype}_${displayChildren ? `childUsersInGroup` : `usersInGroup`}`]}
                        users={filteredUsers}
                        onClick={currentGroup.role > 0 ? (user) => childClick(user) : undefined}
                    />
                )}
            </div>
        </>
        );
  };

const EnhancedPage = AnimatedPage(PageContent, pageID);
const components = {
    [pageID]: () => <EnhancedPage />
  };
export default components[pageID];
