import React from 'react';
import UserAvatar from './userAvatar'; // Assuming the UserAvatar is in the same folder
import './avatarButton.css';

const AvatarButton = ({
  user,
  avatarSize,
  small = false,
  outlineStyle = {},
  displayName = false,
  displayFullName = false,
  buttonStyle = {}, // Custom styles for the button wrapper
  onClick,         // onClick handler for the AvatarButton
  buttonText = ''  // Optional text to display next to the avatar
}) => {
  return (
    <div 
      className="AvatarButton" 
      style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', ...buttonStyle }}
      onClick={onClick}
    >
      <div className="AvatarButtonBackground" >
        {/* Render the UserAvatar */}
        <UserAvatar
          user={user}
          size={avatarSize}
          small={small}
          outlineStyle={outlineStyle}
          displayName={displayName}
          displayFullName={displayFullName}
          onClick={onClick}
          isButton={false}
          />

        {/* Optional text next to the avatar */}
        {buttonText && (
          <div style={{ marginLeft: '10px', fontSize: '14px', color: '#333' }}>
            {buttonText}
          </div>
        )}
      </div>
    </div>
  );
};

export default AvatarButton;