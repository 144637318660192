import React, { useContext, useState, useEffect, useRef } from 'react';
import AppContext from '../global/AppContext';
import ButtonLayered from '../elements/buttonLayered';
import { MaterialSymbol } from 'react-material-symbols';
import UserAvatar from '../elements/userAvatar';
import './postAddModal.css';

const InfoMessage = () => {
    const { language, userState, userPrefs, updateUserPrefs } = useContext(AppContext);

    const [isVisible, setIsVisible] = useState(false);
    const [shouldRender, setShouldRender] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false); 
    const textareaRef = useRef(null);

    useEffect(() => {
        let isVisibleTimeout;
        let shouldRenderTimeout;
        let conditionsMet = (userPrefs?.displayInfoMessage || 0);

        if (userPrefs && userState) {
            if (conditionsMet) {
                setShouldRender(true);
                 isVisibleTimeout = setTimeout(() => {
                    setIsVisible(true);
                }, 50);
            } else {
                setIsVisible(false);
                shouldRenderTimeout = setTimeout(() => {
                    setShouldRender(false);
                }, 500); 
            }
        }
        return () => {
            clearTimeout(isVisibleTimeout);
            clearTimeout(shouldRenderTimeout);
        };
    }, [userPrefs, userState]);
    

    const handleContinue = async (event) => {
        event.preventDefault();
        updateUserPrefs('displayInfoMessage', 0);
    };

    const renderMessage = (message) => {
        return message.split('\n').map((line, index) => (
            <span key={index}>
                {line}
                <br /> {/* Add line break between lines */}
            </span>
        ));
    };
    
    return ( shouldRender ?
        <div className='Page-Container' style={{transition: '0.5s', zIndex: '3', backgroundColor: isVisible ? '#00000078' : '', position:'fixed', maxHeight: '100%', overflowY:'hidden'}}>
            <form onSubmit={handleContinue} className={`Box CLR-BG-1 Shadow-1 Fade FromTop ${isVisible ? 'Visible' : ''}`} style={{width:'80%', paddingBlock:'30px'}} >
                <div>
                  <MaterialSymbol icon="info" grade={25} weight={600} style={{fontSize:'40px', marginBottom:'20px', color:'#bebebe'}}/>
                </div>
                <div className='CLR-TXT-1' style={{ marginBottom: '30px'}}>{renderMessage(userPrefs.infoMessage)}</div>
                <div>
                  <ButtonLayered type="submit" text={language.ok} disabled={isSubmitting}/>
                </div>
            </form>
        </div>
        :
        null
    );
};

export default InfoMessage;