// src/components/ViewportManager.js
import React, { useEffect, useContext, useRef } from 'react';
import AppContext from '../global/AppContext';

const ViewportManager = () => {
    const { userPrefs } = useContext(AppContext);

    // Use useRef to store the initial height persistently
    const initialHeight = useRef(window.innerHeight); // Store the height only once

    // Set the initial viewport height using the ref value
    document.documentElement.style.setProperty('--viewport-height', `${initialHeight.current}px`);
    //console.log("Initial viewport height: ", initialHeight.current);

    const isPWA = window.matchMedia('(display-mode: standalone)').matches ||
        window.matchMedia('(display-mode: fullscreen)').matches ||
        window.matchMedia('(display-mode: minimal-ui)').matches ||
        window.navigator.standalone === true;

    useEffect(() => {
        const handleResize = () => {
            if (!isPWA && window.visualViewport) {
                const viewportHeight = window.visualViewport.height;
                document.documentElement.style.setProperty('--viewport-height', `${viewportHeight}px`);
                //console.log("Viewport height set to:", viewportHeight);
            }
        };

        // Set the initial viewport height
        handleResize();

        // Add event listeners for visual viewport resize
        window.visualViewport?.addEventListener('resize', handleResize);
        window.visualViewport?.addEventListener('scroll', handleResize); // Sometimes, the keyboard triggers a scroll event instead of resize

        // Cleanup the event listeners when component unmounts
        return () => {
            window.visualViewport?.removeEventListener('resize', handleResize);
            window.visualViewport?.removeEventListener('scroll', handleResize);
        };
    }, [isPWA]);

    return null; // The component doesn't render anything visible
};

export default ViewportManager;