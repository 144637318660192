import React, { useContext, useEffect, useState } from 'react';
import AnimatedPage from './animatedPage';
import AppContext from '../global/AppContext';
import ButtonWithIcon from '../elements/buttonWithIcon';

const pageID = "MyOrganizations";

const PageContent = () => {
  const { language, updateUserPrefs, userState } = useContext(AppContext);
  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    setOrganizations(userState.userorganizations);
  }, [userState.userorganizations]);

  const gotoOrganization = (organization) => {
    updateUserPrefs("currentOrg", organization);
    updateUserPrefs("currentPage", "OrganizationDetail");
  };

  return (
    <div style={{ padding: "clamp(0px, 1vw, 30px)", paddingTop:"clamp(0px, 3vw, 30px)" }}>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", position: "relative" }}>
        <div>
          <h3 style={{ marginBlock: "0px", position: "relative", left: '50%', transform: "translate(-50%)", paddingBottom: "10px" }}>
            {language.myOrganizations}
          </h3>
        </div>
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
            {organizations.map(organization => (
              <ButtonWithIcon
                key={organization.orgid}
                text={organization.orgname}
                leftIcon="business"
                rightIcon="arrow_forward_ios"
                width={"90%"}
                onClick={() => gotoOrganization(organization)} // Handle button click
                style={{ margin: '5px' }} // Add margin for spacing between buttons
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const EnhancedPage = AnimatedPage(PageContent, pageID);
const components = {
  [pageID]: () => <EnhancedPage />
};
export default components[pageID];