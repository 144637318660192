import React, { useState, useEffect, useRef, useContext } from 'react';
import AppContext from '../global/AppContext';
import { MaterialSymbol } from 'react-material-symbols';
import './imageGallery.css'; // Import CSS for styling

const ImageGallery = () => {
    const { userPrefs, updateUserPrefs } = useContext(AppContext);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [shouldRender, setShouldRender] = useState(false);
    const touchStartX = useRef(0);
    const touchEndX = useRef(0);

    useEffect(() => {
        console.log("userPrefs.galleryOpen ", userPrefs.galleryOpen, "shouldRender ", shouldRender);
        setShouldRender(userPrefs?.galleryOpen >= 0);
        setCurrentIndex(userPrefs.galleryOpen);
    }, [userPrefs.galleryOpen]);

    const close = () => {
        updateUserPrefs("galleryOpen", -1);
    };

    const handlePrevious = () => {
        if (currentIndex > 0) {
            setCurrentIndex((prevIndex) => prevIndex - 1);
        }
    };

    const handleNext = () => {
        if (currentIndex < userPrefs.galleryImages?.length - 1) {
            setCurrentIndex((prevIndex) => prevIndex + 1);
        }
    };

    // Handle touch start and end for sliding functionality
    const handleTouchStart = (e) => {
        touchStartX.current = e.touches[0].clientX;
    };

    const handleTouchMove = (e) => {
        touchEndX.current = e.touches[0].clientX;
    };

    const handleTouchEnd = () => {
        if (touchStartX.current - touchEndX.current > 75) {
            handleNext(); // Swiped left
        }
        if (touchStartX.current - touchEndX.current < -75) {
            handlePrevious(); // Swiped right
        }
    };

    return (
        shouldRender ? (
            <div className="image-gallery" style={{ backgroundColor: "black", position: "fixed", zIndex: "2" }}>
                <button type="button" className={`CLR-TXT-NavBar Icon Left NavBack`} onClick={close} style={{ zIndex: "3", position: "fixed", paddingLeft: "5px", backgroundColor: "black", height: "50px", width: "clamp(0px, 10vw, 30px)", fontSize: "clamp(5px, 9vw, 35px)", display: "flex", alignItems: "center" }}>
                    <MaterialSymbol 
                        icon='arrow_back'
                        grade={25}  
                        weight={300} 
                        style={{ color: "white" }} 
                    />
                </button>
                <div
                    className="image-gallery-wrapper"
                    style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                >
                    {userPrefs.galleryImages != undefined ? (
                        userPrefs.galleryImages.map((image, index) => (
                            <div className="image-container" key={index}>
                                <img src={image} alt={`Image ${index}`} />
                            </div>
                        ))
                    ) : (
                        <div style={{ color: "white", margin: "auto" }}>No images available</div> // Fallback content
                    )}
                </div>
                {currentIndex > 0 && ( // Show previous button only if not at the first image
                    <button className="prev-button" onClick={handlePrevious}>&#9664;</button>
                )}
                {currentIndex < userPrefs.galleryImages?.length - 1 && ( // Show next button only if not at the last image
                    <button className="next-button" onClick={handleNext}>&#9654;</button>
                )}
            </div>
        ) : null
    );
};

export default ImageGallery;