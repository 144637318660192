import React, { useContext, useState, useEffect, useRef } from 'react';
import AppContext from '../global/AppContext';
import ButtonLayered from '../elements/buttonLayered';
import { MaterialSymbol } from 'react-material-symbols';
import UserAvatar from '../elements/userAvatar';
import './postAddModal.css';

const InfoConfirm = () => {
    const { language, userState, userPrefs, updateUserPrefs } = useContext(AppContext);

    const [isVisible, setIsVisible] = useState(false);
    const [shouldRender, setShouldRender] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false); 
    const { confirmation, hideConfirmation } = useContext(AppContext);
    const textareaRef = useRef(null);

    useEffect(() => {
        let isVisibleTimeout;
        let shouldRenderTimeout;
        let conditionsMet = confirmation?.isOpen && userState.loginstatus === 1;

        if (userPrefs && userState) {
            if (conditionsMet) {
                setShouldRender(true);
                 isVisibleTimeout = setTimeout(() => {
                    setIsVisible(true);
                }, 50);
            } else {
                setIsVisible(false);
                shouldRenderTimeout = setTimeout(() => {
                    setShouldRender(false);
                }, 500); 
            }
        }
        return () => {
            clearTimeout(isVisibleTimeout);
            clearTimeout(shouldRenderTimeout);
        };
    }, [userPrefs, userState, confirmation]);
    

    const handleConfirm = () => {
        if (confirmation.onConfirm) {
          confirmation.onConfirm();
        }
        hideConfirmation();
      };
    
    const handleCancel = () => {
      if (confirmation.onCancel) {
        confirmation.onCancel();
      }
      hideConfirmation();
    };
    
    return ( shouldRender ?
        <div className='Page-Container' style={{transition: '0.5s', zIndex: '3', backgroundColor: isVisible ? '#00000078' : '', position:'fixed', maxHeight: '100%', overflowY:'hidden'}}>
            <div className={`Box CLR-BG-1 Shadow-1 Fade FromTop ${isVisible ? 'Visible' : ''}`} style={{width:'80%', paddingBlock:'30px'}} >
                <div>
                  <MaterialSymbol icon="warning" grade={25} weight={600} style={{fontSize:'40px', marginBottom:'20px', color:'#bebebe'}}/>
                </div>
                <div className='CLR-TXT-1' style={{ marginBottom: '30px'}}>{confirmation.message}</div>
                <div style={{display:'flex', justifyContent:'space-evenly'}}>
                  <ButtonLayered type="submit" text={language.cancel} disabled={isSubmitting} width={"30%"} onClick={handleCancel}/>
                  <ButtonLayered type="submit" text={language.yes} disabled={isSubmitting} width={"30%"} onClick={handleConfirm}/>
                </div>
            </div>
        </div>
        :
        null
    );
};

export default InfoConfirm;