import React, { useEffect, useState, useContext } from 'react';
import AppContext from '../global/AppContext';
import { SendRequest } from '../../webRequests';
import ButtonWithIcon from './buttonWithIcon';

const PermitNotificationButton = ({ onSubscribed }) => {
  const { language} = useContext(AppContext);
  const [isNotificationEnabled, setIsNotificationEnabled] = useState(false);
  const pushsubscriber = process.env.REACT_APP_PUSH_SUBSCRIBER;

  useEffect(() => {
    // Check the notification permission status
    if (Notification.permission === 'granted') {
      // Check if the user is subscribed to push notifications
      navigator.serviceWorker.ready.then((registration) => {
        registration.pushManager.getSubscription().then((subscription) => {
          if (subscription) {
            setIsNotificationEnabled(true);
          } else {
            setIsNotificationEnabled(false);
          }
        });
      });
    } else {
      setIsNotificationEnabled(false);
    }
  }, []);

  const urlBase64ToUint8Array = (base64String) => {
    const base64 = base64String.replace(/-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    return Uint8Array.from([...rawData].map((char) => char.charCodeAt(0)));
  };

  const subscribeUser = async () => {
    console.log("Permission granted");

    const registration = await navigator.serviceWorker.ready;

    console.log("navigator.serviceWorker.ready");

    try {
        const subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array('BGKOyzPWA8MmhotvxF-f2S0uW67oZ3c22wbWTLKtdWFHZBczqVZp1spHJqLTjdXppTppmz7iAmHGEMOBeI4vDNw'),
        });

        console.log("Sending push notification subscription");

        const inputData = {
          subscription : subscription
      };

      const responseData = await SendRequest('savesubscription', { inputdata: inputData });
      console.log("Server response:", responseData);

      if (responseData.result.success) {
        setIsNotificationEnabled(true);
          if (onSubscribed) {
              onSubscribed(subscription);
          }
      } else {
          console.error('Subscription failed:', responseData.result);
      }
    } catch (error) {
        console.error('Subscription error:', error);
    }
  };

  const handleButtonClick = () => {
    if (Notification.permission === 'granted') {
      subscribeUser();
    } else {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          subscribeUser();
        }
      });
    }
  };

  if (isNotificationEnabled) {
    return null; // Hide the button if permission is already granted
  }

  return (
    <ButtonWithIcon text={language.permitNotifications} leftIcon={'notifications'} width={"90%"} style={{marginTop:"10px"}}
    onClick={handleButtonClick}/>
  );
};

export default PermitNotificationButton;