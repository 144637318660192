import React, { useContext, useEffect, useState, useRef } from 'react';
import AnimatedPage from './animatedPage';
import AppContext from '../global/AppContext';
import ButtonLogin from '../elements/buttonLayered';
import { MaterialSymbol } from 'react-material-symbols';
import { useSearchParams } from 'react-router-dom';
import ButtonLayered from '../elements/buttonLayered';
import { eventWrapper } from '@testing-library/user-event/dist/utils';
import { SendRequest } from '../../webRequests';
import { ReactComponent as NerligoLogo } from '../../assets/icons/svg/nerligo_logo.svg';
import bg from '../../assets/images/png/login_bg.png';

const pageID = "LoginForm";

const PageContent = () => {
    const { language, userState, userPrefs, verifyLogIn, logIn, enterApp, updateUserPrefs, showOptions, hideOptions } = useContext(AppContext);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState(''); // Separate state for password confirmation
    const [result, setResultMessage] = useState('\u00A0');
    const [searchParams] = useSearchParams();
    const resetUserNameRef = useRef(null);
    const verifyCode = searchParams.get('verifyacc');

    useEffect(() => {
        if (userState.loginstatus === 1) {
            setResultMessage('\u00A0');
            setUsername('');
            setPassword('');
            setPasswordConfirm('');
        }
    }, [userState.loginstatus]);

    const verify = userPrefs.verifyCode !== undefined && userPrefs.verifyCode !== "";

    const handleSubmit = async (event) => {
        event.preventDefault();

        console.log('userPrefs.verifyCode ' , userPrefs.verifyCode , ' verify ' , verify );
        
        try {
            if (verify){
                await verifyLogIn(userPrefs.verifyUserName, password, verifyCode,  async (result) => {
    
                    console.log("verifyLogIn result ",result);
                    let resultMessage;
                    switch (result.code) {
                        case 99:
                            resultMessage = language.loginFormWrongCredentials;
                            break;
                        case 0:
                            resultMessage = language.loginFormNotVerified;
                            break;
                        case 1:
                            resultMessage = '\u00A0';
                            updateUserPrefs('verifyCode', '');
                            enterApp(result.groupscount);
                            break;
                        case 2:
                            resultMessage = language.loginFormAccountSuspended;
                            break;
                        default:
                            resultMessage = language.loginFormWrongCredentials;
                            break;
                    }
                    setResultMessage(resultMessage);
                });
            }
            else{
                await logIn(username, password, async (result) => {
    
                    console.log("logIn result ",result);
                    let resultMessage;
                    switch (result.code) {
                        case 99:
                            resultMessage = language.loginFormWrongCredentials;
                            break;
                        case 0:
                            resultMessage = language.loginFormNotVerified;
                            break;
                        case 1:
                            resultMessage = '\u00A0';
                            enterApp(result.groupscount);
                            break;
                        case 2:
                            resultMessage = language.loginFormAccountSuspended;
                            break;
                        default:
                            resultMessage = language.loginFormWrongCredentials;
                            break;
                    }
                    setResultMessage(resultMessage);
                });
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    const confirmResetPassword = async (event) => {
        event.preventDefault();
        const username = resetUserNameRef.current.value;
        console.log("Resetting password for account ", username);
        hideOptions();

        updateUserPrefs('displayInfoLoading', 1);

        const response = await SendRequest('resetpassword', { username: username });
        console.log("Reset password result:" , response);

        updateUserPrefs('displayInfoLoading', 0);

        if (response.success){
            updateUserPrefs('infoMessage', language.emailPasswordReset);
            updateUserPrefs('displayInfoMessage', 1);
        }
    }

    const resetPassword = async () => {
        showOptions({
            message: (
              <form>
                <div style={{paddingTop:"20px"}}>                    
                    {language.loginFormResetPassword}<br/>
                </div>
                <div style={{ width: '100%' }}>
                    <input 
                        required
                        type="text"
                        placeholder={language.loginFormUsernameTitle}
                        className="Input"
                        name="resetUserName"
                        ref={resetUserNameRef}
                        style={{boxShadow:"inset rgb(0 0 0 / 31%) 0px 0px 7px", marginBlock:"20px"}}
                    />
                </div>
                <div>
                    <ButtonLayered
                        type="button"
                        text={language.reset}
                        onClick={(e) => confirmResetPassword(e)}
                        style={{width:"auto", marginBottom:"10px"}}
                    />
                </div>
              </form>
            ),
            showIcon: false
          });
    };

    const handleCancel = async (event) => {
        event.preventDefault();
        updateUserPrefs('wantLogIn', 0);
    };

    const inputStyle = {borderRadius:"20px", boxShadow:"0px 2px 0px 0px #b5bcc7", height:"30px", width:"calc(80% - 40px", maxWidth:"400px"};

    return (
        <form id="loginForm" onSubmit={handleSubmit} className={`loginform CLR-BG-2 Shadow-1`}>
            <div style={{position:"absolute", width:"100%"}}>
                <img src={bg} style={{width:"100%"}}></img>
            </div>

            <button type="button" className="Icon-Circle CLR-BTN-Close" onClick={handleCancel} style={{ float: 'right' }}>
                <MaterialSymbol icon="close" grade={-25} />
            </button>

            {/* <div className='loginInfo'>
                <div style={{marginTop:"5%", fontSize:"15px", marginBottom:"5%"}}>
                    Přihlášení do aplikace
                </div>

                <div style={{fontSize:"10vw", fontWeight:"700"}}>
                    Nerliano s.r.o.                    
                </div>

                <div style={{color:"#8b8b8b", fontSize:"15px", fontWeight:"500"}}>
                    Nejlepší aplikace pro přehled<br/> a vývoj našich dětí
                </div>
            </div> */}

            <div className='loginFormCredentials'>
                
                {verify ? (
                    <div id="loginFormTitle" className='loginFormTitle'>{language.loginFormSetPasswordTitle}<br/>{userPrefs.verifyUserName}</div>
                ) : <div id="loginFormTitle" className='loginFormTitle'>{language.loginFormTitle}</div>}
                {!verify ? (
                    <div>
                        <input 
                            required
                            type="text"
                            placeholder={language.loginFormUsernameTitle}
                            className={"Input"}
                            name="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            style={{...inputStyle, backgroundColor: "white", marginBottom:"30px"}}
                        />
                    </div>
                ) : null}
                <div>
                    <input
                        required
                        type="password"
                        placeholder={language.loginFormUsernamePassword}
                        className="Input"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        style={{...inputStyle, backgroundColor: "white" }}
                    />
                </div>
                {verify ? (
                    <div>
                        <input
                            required
                            type="password"
                            placeholder={language.loginFormUsernamePasswordConfirm}
                            className="Input"
                            name="passwordConfirm"
                            value={passwordConfirm}
                            onChange={(e) => setPasswordConfirm(e.target.value)}
                            pattern={password}
                            style={{ backgroundColor: "white" }}
                        />
                    </div>
                ) : null}
                <div
                    className={`loginresult ${result === '\u00A0' ? '' : 'Visible'}`}
                >
                    {result}
                </div>
                <ButtonLogin type="submit" text={!verify ? language.loginFormButtonLogIn : language.loginFormButtonSetPassword} style={{ textTransform:"uppercase", margin:"auto", width:"80%", maxWidth:"440px", paddingInline:"20px" }} />
                {!verify ? (
                    <button className='ButtonText' type="button" onClick={() => resetPassword()}>{language.loginFormForgottenPassword}</button>
                ) : null}
                <div style={{marginBottom: '20px'}}></div>
            </div>

            <div className='loginInfo' style={{position:"fixed", fontSize:"15px", bottom:"2%", left:"50%", transform:"translate(-50%,0px)", width:"100%"}}>
                <div>
                    Potřebujete poradit?
                </div>
                <div style={{textDecoration:"underline", color:"blue"}}>
                    odkaz na rady a tipy
                </div>
                <div className='Logo' style={{width:"clamp(0px, 30vw, 100px)", margin:"auto", marginBlock:"30px"}}><NerligoLogo/></div>
                <div style={{fontSize: '10px'}}>
                    Přihlášení do školní aplikace společnosti Nerliano s.r.o.
                </div>
            </div>
        </form>
    );
};

const EnhancedPage = AnimatedPage(PageContent, pageID, 'login');
const components = {
    [pageID]: () => <EnhancedPage />
  };
export default components[pageID];