// useFontLoader.js
import { useEffect, useState } from 'react';
import WebFont from 'webfontloader';

const FontLoader = (fontName) => {
  const [isFontLoaded, setIsFontLoaded] = useState(false);

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Material Symbols Outlined']
      },
      active: () => {
        console.log('Font successfully loaded.');
        setIsFontLoaded(true);
      },
      inactive: () => {
        console.error('Font failed to load.');
        setIsFontLoaded(true);  // Proceed even if font fails
      },
      timeout: 10000  // Set timeout to 5 seconds (5000 ms)
    });
  }, [fontName]);

  return isFontLoaded;
};

export default FontLoader;