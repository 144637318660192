import React, { useContext } from 'react';
import AnimatedPage from './animatedPage';
import AppContext from '../global/AppContext';
import { SendRequest } from '../../webRequests';
import UserAvatar from '../elements/userAvatar';
import UsersList from '../elements/usersList';
import ButtonWithIcon from '../elements/buttonWithIcon';

const pageID = "RemoveUser";

const PageContent = () => {
    const { language, userPrefs, updateUserPrefs, userState, showConfirmation } = useContext(AppContext);
    const orgId = userPrefs.currentOrg.orgid;
    const groupId = userPrefs?.groupDetail?.groupid;

    const handleUserRemove = async (event, userid, username) => {
        if (event) event.preventDefault();

        const requestData = {
            orgid: orgId,
            groupid: groupId,
            removedUserId: userid,
        };

        let responseData;

        switch(userPrefs.removeUserFrom) {
            case "Group":
            case "GroupChild":
                responseData = await SendRequest('removeuserfromgroup', { inputdata: requestData });
                console.log("Group remove:" ,responseData);
                goBack();
                break;

            case "Organization":
            case "OrganizationChild":
                showConfirmation(
                    <>
                        {userPrefs.removeUserFrom === "Organization" 
                            ? language.confirmOrgUserDelete 
                            : language.confirmOrgChildDelete} 
                        <br/>
                        <strong>{username}</strong>
                    </>,
                    async () =>  {
                        updateUserPrefs('displayInfoLoading', 1);
                        responseData = await SendRequest('removeuserfromorganization', { inputdata: requestData });
                        console.log("Org remove:" ,responseData);

                        updateUserPrefs('displayInfoLoading', 0);
                        updateUserPrefs('infoMessage', responseData.success ? language.done : language.failed);
                        updateUserPrefs('displayInfoMessage', 1);
                        updateUserPrefs("currentPage", 'OrganizationDetail');
                        if (responseData.success) {

                            const requestData = { orgid: orgId };
                            const responseData = await SendRequest('getorganizationusers', { requestdata: requestData });
                            console.log("getorganizationusers:", responseData);
                    
                            const usersData = {
                                orgid: orgId,
                                orgusers: responseData.orgusers.sort((a, b) => b.role - a.role),
                            };
                            updateUserPrefs('orgUsers', usersData);
                            
                            goBack();
                        }
                    },
                    () => console.log('Delete action canceled')
                );
                break;
        }
    };

    const goBack = (event) => {
        if (event) {
            event.preventDefault(); // Prevent the default action
        }

        const pageMap = {
            "Organization": "OrganizationUsers",
            "OrganizationChild": "OrganizationChildren",
            "Group": "GroupUsers",
            "GroupChild": "GroupChildren"
        };
        updateUserPrefs("currentPage", pageMap[userPrefs.removeUserFrom]);
    };

    const currentOrg = userState.userorganizations.find(org => org.orgid === orgId);
    const currentGroup = userState.usergroups.find(group => group.groupid === groupId);

    const orgUsers = userPrefs.orgUsers.orgusers || [];
    const groupUsers = userPrefs.groupUsers || [];

    // Common variables
    const isGroup = userPrefs.removeUserFrom.includes("Group");
    const userList = isGroup ? groupUsers : orgUsers;

    const title = 
    userPrefs.removeUserFrom === "Group" ? language[`type_${userPrefs.currentGroup.grouptype}_removeUserGroup`]
    : userPrefs.removeUserFrom === "GroupChild" ? language[`type_${userPrefs.currentGroup.grouptype}_removeUserGroupChild`]
    : userPrefs.removeUserFrom === "Organization" ? language[`type_${userPrefs.currentOrg.orgtype}_removeUserOrg`]
    : userPrefs.removeUserFrom === "OrganizationChild" ? language[`type_${userPrefs.currentOrg.orgtype}_removeUserOrgChild`]
    : "DefaultTitle";

    const listTitle = 
    userPrefs.removeUserFrom === "Group" ? language[`type_${userPrefs.currentGroup.grouptype}_removeUserGroup`]
    : userPrefs.removeUserFrom === "GroupChild" ? language[`type_${userPrefs.currentGroup.grouptype}_removeUserGroupChild`]
    : userPrefs.removeUserFrom === "Organization" ? language[`type_${userPrefs.currentOrg.orgtype}_usersInOrg`]
    : userPrefs.removeUserFrom === "OrganizationChild" ? language[`type_${userPrefs.currentOrg.orgtype}_childUsersInOrg`]
    : "DefaultTitle";

    const filteredUsers = userList.filter(user => {
        if (isGroup) {
            return (userPrefs.removeUserFrom === "Group" && user.role > 0) || 
                   (userPrefs.removeUserFrom === "GroupChild" && user.role === 0);
        } else {
            return (userPrefs.removeUserFrom === "Organization" && user.role > 0) || 
                   (userPrefs.removeUserFrom === "OrganizationChild" && user.role === 0);
        }
    });

    return (
        <form>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <h3 style={{ marginBottom: "0px" }}>
                    {title}
                </h3>
                <h2 style={{ marginTop: "0px", marginBottom: "0px" }}>
                    {currentOrg?.orgname || "Organization Not Found"}
                </h2>
                {isGroup && <h2 style={{ marginTop: "0px" }}>{currentGroup?.groupname || "Group Not Found"}</h2>}

                {/* User list */}
                {filteredUsers.length > 0 ? (
                    <UsersList
                        title={listTitle}
                        users={filteredUsers}
                        opensPublicProfiles={false}
                        onClick={(user) => handleUserRemove(null, user.userid, `${user.firstname} ${user.lastname}`)}
                    />
                ) : (
                    <p style={{ textAlign: "center", marginTop: "0px" }}>
                        {language.noAvailableUsers}
                    </p>
                )}

                <ButtonWithIcon text={language.cancel} leftIcon={'cancel'} rightIcon={'arrow_back_ios'} width={"90%"} style={{marginTop:"10px"}}
                    onClick={goBack}/>
            </div>
        </form>
    );
};

const EnhancedPage = AnimatedPage(PageContent, pageID);
const components = {
    [pageID]: () => <EnhancedPage />
};
export default components[pageID];