import React, { useContext } from 'react';
import AnimatedPage from './animatedPage';
import AppContext from '../global/AppContext';
import { SendRequest } from '../../webRequests';
import ProfileImage from '../elements/profileImage';
import ItemProfileEditable from '../elements/itemTextEditable';

const pageID = "PublicProfile";

const PageContent = () => {
    const { language, userPrefs, userState, navBack, updateUserState, updateUserPrefs } = useContext(AppContext);
    var user = userPrefs.publicDetailUser;

    return (
        <>
            {/* <h2>{language.myProfile}</h2> */}
                <div style={{paddingBottom:'30px'}}></div>

                <ProfileImage user={user} canUploadNew={false}/>

                <div className='Group-Label'>{language.name}</div>
                
                <ItemProfileEditable 
                    label={language.firstName} 
                    value={user.firstname} 
                    isEditable={false}
                />

                <ItemProfileEditable 
                    label={language.lastName} 
                    value={user.lastname}
                    isEditable={false}
                />

                <div className='Group-Label' style={{paddingTop:'20px'}}>{language.address}</div>

                <ItemProfileEditable 
                    label='Stát' 
                    value={user.state} 
                    isEditable={false}
                />

                <ItemProfileEditable 
                    label='Město' 
                    value={user.city} 
                    isEditable={false}
                />

                <ItemProfileEditable 
                    label='Ulice' 
                    value={user.street} 
                    isEditable={false}
                />

                <ItemProfileEditable 
                    label='Číšlo popisné' 
                    value={user.streetnumber} 
                    isEditable={false}
                />

                <div className='Group-Label' style={{paddingTop:'20px'}}>{language.contacts}</div>

                <ItemProfileEditable 
                    label='Telefon' 
                    value={user.phonenumber}
                    isEditable={false}
                />

                <ItemProfileEditable 
                    label='E-mail' 
                    value={user.emailcontact}
                    isEditable={false}
                />

                {/* <button type="button" className="ListItem CLR-BTN-1" onClick={() => navBack()}>
                    {language.back}
                </button> */}
        </>
    );
};

const EnhancedPage = AnimatedPage(PageContent, pageID);
const components = {
    [pageID]: () => <EnhancedPage />
  };
export default components[pageID];