import React from 'react';
import { MaterialSymbol } from 'react-material-symbols';
import { ReactComponent as SvgCalendar } from '../../assets/icons/svg/Kalendar.svg';

const DatePicker = ({ selectedDate, onChangeDate, onOpenCalendar, mode = 0 }) => {
    // mode: 0=month and day, 1=day only

    const svgIconStyle = {width:"clamp(0px,7vw,45px)"};

    const arrowButtonStyle = { padding: "0px", display: "flex", backgroundColor: "#ffffff00" };
    const arrowStyle = { fontSize: "clamp(0px,8vw,30px)", color: "#fdd046" };
    const divider = { width: "5vw" };

    const dayTextContainerStyle = { width: "12vw" };
    const dayPickerTextStyle = { fontSize: "4vw", marginBlock: "auto", paddingInline: "2px", color: "white" };
    const currentDayPickerTextStyle = { fontSize: "6vw", marginBlock: "auto", paddingInline: "2px", fontWeight: "bold", color: "white" };

    const monthTextContainerStyle = { width: "12vw" };
    const monthPickerTextStyle = { fontSize: "3vw", marginBlock: "auto", paddingInline: "2px", color: "white" };
    const currentMonthPickerTextStyle = { fontSize: "6vw", marginBlock: "auto", paddingInline: "2px", fontWeight: "bold", color: "white" };

    // Formatting functions for month and day
    const formatMonth = (date) => new Intl.DateTimeFormat('cs-CZ', { month: 'long' }).format(date);
    const formatDay = (date) => new Intl.DateTimeFormat('en-US', { day: 'numeric' }).format(date);

    // Calculate previous and next month and day
    const previousMonth = new Date(selectedDate);
    previousMonth.setMonth(selectedDate.getMonth() - 1);

    const nextMonth = new Date(selectedDate);
    nextMonth.setMonth(selectedDate.getMonth() + 1);

    const previousDay = new Date(selectedDate);
    previousDay.setDate(selectedDate.getDate() - 1);

    const nextDay = new Date(selectedDate);
    nextDay.setDate(selectedDate.getDate() + 1);

    return (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", paddingBlock: "2vw", backgroundColor: "#394458" }}>
            {/* Month Navigation */}

            {mode == 0 && (
                <>
                    <button onClick={() => onChangeDate('month', -1)} style={arrowButtonStyle}>
                        <MaterialSymbol icon={'arrow_forward_ios'} style={{ ...arrowStyle, transform: "rotate(180deg)" }} />
                    </button>

                    <div style={monthTextContainerStyle}>
                        <div style={{ ...monthPickerTextStyle, textAlign: "center" }}>
                            {formatMonth(previousMonth)}
                        </div>
                    </div>

                    <div style={monthTextContainerStyle}>
                        <div style={{ ...currentMonthPickerTextStyle, textAlign: "center" }}>
                            {formatMonth(selectedDate)}
                        </div>
                    </div>

                    <div style={monthTextContainerStyle}>
                        <div style={{ ...monthPickerTextStyle, textAlign: "center" }}>
                            {formatMonth(nextMonth)}
                        </div>
                    </div>

                    <button onClick={() => onChangeDate('month', 1)} style={arrowButtonStyle}>
                        <MaterialSymbol icon={'arrow_forward_ios'} style={arrowStyle} />
                    </button>
                </>
            )}

            <div>
                {mode == 1 && (
                    <div style={{ ...monthPickerTextStyle, textAlign: "center" }}>
                        {formatMonth(selectedDate)}
                    </div>
                )}

                <div style={{ display: "flex", alignItems: "center" }}>
                    {mode == 0 && (<div style={divider}></div>)}
                    {/* Day Navigation */}
                    <button onClick={() => onChangeDate('day', -1)} style={arrowButtonStyle}>
                        <MaterialSymbol icon={'arrow_forward_ios'} style={{ ...arrowStyle, transform: "rotate(180deg)" }} />
                    </button>

                    <div style={dayTextContainerStyle}>
                        <div style={{ ...dayPickerTextStyle, textAlign: "center" }}>
                            {formatDay(previousDay)}.
                        </div>
                    </div>

                    <div style={dayTextContainerStyle}>
                        <div style={{ ...currentDayPickerTextStyle, textAlign: "center" }}>
                            {formatDay(selectedDate)}.
                        </div>
                    </div>

                    <div style={dayTextContainerStyle}>
                        <div style={{ ...dayPickerTextStyle, textAlign: "center" }}>
                            {formatDay(nextDay)}.
                        </div>
                    </div>

                    <button onClick={() => onChangeDate('day', 1)} style={arrowButtonStyle}>
                        <MaterialSymbol icon={'arrow_forward_ios'} style={arrowStyle} />
                    </button>
                </div>
            </div>

            <button onClick={() => onOpenCalendar()} style={{...arrowButtonStyle, position:"absolute", right:"clamp(0px,5vw,20px"}}>
                <SvgCalendar style={svgIconStyle}/>
            </button>
        </div>
    );
};

export default DatePicker;