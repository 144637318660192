import React, { useState } from 'react';
import './buttonUniversal.css';

const ButtonUniversal = ({ 
    onClick,
    text,
    textColor,
    backgroundColor,
    leftIcon,
    rightIcon,
    rightIconStyle = null,
    className,
    disabled,
    width,
    style,
    type = 'classic',
    preventDefault = true,
    isToggle = false,         // Prop to determine if the button should be toggleable
    defaultToggled = false,
    rightIconOnEnd = false,   // Prop to set the default toggle state
    onToggle = () => {}    // Event handler for when toggled off
}) => {
    const [isToggled, setIsToggled] = useState(defaultToggled);

    // Function to handle button click
    const handleClick = (e) => {
        if (e && preventDefault)
            e.preventDefault();

        if (isToggle) {
            const newToggleState = !isToggled;
            setIsToggled(newToggleState);
            onToggle(newToggleState); // Pass the new state (true/false)
        } else if (onClick) {
            onClick();
        }
    };

    const combinedStyle = {
        width,
        backgroundColor: backgroundColor || ( (type === 'classic' || type === 'flat' ) ? "white" : "transparent"),
        wordBreak:"break-word",
        ...(textColor && { color: textColor }),
        ...style // Spread the style prop to apply additional styles
    };

    const typeClasses = {
        classic: 'classic',
        underline: 'underline',
        flat: 'flat',
        // Add more types here if needed
    };

    const typeClassName = typeClasses[type] || '';
    const buttonClassName = `ButtonUniversal ${typeClassName} ${className ? className : ""}`.trim();

    // Determine the right icon to display
    const displayRightIcon = isToggle && !rightIcon ? (
        <span role="img" aria-label="checkmark" style={{ color: 'green' }}>
            ✔
        </span>
    ) : (
        rightIcon
    );

    return (
        <button
            onClick={handleClick}
            className={buttonClassName}
            disabled={disabled}
            style={combinedStyle}
        >
            {/* Render left icon if provided */}
            {leftIcon && (
                <span className="Icon">
                    {React.isValidElement(leftIcon) ? leftIcon : <span>{leftIcon}</span>}
                </span>
            )}
            
            {typeof text === 'string' ? <span>{text}</span> : text}

            {/* Render right icon with adjusted opacity based on toggle state */}
            {(isToggle || leftIcon || rightIcon) && (
                <span className={`${rightIconStyle ? '': 'Icon' } ${rightIconOnEnd ? 'End' : ''}`} style={{ ...rightIconStyle, opacity: isToggle && !isToggled ? 0 : 1, transition: 'opacity 0.3s' }}>
                    {displayRightIcon}
                </span>
            )}
            
        </button>
    );
};

export default ButtonUniversal;

// Usage examples

{/* 
    
<ButtonUniversal 
text={"Button Example"} 
/>

<ButtonUniversal 
text={"Button Example"} 
rightIcon={<span role="img" aria-label="fire">🔥</span>} 
/>

<ButtonUniversal 
text={"Button Example"} 
leftIcon={<span role="img" aria-label="fire">🔥</span>} 
/>

<ButtonUniversal 
text={"Button Example"} 
style={{width:"90%"}}
leftIcon={<span role="img" aria-label="fire">🔥</span>} 
/>

<ButtonUniversal 
text={"Button Example"} 
style={{width:"90%"}}
leftIcon={<MaterialSymbol icon={'send'} color='red'/>}
/>

<ButtonUniversal 
text={"Button Example"} 
style={{width:"90%", justifyContent:"space-between"}}
leftIcon={<MaterialSymbol icon={'send'} color='red'/>}
/>

<ButtonUniversal 
text={"Button Example"} 
style={{width:"90%"}}
leftIcon={<MaterialSymbol icon={'send'} color='red'/>}
rightIcon={<MaterialSymbol icon={'send'} color='red'/>}
/>

<ButtonUniversal 
text={"Button Example"} 
style={{width:"90%", justifyContent:"space-between"}}
leftIcon={<MaterialSymbol icon={'send'} color='red'/>}
rightIcon={<MaterialSymbol icon={'send'} color='red'/>}
/>

<ButtonUniversal 
text={"Button Example"} 
style={{width:"90%"}}
leftIcon={<MaterialSymbol icon={'send'} color='red'/>}
rightIcon={<MaterialSymbol icon={'send'} color='red'/>}
rightIconOnEnd={true}
/>

<ButtonUniversal 
text={"Button Example"} 
/>

<ButtonUniversal 
text={"Fotky/Videa"}
type='underline'
style={{width:"100%"}}
leftIcon={<span role="img" aria-label="fire">🔥</span>} 
rightIcon={<span role="img" aria-label="fire">🔥</span>}
/>

<ButtonUniversal
text={"Fotky/Videa"}
type='underline'
style={{width:"100%"}}
leftIcon={<MaterialSymbol icon={'send'} color='red'/>}
rightIcon={<MaterialSymbol icon={'send'} color='red'/>}
/>

<ButtonUniversal
text={"Fotky/Videa"}
type='underline'
style={{width:"100%"}}
/>

<ButtonUniversal 
isToggle={true}
text={"Button Example"} 
leftIcon={<span role="img" aria-label="fire">🔥</span>}
rightIcon={<span role="img" aria-label="rocket">🚀</span>}
onToggleOff={() => console.log("toggled off")}
onToggleOn={() => console.log("toggled on")}
/>

<ButtonUniversal 
isToggle={true}
defaultToggled={true}
text={"Button Example"} 
leftIcon={<span role="img" aria-label="fire">🔥</span>}
onToggleOff={() => console.log("toggled off")}
onToggleOn={() => console.log("toggled on")}
/>

*/}